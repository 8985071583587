import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Button, Spin } from "antd";
import { useRouter } from "next/router";
import Link from "next/link";
import styles from "@aspen/theme/Strategy.module.less";
import { IKycLevel, IVentureListItem, COMMON_CACHE_PRIORTY_KEYS, VENTURE_TYPE } from "@aspen/model";
import { subscribeVenture } from "@aspen/services";
import { GA_EVENT_NAME, GA_EVENT_TAG, i18nUtil, reportEvent, VENTURES_PATHS } from "@aspen/libs";
import { useCachePriorityData } from "@aspen/hooks";
import { WithKYCInfo, HtmlContentComponent, message } from "@aspen/ui";
import {
  Disclaimer,
  ModalSecondarySubscribe,
  ModalVenture,
  ModalVentureSubscribeResult
} from "../../index";

interface IProps {
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
}

interface ISubscribeInfoRefModel {
  sharesType: string;
  amount: string;
  priceRange: string;
  discountRate: string;
}

function Detail(props: IProps) {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalResVisible, setModalResVisible] = useState<boolean>(false);
  const [secondarySubscribe, setSecondarySubscribe] = useState<boolean>(false);
  const [actionType, setActionType] = useState<number>(0);

  const subscribeInfoRef = useRef<ISubscribeInfoRefModel>(null);

  const intl = i18nUtil.t();
  const ventures_text: string = intl["ventures.detail.strategies"];
  const details_text: string = intl["ventures.detail.details"];

  const router = useRouter();
  // 埋点用 当前的风投产品列表 是一级还是二级
  const fromTab = router.query?.tab?.toString() ?? "";
  // @ts-ignore
  const [info, updateInfo] = useState<IVentureListItem>(null);
  // @ts-ignore
  const { data, isLoading } = useCachePriorityData<IVentureListItem>(
    COMMON_CACHE_PRIORTY_KEYS.ventureDetail,
    router.query?.id
      ? {
          id: router.query?.id
        }
      : null
  );
  useEffect(() => {
    updateInfo(data);
  }, [data]);

  // 是否开始或者已经过期
  const isExpired: () => string | boolean = () => {
    const endTime = info?.endTime;
    const startTime = info?.startTime;
    const now = new Date().getTime();
    if (Number(now) > Number(endTime)) {
      return "close";
    }
    if (Number(now) < Number(startTime)) {
      return "comingSoon";
    }
    return false;
  };

  const handleSecondarySubmit = (subscribeInfo: ISubscribeInfoRefModel) => {
    const { sharesType, amount, priceRange, discountRate } = subscribeInfo;
    // @ts-ignore
    subscribeInfoRef.current = subscribeInfo;
    const subscribeParams = {
      productId: info?.id,
      amount,
      assetType: sharesType,
      sharePrice: priceRange,
      discountRate
    };
    subscribeVenture(subscribeParams)
      .then((res) => {
        if (res?.code == "0") {
          reportEvent({
            joinedTag: GA_EVENT_TAG.Modal,
            moduleName: GA_EVENT_NAME.ventures.detail.subsResult,
            detailParams: {
              ...subscribeParams,
              type: fromTab
            }
          });
          setModalResVisible(true);
        } else {
          message.error(intl["venture.modal.subscribe.error.tips"]);
        }
      })
      .finally(() => {
        setSecondarySubscribe(false);
      });

    reportEvent({
      moduleName: GA_EVENT_NAME.ventures.detail.submitSubscribe,
      detailParams: {
        ...subscribeParams,
        type: fromTab
      }
    });
  };
  // 点击申购赎回
  const handleClick = (type: number) => {
    if (props.showGuideModal(2)) {
      // type : 0 申购；1 赎回
      setActionType(type);
      setModalVisible(true);
      reportEvent({
        joinedTag: GA_EVENT_TAG.Modal,
        moduleName:
          type === 0
            ? GA_EVENT_NAME.ventures.detail.subsModal
            : GA_EVENT_NAME.ventures.detail.redeemModal,
        detailParams: {
          id: info?.id,
          type: fromTab
        }
      });
    }
  };

  return (
    <div className={styles.detail}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link
            href={{
              pathname: VENTURES_PATHS.VENTURES,
              query: { currentTab: info?.type === 2 ? "secondary" : "primary" }
            }}>
            {ventures_text}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{details_text}</Breadcrumb.Item>
      </Breadcrumb>
      <section id="title">
        <p className={styles.title}>{info?.name}</p>
        <div className="flex" style={{ marginBottom: 24 }}>
          {intl[VENTURE_TYPE[info?.type]] ? (
            <div className={styles.categroy}>{intl[VENTURE_TYPE[info?.type]]}</div>
          ) : null}
          {info?.attachmentUrl && info?.attachmentName ? (
            <Button
              type={"ghost"}
              className={styles.attachment}
              onClick={() => {
                reportEvent({
                  moduleName: GA_EVENT_NAME.ventures.detail.openLink,
                  detailParams: {
                    type: "venture"
                  }
                });
                window.open(info?.attachmentUrl ?? "");
              }}>
              <span>{info?.attachmentName}</span>
            </Button>
          ) : null}
        </div>
      </section>
      <Spin spinning={isLoading}>
        <div className={styles.detailContent}>
          {/* <div
          className={styles.detailBgContent}
          dangerouslySetInnerHTML={{ __html: info?.detailInformation }}
        /> */}
          <HtmlContentComponent htmlContent={info?.detailInformation} />

          {info?.name && (
            <div className={styles.btnWrap}>
              {isExpired() ? (
                <div className={styles.subBtnWrap}>
                  <Button type="primary" disabled className={styles.actionBtn}>
                    {isExpired() === "close" ? intl["button.closed"] : intl["button.comingSoon"]}
                  </Button>
                </div>
              ) : (
                <div className={styles.subBtnWrap}>
                  <Button
                    type="primary"
                    className={styles.actionBtn}
                    onClick={() => {
                      if (info?.shares) {
                        reportEvent({
                          joinedTag: GA_EVENT_TAG.Modal,
                          moduleName: GA_EVENT_NAME.ventures.detail.submitSubscribe,
                          detailParams: {
                            id: info?.id,
                            type: fromTab
                          }
                        });
                        setSecondarySubscribe(true);
                      } else {
                        handleClick(0);
                      }
                    }}>
                    {info?.shares ? intl["button.interest"] : intl["button.subscribe"]}
                  </Button>
                  {info?.amount && (
                    <Button
                      type="primary"
                      className={styles.actionBtn}
                      onClick={() => handleClick(1)}>
                      {intl["button.redeem"]}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {info?.name && <Disclaimer />}
      </Spin>
      <ModalVenture
        visible={modalVisible}
        type={actionType}
        id={info?.id}
        gaExtra={{ type: fromTab }}
        cancelModal={() => {
          setModalVisible(false);
        }}
      />
      {secondarySubscribe ? (
        <ModalSecondarySubscribe
          visible={secondarySubscribe}
          ventureName={info?.name}
          currency={info?.currency}
          purchaseInformation={info?.purchaseInformation || ""}
          handleSubmit={({
            sharesType,
            amount,
            priceRange,
            discountRate
          }: {
            sharesType: string;
            amount: string;
            priceRange: string;
            discountRate: string;
          }) => {
            handleSecondarySubmit({ sharesType, amount, priceRange, discountRate });
          }}
          cancelModal={() => {
            setSecondarySubscribe(false);
          }}
        />
      ) : null}
      {modalResVisible ? (
        <ModalVentureSubscribeResult
          visible={modalResVisible}
          ventureName={info?.name}
          // @ts-ignore
          subscribeInfo={subscribeInfoRef?.current}
          cancelModal={() => {
            setModalResVisible(false);
          }}
        />
      ) : null}
    </div>
  );
}

export const PageVenturesDetail = WithKYCInfo(Detail);
