import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { Spin, Row, Col, Table, Button, Modal, Tooltip, Tabs } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styles from "@aspen/theme/Savings.module.less";
import {
  MyInvestmentDetail,
  MY_AUTOMATICE_INVESTMENT,
  AUTOMATICE_INVESTMENT_CYCLE_CODE,
  IDcaProductsStatusEnums,
  OPERARTOR_TYPE,
  IDcaProductsPauseTypeEnums
} from "@aspen/model";
import {
  myAutoInvestList,
  myAutoInvestDetail,
  cancelAutomaticInvestmentPlan
} from "@aspen/services";
import {
  transformTime,
  decimalPointNoMoreX,
  resetRate,
  i18nUtil,
  reportEvent,
  transformDate,
  YIELD_PATHS,
  AUTHORIZATION_TYPE,
  USER_AUTH,
  GA_EVENT_NAME,
  USER_ROLE,
  MYDCA_PLAN_TAB,
  YIELD_TYPES,
  GA_EVENT_TAG
} from "@aspen/libs";
import { message } from "@aspen/ui";
import { TabLabel, ModalAutomaticInvestment, ModalSafetyVerification } from "../../index";

import tableEmpty from "@aspen/assets/images/no_results.png";

function InvestmentPlan() {
  const intl = i18nUtil.t();
  const my_title_text = intl["savings.automatic.my.title"];
  const loadMore = intl["button.loadMore"];
  const failTips_text = intl["savings.automatic.automatic.plan.cancelled"];
  const date_text = intl["column.date"];
  const name_text = intl["column.name"];
  const status_text = intl["column.status"];
  const apy_text = intl["column.apy"];
  const column_operator = intl["column.operator"];
  const action_text = intl["column.action"];
  const plan_details_text = intl["column.yield.invest.plan.details"];
  const investment_details_text = intl["column.yield.invest.investment.details"];
  const history_btn_text = intl["savings.automatic.history.btn"];
  const next_date = intl["savings.automatic.next.date"];
  const submitInfo = {
    cycle: "",
    amount: "",
    fee: "",
    revenueRate: "",
    recentDate: 0,
    payCoin: "USD",
    rateList: []
  };
  const [showSafetyVerificationModal, setShowSafetyVerificationModal] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isTransferYield, setIsTransferYield] = useState<boolean>(false);
  const [isTransferStaking, setIsTransferStaking] = useState<boolean>(false);
  const [confirmInfo, setConfirmInfo] = useState(submitInfo);
  const [currentInfo, setCurrentInfo] = useState<MyInvestmentDetail>();
  const [cancelVisible, setCancelVisible] = useState<boolean>(false);
  const [cancelId, setCancelId] = useState<string>("");
  const [showAutoFinancing, setShowAutoFinancing] = useState<boolean>(false);
  const [showAutoStaking, setShowAutoStaking] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(MYDCA_PLAN_TAB.all);

  const allNextTokenRef = useRef(null);
  const activeNextTokenRef = useRef(null);
  const canceledNextTokenRef = useRef(null);

  const router = useRouter();

  const tabItems = [
    {
      key: MYDCA_PLAN_TAB.all,
      label: <TabLabel title={"wallet.myDca.tab.all"} />
    },
    {
      key: MYDCA_PLAN_TAB.active,
      label: <TabLabel title={"wallet.myDca.tab.active"} />
    },
    {
      key: MYDCA_PLAN_TAB.cancelled,
      label: <TabLabel title={"wallet.myDca.tab.cancelled"} />
    }
  ];

  useEffect(() => {
    getInitData(currentTab);
  }, [currentTab]);
  // 初始化数据,
  const getInitData = (currentTab: string, type?: string) => {
    switch (currentTab) {
      case MYDCA_PLAN_TAB.all:
        fetchAutoInvestList("", allNextTokenRef, type);
        break;
      case MYDCA_PLAN_TAB.active:
        fetchAutoInvestList(IDcaProductsStatusEnums.RUNNING, activeNextTokenRef, type);
        break;
      case MYDCA_PLAN_TAB.cancelled:
        fetchAutoInvestList(
          `${IDcaProductsStatusEnums.PAUSE},${IDcaProductsStatusEnums.USER_STOP}`,
          canceledNextTokenRef,
          type
        );
        break;
    }
  };
  const initDetailModal = (data) => {
    const id = router?.query?.id;
    if (id) {
      const params = data.find((item) => item.id === id);
      if (params) {
        getDetailsInfo(params);
      }
    }
  };
  //定投转活期理财
  const _isTransferYield = (value) => {
    setIsTransferYield(value);
  };

  //定投转staking
  const _isTransferStaking = (value) => {
    setIsTransferStaking(value);
  };
  const fetchAutoInvestList = (status: string, nextTokenRef, type?: string) => {
    setLoading(true);
    if (!type) nextTokenRef.current = null;
    const param = { limit: 10, nextToken: nextTokenRef.current, status };
    //接口返回数据结构可优化 @后端
    myAutoInvestList(param)
      .then((res) => {
        if (res?.code == "0") {
          if (type === "loadMore") {
            setDataSource(dataSource.concat(res.data?.rows));
          } else {
            setDataSource(res.data?.rows);
            initDetailModal(res.data?.rows);
          }
          nextTokenRef.current = res.data?.nextToken;
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const showOrCloseModal = () => {
    setModalVisible(!modalVisible);
  };

  const getDetailsInfo = (param) => {
    setConfirmInfo((prevInfo) => {
      prevInfo.cycle = intl[AUTOMATICE_INVESTMENT_CYCLE_CODE[param?.period]];
      prevInfo.amount = param?.amount;
      prevInfo.fee = param?.feeRate;
      prevInfo.revenueRate = param?.revenueRate;
      prevInfo.recentDate = param?.nextInvestTime;
      prevInfo.payCoin = param?.payCurrency;
      // @ts-ignore
      prevInfo.rateList = [...resetRate(param?.investmentProportion?.currencyProportion)];
      return { ...prevInfo };
    });
    setIsTransferYield(param.autoFinancing === 1); //是否客户开启了自动转活期理财
    setIsTransferStaking(param.autoStaking === 1); //是否客户开启了自动转入staking
    setShowAutoFinancing(param.canAutoFinancing === 1); //是否管理端开启了自动转活期理财
    setShowAutoStaking(param.canAutoStaking === 1); // 是否管理端开启了自动转入staking

    const fetchParams = {
      contractId: param.id
    };

    myAutoInvestDetail(fetchParams)
      .then((res) => {
        if (res?.code == "0") {
          setCurrentInfo(res.data);
        } else {
          message.error(intl?.[res.msg] ?? res.msg);
        }
      })
      .catch(() => {})
      .finally(() => {});
    reportEvent({
      joinedTag: GA_EVENT_TAG.Modal,
      moduleName: GA_EVENT_NAME.dca.viewDCADetail,
      detailParams: {
        id: param.id,
        productId: param?.productId,
        nameKey: param?.productNameKey,
        payCurrency: param?.payCurrency,
        proportion: param?.proportion,
        type: param?.type
      }
    });
  };
  useEffect(() => {
    currentInfo && showOrCloseModal();
  }, [currentInfo]);

  const renderFailedTips = (record) => {
    let reason: string = "";
    switch (record?.pauseType) {
      case IDcaProductsPauseTypeEnums.cancelledManually:
        reason = i18nUtil.formatMessage(
          { id: "savings.automatic.automatic.manually.cancelled" },
          {
            operator: intl[OPERARTOR_TYPE[record?.cancelOperatorType]] || "",
            email: record?.cancelOperatorEmail
          }
        );
        break;
      case IDcaProductsPauseTypeEnums.transferBySystem:
        reason = intl["savings.automatic.automatic.transfer.fail"];
        break;
      case IDcaProductsPauseTypeEnums.redeemedAll:
        reason = i18nUtil.formatMessage(
          { id: "savings.automatic.automatic.redeemed.all" },
          { currency: record?.payCurrency }
        );
        break;
      case IDcaProductsPauseTypeEnums.planCancelled:
      case IDcaProductsPauseTypeEnums.systemError:
        reason = intl["savings.automatic.automatic.plan.cancelled"];
        break;
      default:
        reason = failTips_text;
        break;
    }
    return reason;
  };
  //正在进行中的定投
  const columns = [
    {
      title: date_text,
      dataIndex: "created",
      render: (record) => <span>{transformTime(record)}</span>
    },
    {
      title: name_text,
      dataIndex: "productNameKey",
      render: (value, record) => (
        <span className={styles.productNameKey}>
          {record.type == 0
            ? i18nUtil.formatMessage(
                { id: "savings.automatic.name" },
                {
                  coin: value?.split(".")?.slice(1, -1)?.join(",").toUpperCase()
                }
              )
            : record.name}
        </span>
      )
    },
    {
      title: next_date,
      dataIndex: "nextInvestTime",
      render: (value, record) => (
        <span>{record?.status === 3 || record?.statu === 2 ? "--" : transformDate(value)}</span>
      )
    },
    {
      title: status_text,
      width: 125,
      dataIndex: "status", // 2-已暂停 3-用户手动终止（2，3统一代表已终止状态）
      render: (value, record) => (
        <div>
          {value === 3 || value === 2 ? (
            <div>
              <span>{intl[MY_AUTOMATICE_INVESTMENT[value]]}</span>
              <Tooltip title={renderFailedTips(record)} style={{ width: 200 }}>
                <InfoCircleOutlined style={{ paddingLeft: "5px", cursor: "pointer" }} />
              </Tooltip>
            </div>
          ) : (
            <span>{intl[MY_AUTOMATICE_INVESTMENT[value]]}</span>
          )}
        </div>
      )
    },
    {
      title: apy_text,
      dataIndex: "revenueRate",
      render: (record) => <span>{decimalPointNoMoreX(record * 100, 2)}%</span>
    },
    {
      title: column_operator,
      dataIndex: "operatorType", //操作人类别  0本人操作，1子账号，2代理商
      render: (value: number) => <span>{intl[OPERARTOR_TYPE[value]] || "--"}</span>
    },
    {
      title: plan_details_text,
      render: (value, row) => (
        <Button
          className={styles.actionBtn}
          style={{ width: 75 }}
          onClick={() => getDetailsInfo(row)}>
          {intl["button.view"]}
        </Button>
      )
    },
    {
      title: investment_details_text,
      render: (value, row) => (
        <Button
          className={styles.actionBtn}
          style={{ width: 75 }}
          onClick={() => {
            router.push({
              pathname: YIELD_PATHS.YIELD_INVESTMENT_HISTORY,
              query: { id: row.id }
            });
          }}>
          {intl["button.view"]}
        </Button>
      )
    },
    {
      title: action_text,
      width: 75,
      render: (value, row) => (
        <div>
          {row.status != 3 && row.status != 2 && (
            <Button
              className={styles.actionBtn}
              style={{ width: 75 }}
              onClick={() => handleShowCancelVisible(row.id)}>
              {intl["button.cancel"]}
            </Button>
          )}
        </div>
      )
    }
  ];

  //取消定投
  const handleShowCancelVisible = (id: string) => {
    setCancelId(id);
    setCancelVisible(true);

    reportEvent({
      joinedTag: GA_EVENT_TAG.Modal,
      moduleName: GA_EVENT_NAME.dca.openCancelModal,
      detailParams: { id }
    });
  };

  const handleCloseCancelVisible = () => {
    setCancelVisible(false);
  };

  //验证码
  const hideOK = (code: string) => {
    fetchCancel(code);
  };

  const handleCancel = () => {
    const authorizationType = localStorage.getItem(AUTHORIZATION_TYPE);
    if (
      (localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_PARTNER_CUSTOMER ||
        localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_SUB) &&
      authorizationType === "0"
    ) {
      setShowSafetyVerificationModal(true);
    } else {
      fetchCancel();
    }

    reportEvent({
      moduleName: GA_EVENT_NAME.dca.confirmCancel,
      detailParams: { id: cancelId }
    });
  };
  const fetchCancel = (emailCode?: string) => {
    const param = {
      contractId: cancelId,
      verifyCode: emailCode || null
    };
    setLoading(true);
    cancelAutomaticInvestmentPlan(param)
      .then((res) => {
        if (res?.code == "0") {
          if (res.data === true) {
            handleCloseCancelVisible();
            setShowSafetyVerificationModal(false);
            //删除后清空分页重新获取数据
            message.success(intl["system.successful"], 1).then(() => {
              if (currentTab === MYDCA_PLAN_TAB.all) {
                allNextTokenRef.current = null;
              } else if (currentTab === MYDCA_PLAN_TAB.active) {
                activeNextTokenRef.current = null;
              } else {
                canceledNextTokenRef.current = null;
              }
              getInitData(currentTab);
            });
          } else {
            message.error(intl?.[res.msg] ?? res.msg);
          }
        } else {
          message.error(intl?.[res.msg] ?? res.msg);
        }
      })
      .finally(() => {
        setLoading(false);
        showSafetyVerificationModal && setShowSafetyVerificationModal(false);
      });
  };

  const clickLoadMore = () => {
    getInitData(currentTab, "loadMore");
    reportEvent({ moduleName: GA_EVENT_NAME.dca.loadMorMyPlan });
  };

  const handleClickCreateDca = () => {
    router.push({ pathname: YIELD_PATHS.YIELD, query: { tabType: YIELD_TYPES.DCA } });
  };

  const handleChangeTab = (currentTab) => {
    reportEvent({
      moduleName: GA_EVENT_NAME.dca.changeMyDcaTab,
      detailParams: { tab: currentTab }
    });
    setCurrentTab(currentTab);
  };

  const _renderTabContent = (nextTokenRef) => {
    return (
      <Spin spinning={loading}>
        {dataSource.length ? (
          <>
            <Table
              className={styles.table}
              rowKey={(record) => record?.id}
              bordered={false}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
            {nextTokenRef.current && dataSource.length >= 10 && (
              <div className={styles.loadMore} onClick={clickLoadMore}>
                {loadMore}
              </div>
            )}
          </>
        ) : (
          <div className={styles.flexColumn}>
            <Image alt="" unoptimized src={tableEmpty} width={136} height={100} />
            <span className={styles.emptyTxt}>{intl["savings.automatic.automatic.empty"]}</span>
            <Button type="primary" onClick={handleClickCreateDca} style={{ width: 165 }}>
              {intl["button.create.plan"]}
            </Button>
          </div>
        )}
      </Spin>
    );
  };
  return (
    <section className={styles.savings + " " + "customer"}>
      <div className="customerTop">
        <Row className="top" justify="space-between">
          <Col>
            <p className="title">{my_title_text}</p>
          </Col>
          <Col>
            <Button
              size="large"
              type="primary"
              className={styles.investmentPlanBtn}
              onClick={() => {
                router.push(YIELD_PATHS.YIELD_INVESTMENT_HISTORY);
              }}>
              {history_btn_text}
            </Button>
          </Col>
        </Row>
      </div>
      <Row className="section">
        <Col span={24}>
          <Tabs
            items={tabItems}
            onChange={handleChangeTab}
            className={styles.tab}
            activeKey={currentTab}
          />
          <div className={styles.list}>
            {currentTab === MYDCA_PLAN_TAB.all && _renderTabContent(allNextTokenRef)}
            {currentTab === MYDCA_PLAN_TAB.active && _renderTabContent(activeNextTokenRef)}
            {currentTab === MYDCA_PLAN_TAB.cancelled && _renderTabContent(canceledNextTokenRef)}
          </div>
        </Col>
      </Row>
      <Modal
        className={styles.autoModal}
        open={cancelVisible}
        confirmLoading={loading}
        title={intl["savings.automatic.modal.cancel.title"]}
        width={480}
        onOk={handleCancel}
        onCancel={handleCloseCancelVisible}
        okText={intl["button.confirm"]}
        cancelText={intl["button.cancel"]}
        destroyOnClose={true}>
        <p>{intl["savings.automatic.modal.cancel.content"]}</p>
      </Modal>
      {modalVisible ? (
        <ModalAutomaticInvestment
          type="show"
          visible={modalVisible}
          autoFinancingChecked={isTransferYield}
          autoStakingChecked={isTransferStaking}
          handleCancel={showOrCloseModal}
          handleChangeIsTransferYield={_isTransferYield}
          handleChangeIsTransferStaking={_isTransferStaking}
          info={confirmInfo}
          currentInfo={currentInfo}
          showAutoFinancing={showAutoFinancing}
          showAutoStaking={showAutoStaking}
          handleOk={() => {
            getInitData(currentTab);
          }}
        />
      ) : null}
      {showSafetyVerificationModal ? (
        <ModalSafetyVerification
          confirmLoading={loading}
          visible={showSafetyVerificationModal}
          handleCancel={() => {
            setShowSafetyVerificationModal(false);
          }}
          onOk={hideOK}
          businessType={-1}
          isCustomerValidate={true}
        />
      ) : null}
    </section>
  );
}

export const PageMyDcaPlan = InvestmentPlan;
