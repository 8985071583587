import React, { Component } from "react";
import styles from "./Login.module.less";
import Image from "next/image";
import logo from "@aspen/assets/images/aspen_logo_black@2x.svg";
import { handleBlur, handleFocus, i18nUtil, REG_EXP_PASSWORD } from "@aspen/libs";
import LanguageSwitch from "./LanguageSwitch";
import clsx from "clsx";
import DownloadAppQrCode from "./DownloadAppQrCode";

interface IProps {}

interface IState {
  // status: ILoading;
}

export default (WrappedComponent) => {
  class LoginLayout extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);

      this.state = {
        // status: "loading"
      };

      // this.updateStatus = this.updateStatus.bind(this);
    }

    /**
     * @description: 校验确认密码格式以及是否和密码一致，重置密码和设置密码使用
     * @params {
     *  confirmPassword：确认密码，
     *  password：密码，
     *  message：格式或者长度错误提示，
     *  notMatch：两次密码不一致时的提示
     * }
     * @return {Promise}
     */
    passwordConfirmValidator: (
      confirmPassword: string,
      password: string,
      message: string,
      notMatch: string
    ) => Promise<any> = (
      // promise返回any，不修改
      confirmPassword,
      password,
      message,
      notMatch
    ) => {
      if (!confirmPassword) {
        return Promise.reject(new Error(message));
      }
      if (password !== confirmPassword) {
        return Promise.reject(new Error(notMatch));
      }
      if (
        !confirmPassword ||
        password.length !== confirmPassword.length ||
        !REG_EXP_PASSWORD.test(confirmPassword)
      ) {
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    };

    // updateStatus: (status: ILoading) => void = (status) => {
    //   this.setState({
    //     status: status
    //   });
    // };

    render() {
      const intl = i18nUtil.t();
      return (
        // <Spin
        //   renderLoading={<Loading path="/animations/loading.json" />}
        //   status={this.state.status}>
        // <section className={clsx(styles.login, "login", formatwebp ? "png" : "123")}>
        <section className={clsx(styles.login, "login")}>
          <LanguageSwitch />
          <div className={styles.main}>
            <div className={styles.mainContainer}>
              <DownloadAppQrCode />
              <section className={styles.leftBrief}>
                <div className={styles.logo}>
                  <Image unoptimized src={logo} alt="" />
                </div>
                <p className={styles.title}>{intl["login.title"]}</p>
              </section>
              <WrappedComponent
                {...this.props}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                // updateStatus={this.updateStatus}
              />
            </div>
          </div>
        </section>
        // </Spin>
      );
    }

    componentDidMount(): void {}
  }

  return LoginLayout;
};
