import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Spin, Select, Button } from "antd";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import styles from "@aspen/theme/Wallet.module.less";
import {
  WALLET_PATHS,
  convertUSD2USDC,
  decimalPointNoMoreX,
  getQueryValue,
  i18nUtil,
  numberToThousands,
  reportEvent
} from "@aspen/libs";
import {
  getVaultAssetCurrencyConfig,
  showVaultDepositAddress,
  vaultWalletWithdraw
} from "@aspen/services";
import {
  IBusinessVerificationHeaderModel,
  IReqSecurityVerifyHeader,
  ECurrencyAssetQuotasPeriod,
  IBindingBusinessType,
  VAULT_ACTION_TYPE
} from "@aspen/model";
import { message, NumericInput } from "@aspen/ui";
import { VaultHistory, ModalBusinessVerification } from "../../../index";
import { WithRiskReminder } from "../../../withRiskReminder/WithRiskReminder";

import tips from "@aspen/assets/images/ic_tips.png";
import vaultWithdrawImg from "@aspen/assets/images/vaultWithdraw.png";

interface IProps {}

const VaultWithdraw: React.FC<IProps> = () => {
  const intl = i18nUtil.t();
  const coldWallet = intl["wallet.cold.wallet"];
  const coldWithdraw = intl["wallet.cold.withdraw"];
  const information = intl["wallet.vault.withdraw.bread"];
  const notice = intl["wallet.deposit.btc.notice"];
  const location = intl["kyc.certify.location"];
  // const spotAddress = intl["wallet.vault.spot.address"];
  const withdraw = intl["wallet.vault.button.withdraw"];
  const spotAvailable = intl["wallet.vault.cold.available"];
  const toSpotAccount = intl["wallet.vault.withdraw.to.spot.account"];
  const withdrawAmount = intl["wallet.vault.withdraw.amount"];
  const withdrawSuccess = intl["wallet.vault.withdrawal.success"];
  const withdrawFailed = intl["wallet.vault.withdrawal.failed"];
  const toSpotTips = intl["wallet.vault.to.spot.tips"];

  const router = useRouter();
  const currency = (router?.query?.currency as string) ?? getQueryValue("currency");
  const withdrawMinAmount = useRef<number>(0);

  const [state, setState] = useState<any>({
    loading: false,
    amount: "",
    currentCoin: currency || "",
    currentChain: null,
    coinList: [],
    chainList: [],
    depositAddress: "",
    currentTag: "",
    availableNetwork: 0,
    depositNetwork: 0,
    curAvailableSpotBalance: 0,
    confirmLoading: false,
    showWithdrawVerification: false
  });

  const handleSetState = (stateObj) => {
    setState((prevState) => ({ ...prevState, ...stateObj }));
  };

  useEffect(() => {
    fetchChainList(currency);
  }, [currency]);

  useEffect(() => {
    getMinWithdrawAmount();
  }, [state.chainList, state.currentChain]);

  // 获取链地址
  const fetchChainList: (currency: string) => void = (currency) => {
    getVaultAssetCurrencyConfig({ currency, walletType: 1 })
      .then((res) => {
        if (res?.code == 0) {
          const { data } = res;
          const chainList =
            (data && data[0]?.assets?.filter((item) => item.chainType !== "FIAT")) || [];
          handleSetState({
            chainList,
            currentChain: chainList[0]?.asset || "",
            availableNetwork: (data && data[0]?.assets?.[0]?.availableNetwork) || 0,
            depositNetwork: (data && data[0]?.assets?.[0]?.depositNetwork) || 0,
            curAvailableSpotBalance: (data && data[0]?.available) || 0
          });

          showWalletDepositAddress(chainList[0]?.asset);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        handleSetState({
          loading: false
        });
      });
  };

  // 先获取已生成电子货币地址 没有则请求生成电子货币地址（多链传入coincode为链的asset）
  const showWalletDepositAddress: (coincode: string) => void = (coincode) => {
    handleSetState({ loading: true });

    const params = {
      currency: coincode,
      required: true
    };

    showVaultDepositAddress(params)
      .then((res) => {
        if (res?.code == "0") {
          if (res?.data?.address) {
            handleSetState({
              depositAddress: res.data.address,
              currentTag: res.data?.memo && res.data?.memo,
              loading: false
            });
          }
        } else {
          handleSetState({
            depositAddress: "",
            loading: false
          });
        }
      })
      .finally(() => {
        handleSetState({ loading: false });
      });
  };

  const getMinWithdrawAmount = () => {
    // 提币最小限额对应 quotas 中 period为ONCE的值
    const _currentChainData = state.chainList.find((item) => item.asset == state.currentChain);
    const quotasList = _currentChainData?.quotas;
    const onceQuotas = quotasList?.find((item) => item?.period === ECurrencyAssetQuotasPeriod.once);
    withdrawMinAmount.current = onceQuotas?.minAmount;
  };

  const checkAmount = () => {
    // 用户输入小于冷钱包的最小充币额时，报错提示：
    if (Number(state.amount) < withdrawMinAmount.current) {
      return `${intl["wallet.minimum.withdraw"]} ${decimalPointNoMoreX(withdrawMinAmount.current)}`;
    }

    // 用户输入超过账户可用余额时，报错提示
    if (Number(state.amount) > state.curAvailableSpotBalance) {
      return `${intl["wallet.exceed.max.spot.available"]} ${decimalPointNoMoreX(
        state?.curAvailableSpotBalance
      )} ${currency}`;
    }

    return false;
  };

  const handleChangeChain = (value: string) => {
    reportEvent({
      moduleName: "vaultWithdrawSelectChain",
      detailParams: {
        chain: value,
        currency: state.currentCoin
      }
    });
    showWalletDepositAddress(value);
    handleSetState({
      currentChain: value
    });
  };

  const handleChangeAmount = (e) => {
    let params = e.replace(/[^\d.]/g, "").replace(/\.{2,}/g, "."); //数字
    const value = params.replace(/^(-)*(\d+)\.(\d{1,8}).*$/, "$1$2.$3"); //八位小数
    handleSetState({ amount: value });
  };

  const handleMaxAmount = () => {
    handleSetState({ amount: state.curAvailableSpotBalance });
  };

  const confirmInfo = (verifyParam: IBusinessVerificationHeaderModel) => {
    handleSetState({ confirmLoading: true });
    const mergedParam = {
      address: state.depositAddress,
      amount: state.amount,
      currency: state.currentChain || "",
      memo: state.currentTag || "",
      outerBusinessId: ""
    };
    let headerParams: IReqSecurityVerifyHeader = {
      businessType: IBindingBusinessType.vaultWalletWithdraw,
      smsType: 0
    };
    headerParams = Object.assign({}, headerParams, verifyParam);
    reportEvent({
      moduleName: "vaultWithdraw",
      detailParams: {
        amount: mergedParam.amount,
        currency: mergedParam.currency
      }
    });
    vaultWalletWithdraw(mergedParam, headerParams)
      .then((res) => {
        if (res?.code == "0") {
          message.success(withdrawSuccess, 2).then(() => {
            fetchChainList(currency);
            handleSetState({ showWithdrawVerification: false });
          });
        } else {
          let msg = intl?.[res?.msg];
          if (!msg) {
            msg = intl?.[res?.code] ?? withdrawFailed;
          }
          message.error(msg);
        }
      })
      .finally(() => {
        handleSetState({ confirmLoading: false });
      });
  };

  return (
    <section className={styles.vaultStorage}>
      <Spin spinning={state.loading}>
        <Breadcrumb>
          <Breadcrumb.Item>{location}:</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link href={WALLET_PATHS.WALLET_VAULT}>{coldWallet}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{information}</Breadcrumb.Item>
        </Breadcrumb>

        <div className={styles.withdrawContent}>
          <p className={styles.title}>
            <p className={styles.title}>{coldWithdraw}</p>
          </p>
          <div className={styles.vaultTypeContent}>
            <span className={styles.img}>
              <Image alt="" src={vaultWithdrawImg} width={24} height={24} />
            </span>
            <span className={styles.text}>{toSpotAccount}</span>
          </div>
          <div className={styles.withdrawTips}>
            <Image alt="" unoptimized src={tips} width="18" height="18" />
            <span>{toSpotTips}</span>
          </div>
          {/* select Network */}
          <div className={styles.chainTypeArea}>
            <p className={styles.label}>{intl["wallet.deposit.btc.select.network"]}</p>
            <Select
              className={styles.selectCoin}
              value={state.currentChain}
              style={{ width: 160 }}
              onChange={handleChangeChain}>
              {state.chainList.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.asset}>
                    {item.chainName}
                  </Select.Option>
                );
              })}
            </Select>
          </div>

          {/* Withdrawal Amount */}
          <div className={styles.amountArea} style={{ marginBottom: 32 }}>
            <div className={styles.topArea}>
              <span>{withdrawAmount}</span>
              <span onClick={handleMaxAmount}>{intl["savings.fixed.max"]}</span>
            </div>
            <div className={styles.bottomArea}>
              <div className={styles.amountTop}>
                <NumericInput
                  bordered={false}
                  value={state.amount}
                  placeholder={`${spotAvailable}:${numberToThousands(
                    state?.curAvailableSpotBalance
                  )}`}
                  className={styles.amountInput}
                  onChangeEvent={handleChangeAmount}
                  suffix={convertUSD2USDC(state.currentCoin)}
                />
              </div>
            </div>
            <div className={styles.amountTipContent}>
              {state.amount != "" && checkAmount() && (
                <p className={styles.limitTips}>{checkAmount()}</p>
              )}
            </div>
          </div>
          {/*/!* spot地址 *!/*/}
          {/*<p className={styles.label}>{spotAddress}</p>*/}
          {/*<div className={styles.copyArea}>*/}
          {/*  <Input type="text" value={state.depositAddress} disabled />*/}
          {/*</div>*/}
          <Button
            style={{ marginTop: 16 }}
            type="primary"
            onClick={() => {
              handleSetState({ showWithdrawVerification: true });
            }}
            disabled={!(state.amount && !checkAmount())}>
            {withdraw}
          </Button>
          {/* Notice */}
          <ul className={styles.withdrawNoticeContent}>
            <li className={styles.title}>{notice}</li>
            <li>{intl["wallet.vault.withdraw.notice.first"]}</li>
            <li>{intl["wallet.vault.withdraw.notice.second"]}</li>
            <li>{intl["wallet.vault.withdraw.notice.third"]}</li>
          </ul>
        </div>
      </Spin>
      {state.showWithdrawVerification ? (
        <ModalBusinessVerification
          confirmLoading={state.confirmLoading}
          visible={state.showWithdrawVerification}
          businessType={IBindingBusinessType.vaultWalletWithdraw}
          confirm={(res) => {
            confirmInfo(res);
          }}
          cancel={() => {
            handleSetState({ showWithdrawVerification: false });
          }}
        />
      ) : null}
      <VaultHistory
        showViewAll={true}
        type={VAULT_ACTION_TYPE.WITHDRAW}
        currency={state.currentCoin}
      />
    </section>
  );
};

export const PageVaultWithdraw = WithRiskReminder(React.memo(VaultWithdraw));
