import React from "react";
import styles from "@aspen/theme/Savings.module.less";
import { IMessageFromApp, IYtdTrend } from "@aspen/model";
import { sendToApp } from "@aspen/libs";
import BtcChart from "../../yield/BtcChart";

interface IProps {}

interface IState {
  data: IYtdTrend | undefined;
}

class AppBtcYtd extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { data: undefined };
  }

  componentDidMount() {
    window.sendToH5 = this._fromAPP;
    sendToApp({ type: "onLoad" });
  }

  /**
   * app调用
   * @param message
   */
  _fromAPP = (message: string) => {
    let res: IMessageFromApp = JSON.parse(message);
    if (!res?.type) {
      return;
    }
    const { type, data } = res;
    switch (type) {
      /**
       * app选中资产分布中的货币
       */
      case "setDate": {
        data && this.setData(data);
        break;
      }
    }
  };

  setData = (data: IYtdTrend) => {
    this.setState({ data: data });
  };

  render() {
    const { data } = this.state;
    return (
      <div className={styles.appBtcDca}>
        <BtcChart ytdTrend={data} />
      </div>
    );
  }
}

export const PageAppBtcYtd = AppBtcYtd;
