import React from "react";
import { Tooltip } from "antd";
import {appOnlyDownload, i18nUtil} from "@aspen/libs";

import QRCode from "qrcode.react";
import qrcodeLogo from "@aspen/assets/images/qrcodeLogo.png";
import downloadApp from "@aspen/assets/images/download_app.png";
import Image from "next/image";

const DownloadAppQrCode: React.FC = () => {
  const intl = i18nUtil.t();
  const downloadAppText = intl["download.app.ios.and.android"];

  const gotoAppCode = (
    <div style={{ width: 150, padding: "22px 25px 15px" }}>
      <QRCode includeMargin value={appOnlyDownload} size={96} level="H" />
      <div
        style={{
          width: 18,
          height: 18,
          position: "absolute",
          top: "67px",
          left: "72px"
        }}>
        <Image unoptimized src={qrcodeLogo} width={18} height={18} alt="" />
      </div>
      <p style={{ marginTop: 15 }}>{downloadAppText}</p>
    </div>
  );

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        cursor: "pointer",
        zIndex: 9
      }}>
      <Tooltip placement="right" color="rgba(0, 0, 0, 0.74)" title={gotoAppCode}>
        <Image unoptimized src={downloadApp} width={36} height={36} alt="" />
      </Tooltip>
    </div>
  );
};

export default React.memo(DownloadAppQrCode);
