import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Divider, Spin, Table, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import Link from "next/link";
import { useRouter } from "next/router";
import styles from "@aspen/theme/Structured.module.less";
import { IAccumulatorOrderModel } from "@aspen/model";
import {
  i18nUtil,
  convertUSD2USDC,
  decimalPointNoMoreXNoFill,
  getQueryValue,
  isComplementation,
  numberToThousands,
  certifiedKycLevel,
  reportEvent,
  STRUCTURED_PATH,
  REG_EXP_POSITIVE_NUMBER,
  STRUCTURED_TYPES,
  GA_EVENT_NAME,
  GA_EVENT_TAG
} from "@aspen/libs";
import {
  availableSpots,
  getAccumulatorOrderDetail,
  getAccumulatorProductDetail,
  postAccumulatorOrder
} from "@aspen/services";
import { WithTrimInput, message, KycGuideTips, CoinImage, Disclaimer } from "@aspen/ui";
import { Settlement, ModalSubscribeResult, ModalSuspend, InitialPriceTooltip } from "../../index";

interface IProps {
  showToProfileBtn?: boolean;
}

const AccumulatorSubscribe = (props: IProps) => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const id = router?.query?.id?.toString() ?? getQueryValue("id") ?? "";
  const underlying = router?.query?.underlying?.toString() ?? getQueryValue("underlying") ?? "";
  const quote = router?.query?.quote?.toString() ?? getQueryValue("quote") ?? "";
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(null);
  const [available, setAvailable] = useState(null);
  const [openResultModal, setOpenResultModal] = useState<boolean>(false);
  const [openSuspendModal, setOpenSuspendModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<IAccumulatorOrderModel>();

  const goBack = () => {
    reportEvent({
      moduleName: GA_EVENT_NAME.accumulator.cancelSubscribePage,
      detailParams: {
        id,
        underlying,
        quote
      }
    });
    router.back();
  };

  /**
   * 累计期权详情
   */
  const fetchDetail = async () => {
    try {
      const res = await getAccumulatorProductDetail({ underlying, quote, id });
      setLoading(false);
      // 查询累计期权详情接口如果异常，则返回前一个页面
      if (res?.code !== "0") {
        message.error(intl[res?.msg] ?? res?.msg);
        goBack();
        return;
      }
      if (!res?.data || Object.keys(res?.data).length <= 0) {
        goBack();
        return;
      }
      setData(res?.data);
    } catch (err) {
      setLoading(false);
      goBack();
    }
  };

  /**
   * 获取现货账户币种余额
   */
  const fetchAvailable = async () => {
    const res = await availableSpots({ currency: quote, category: 0 });
    if (res?.code !== "0") {
      message.error(intl[res?.msg] ?? res?.msg);
      return;
    }
    setAvailable(res?.data?.[quote]);
  };

  useEffect(() => {
    fetchDetail();
    fetchAvailable();
  }, []);

  const { subscriptionMin, period, tickSize, productId } = data ?? {};
  const onChange = (inputValue: string) => {
    // 只能输入正数
    if (!REG_EXP_POSITIVE_NUMBER.test(inputValue)) {
      return;
    }
    // 输入框限制最多允许输入八位小数
    let params = inputValue.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".");
    const value = params.replace(/^(-)*(\d+)\.(\d{1,8}).*$/, "$1$2.$3");
    if (isNaN(Number(value))) {
      return;
    }

    setValue(value);
    // 校验输入框输入逻辑
    const amount = Number(value);
    // 判断申购数量不能低于最小申购额
    if (amount < subscriptionMin) {
      const error = i18nUtil.formatMessage(
        { id: "structured.accumulator.subscribe.minimum" },
        { amount: subscriptionMin, coin: convertUSD2USDC(quote) }
      );
      setError(error);
      return;
    }
    if (amount > Number(available)) {
      const error = intl["structured.accumulator.subscribe.insufficient"];
      setError(error);
      return;
    }
    if (tickSize && !isComplementation(amount, tickSize)) {
      const error = i18nUtil.formatMessage(
        { id: "structured.accumulator.subscribe.multiple" },
        { amount: tickSize ?? "--" }
      );
      setError(error);
      return;
    }
    setError(null);
  };
  const columns = [
    {
      title: intl["structured.accumulator.period"],
      dataIndex: "period",
      key: "period",
      render: (text) => (
        <p style={{ textAlign: "center" }} className={styles.valueText}>
          {text} {text ? intl["structured.accumulator.week"] : "--"}
        </p>
      )
    },
    {
      title: (
        <>
          <span>{intl["structured.accumulator.strike.price"]}</span>
          <Tooltip title={intl["structured.accumulator.strike.price.desc"]}>
            <InfoCircleOutlined
              style={{ paddingLeft: "8px", cursor: "pointer", color: "rgba(255, 255, 255, 0.6)" }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "strike",
      key: "strike",
      render: (text) => (
        <p style={{ textAlign: "center" }} className={styles.valueText}>
          {text ? `${(Number(text) * 100).toFixed(2)}%` : "--"}
        </p>
      )
    },
    {
      title: (
        <>
          <span>{intl["structured.accumulator.KO.price"]}</span>
          <Tooltip title={intl["structured.accumulator.KO.price.desc"]}>
            <InfoCircleOutlined
              style={{ paddingLeft: "8px", cursor: "pointer", color: "rgba(255, 255, 255, 0.6)" }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "knockOutPrice",
      key: "knockOutPrice",
      render: (text) => (
        <p style={{ textAlign: "center" }} className={styles.valueText}>
          {text ? `${(Number(text) * 100).toFixed(2)}%` : "--"}
        </p>
      )
    },
    {
      title: intl["structured.accumulator.expiry.date"],
      dataIndex: "expiry",
      key: "expiry",
      render: (text) => (
        <p className={styles.valueText}>{text ? dayjs(text).format("YYYY-MM-DD") : "--"}</p>
      )
    }
  ];

  const checkOrder = async (data) => {
    const res = await getAccumulatorOrderDetail(data?.orderId);
    setLoading(false);
    setOrder(res?.data ?? data);
    setOpenResultModal(true);
    reportEvent({
      joinedTag: GA_EVENT_TAG.Modal,
      moduleName: GA_EVENT_NAME.accumulator.subsResult,
      detailParams: {
        id: productId,
        itemId: id,
        amount: value
      }
    });
  };

  const placeOrder = async () => {
    try {
      setLoading(true);
      reportEvent({
        moduleName: GA_EVENT_NAME.accumulator.submitSubscribe,
        detailParams: {
          id: productId,
          itemId: id,
          amount: value,
          structuredType: STRUCTURED_TYPES.accumulator
        }
      });
      const res = await postAccumulatorOrder({
        productId,
        itemId: id,
        amount: value
      });
      if (res?.code !== "0") {
        setLoading(false);
        if (res?.msg === "order.book.not.exist") {
          reportEvent({
            joinedTag: GA_EVENT_TAG.Modal,
            moduleName: GA_EVENT_NAME.accumulator.openSuspended,
            detailParams: {
              id: productId,
              itemId: id,
              amount: value
            }
          });
          setOpenSuspendModal(true);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
        return;
      }
      // 后端表格存储有最长3s的同步延迟，下单接口成功直接查询订单详情接口，有可能返回order.not.exist
      // 为了避免出现order.not.exist，同时保证前端用户体验不是太差，延迟3s再请求订单详情
      setTimeout(() => {
        checkOrder(res?.data);
      }, 3000);
    } catch (err) {
      setLoading(false);
    }
  };
  const cancelResultModal = () => {
    setValue("");
    setOpenResultModal(false);
  };
  const cancelSuspendModal = () => setOpenSuspendModal(false);
  const today = dayjs().hour(16).minute(0);
  const day = dayjs().valueOf() > today.valueOf() ? 1 : 0;
  return (
    <>
      <section className={styles.structuredSubscribe}>
        <Spin spinning={loading}>
          <div className="flex">
            <Breadcrumb>
              <Breadcrumb.Item>{intl["kyc.certify.location"]}: </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  href={{
                    pathname: STRUCTURED_PATH.STRUCTURED,
                    query: { currentTab: STRUCTURED_TYPES.accumulator }
                  }}>
                  {intl["wallet.structural.accumulator"]}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{intl["wallet.structural.accumulator.subscribe"]}</Breadcrumb.Item>
            </Breadcrumb>
            {!certifiedKycLevel(1) ? (
              <KycGuideTips showToProfileBtn={props?.showToProfileBtn} />
            ) : null}
          </div>
          <div className={styles.accumulatorSubscribeBox}>
            <div className={styles.subHeaderBox}>
              <CoinImage
                // @ts-ignore
                size={45}
                showOpacity
                coins={`${underlying},${convertUSD2USDC(quote)}`}
              />
              <div className={styles.titleBox}>
                <p className={styles.titleText}>
                  {underlying}-{convertUSD2USDC(quote)}
                  &nbsp;
                  {intl["wallet.structural.accumulator"]}
                </p>
                <p className={styles.descText}>
                  {i18nUtil.formatMessage(
                    { id: "structured.accumulator.subscribe.desc" },
                    { coin: underlying }
                  )}
                </p>
              </div>
            </div>
            <div className={styles.accumulatorSubscribeBoxContent}>
              <div style={{ flex: "1 1 auto" }}>
                <Table
                  style={{ marginTop: "34px" }}
                  rowKey={(record) => record?.id}
                  bordered={false}
                  dataSource={[data]}
                  columns={columns}
                  pagination={false}
                  footer={() => (
                    <div className={styles.tableFooter}>
                      <div>
                        <span>{intl["structured.accumulator.initial.price"]}</span>
                        <Tooltip
                          overlayClassName={styles.overlayInnerStyle}
                          overlayInnerStyle={{ padding: "18px" }}
                          title={() => <InitialPriceTooltip />}>
                          <InfoCircleOutlined className={styles.toolTipIcon} />
                        </Tooltip>
                      </div>
                      <span>
                        {dayjs(today).add(day, "d").format("YYYY-MM-DD HH:mm")}{" "}
                        {intl["button.confirm"]}
                      </span>
                    </div>
                  )}
                />
                <div>
                  <p className={styles.amountTitleText}>
                    {intl["structured.accumulator.subscribe.available.amount"]}:
                  </p>
                  <p className={styles.amountText}>
                    {available ? numberToThousands(available) : "--"} {convertUSD2USDC(quote)}
                  </p>
                  <p className={styles.inputTitleText}>
                    {intl["structured.accumulator.subscribe.amount.title"]}:
                  </p>
                  <WithTrimInput
                    value={value}
                    addonAfter={
                      <div
                        onClick={() => onChange((available ?? "").toString())}
                        className={styles.maxTextStyle}>
                        {intl["savings.fixed.max"]}
                      </div>
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                  {error && <p className={styles.errorText}>{error}</p>}
                </div>
                <Divider />
                <div className={styles.flexRow} style={{ marginBottom: "24px" }}>
                  <span className={styles.keyText}>
                    {intl["structured.accumulator.subscribe.min.amount"]}
                  </span>
                  <span>
                    {numberToThousands(subscriptionMin)} {convertUSD2USDC(quote)}
                  </span>
                </div>
                <div className={styles.flexRow}>
                  <span className={styles.keyText}>
                    {intl["structured.accumulator.subscribe.max.amount.per"]}
                  </span>
                  <span>
                    {period
                      ? numberToThousands(decimalPointNoMoreXNoFill(Number(value) / Number(period)))
                      : "--"}{" "}
                    {convertUSD2USDC(quote)}
                  </span>
                </div>
                <Divider />
                <p className={styles.noticeText}>
                  {intl["structured.accumulator.subscribe.notice"]}
                </p>
                <>
                  <Button onClick={goBack} className={styles.btnLeft}>
                    {intl["button.cancel"]}
                  </Button>
                  <Button
                    loading={loading}
                    className={styles.btnRight}
                    disabled={!(value && !error)}
                    type="primary"
                    onClick={placeOrder}>
                    {intl["button.subscribe"]}
                  </Button>
                </>
              </div>
              <Settlement data={data} />
            </div>
          </div>
          {openResultModal && (
            <ModalSubscribeResult
              isVisible={openResultModal}
              order={order}
              cancelModal={cancelResultModal}
            />
          )}
          {openSuspendModal && (
            <ModalSuspend isVisible={openSuspendModal} cancelModal={cancelSuspendModal} />
          )}
        </Spin>
      </section>
      <Disclaimer />
    </>
  );
};

export const PageAccumuSubscribe = React.memo(AccumulatorSubscribe);
