import React, { useEffect, useState } from "react";
import { Breadcrumb, Button } from "antd";
import { useRouter } from "next/router";
import Link from "next/link";
import styles from "@aspen/theme/Strategy.module.less";
import { IKycLevel, IStrategyDetail, COMMON_CACHE_PRIORTY_KEYS, STRATEGY_TYPE } from "@aspen/model";
import { availableSpots } from "@aspen/services";
import { GA_EVENT_NAME, GA_EVENT_TAG, STRATEGIES_PATHS, i18nUtil, reportEvent } from "@aspen/libs";
import { useCachePriorityData } from "@aspen/hooks";
import { WithKYCInfo, HtmlContentComponent } from "@aspen/ui";
import { Disclaimer, ModalStrategyResult, ModalSubscribeRedeem } from "../../index";

interface IProps {
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
  info: IStrategyDetail;
}

const Detail: React.FC<IProps> = (props) => {
  const [subscribeRedeemModalVisible, setSubscribeRedeemModalVisible] = useState<boolean>(false);
  const [resultModalVisible, setResultModalVisible] = useState<boolean>(false);
  const [actionType, setActionType] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [fetchCode, setFetchCode] = useState<string>("0");
  const [fetchAmount, setFetchAmount] = useState<string>("0");

  const intl = i18nUtil.t();
  const strategies_text: string = intl["strategies.detail.strategies"];
  const details_text: string = intl["strategies.detail.details"];

  const router = useRouter();
  // @ts-ignore
  const [info, updateInfo] = useState<IStrategyDetail>(null);
  // @ts-ignore
  const { data } = useCachePriorityData<any>(
    COMMON_CACHE_PRIORTY_KEYS.strategiesDetail,
    router.query?.id
      ? {
          id: router.query?.id
        }
      : null
  );
  useEffect(() => {
    updateInfo(data);
  }, [data]);

  // 是否开始或者已经过期
  const isExpired = () => {
    const endTime = +info?.endTime;
    const startTime = +info?.startTime;
    const now = +new Date().getTime();
    if (now > endTime) {
      return "close";
    }
    if (now < startTime) {
      return "comingSoon";
    }
    return false;
  };

  // 点击申购赎回
  const handleClick = (type: number) => {
    if (props.showGuideModal(2)) {
      const params = {
        currency: info?.currency ?? ""
      };
      // type : 0 申购；1 赎回
      if (type === 0) {
        availableSpots(params).then((res) => {
          if (res?.code == "0") {
            setBalance(res.data?.[info?.currency]);
            setActionType(type);
            setSubscribeRedeemModalVisible(true);
            reportEvent({
              joinedTag: GA_EVENT_TAG.Modal,
              moduleName: GA_EVENT_NAME.strategies.detail.subsModal,
              detailParams: { id: info?.id }
            });
          }
        });
      } else {
        setActionType(type);
        setSubscribeRedeemModalVisible(true);
        reportEvent({
          joinedTag: GA_EVENT_TAG.Modal,
          moduleName: GA_EVENT_NAME.strategies.detail.redeemModal,
          detailParams: { id: info?.id }
        });
      }
    }
  };

  // 申购/赎回 请求完成回调
  const handleResultCallback = (code: string, amount: string) => {
    setFetchCode(code);
    setFetchAmount(amount);
    setSubscribeRedeemModalVisible(false);
    setResultModalVisible(true);

    reportEvent({
      joinedTag: GA_EVENT_TAG.Modal,
      moduleName:
        actionType == 0
          ? GA_EVENT_NAME.strategies.detail.subsResult
          : GA_EVENT_NAME.strategies.detail.redeemResult,
      detailParams: { id: info?.id }
    });
  };

  return (
    <div className={styles.detail}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href={STRATEGIES_PATHS.STRATEGIES}>{strategies_text}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{details_text}</Breadcrumb.Item>
      </Breadcrumb>
      <section id="title">
        <p className={styles.title}>{info?.name}</p>
        <div className="flex" style={{ marginBottom: 24 }}>
          <div className={styles.categroy}>{intl[STRATEGY_TYPE[info?.type]]}</div>
          {info?.attachmentUrl && info?.attachmentName ? (
            <Button
              type={"ghost"}
              className={styles.attachment}
              onClick={() => {
                reportEvent({
                  moduleName: GA_EVENT_NAME.strategies.detail.openLink,
                  detailParams: {
                    type: "strategy"
                  }
                });
                window.open(info?.attachmentUrl ?? "");
              }}>
              <span>{info?.attachmentName}</span>
            </Button>
          ) : null}
        </div>
      </section>
      <div className={styles.detailContent}>
        <HtmlContentComponent htmlContent={info?.detailInformation} />
        {info?.name && (
          <div className={styles.btnWrap}>
            {isExpired() ? (
              <div className={styles.subBtnWrap}>
                <Button type="primary" disabled className={styles.actionBtn}>
                  {isExpired() === "close" ? intl["button.closed"] : intl["button.comingSoon"]}
                </Button>
              </div>
            ) : (
              <div className={styles.subBtnWrap}>
                <Button type="primary" className={styles.actionBtn} onClick={() => handleClick(0)}>
                  {intl["button.subscribe"]}
                </Button>
                {info?.amount && (
                  <Button
                    type="primary"
                    className={styles.actionBtn}
                    onClick={() => handleClick(1)}>
                    {intl["button.redeem"]}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Disclaimer />
      {subscribeRedeemModalVisible ? (
        <ModalSubscribeRedeem
          visible={subscribeRedeemModalVisible}
          type={actionType}
          info={info}
          balance={balance}
          handleResultCallback={(code: string, amount: string) => {
            handleResultCallback(code, amount);
          }}
          cancelModal={() => {
            setSubscribeRedeemModalVisible(false);
          }}
        />
      ) : null}
      {resultModalVisible ? (
        <ModalStrategyResult
          visible={resultModalVisible}
          type={actionType}
          info={info}
          amount={fetchAmount}
          code={fetchCode}
          cancelModal={() => {
            setResultModalVisible(false);
          }}
        />
      ) : null}
    </div>
  );
};

export const PageStrategiesDetail = WithKYCInfo(Detail);
