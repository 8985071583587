import React from "react";
import { withRouter } from "next/router";
import Link from "next/link";
import { Breadcrumb, Button, Spin } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import styles from "@aspen/theme/Wallet.module.less";
import {
  ICurrencyAssetQuotas,
  IInjectProps,
  IBusinessVerificationHeaderModel,
  IReqSecurityVerifyHeader,
  IBindingBusinessType,
  IUserEventEnums
} from "@aspen/model";
import {
  withdrawCircle,
  getCircleLimit,
  getAssetCurrencyConfig,
  userEventCheck
} from "@aspen/services";
import {
  numberToThousands,
  decimalPointNoMoreX,
  convertUSD2USDC,
  getQueryValue,
  computeNumber,
  reportEvent,
  i18nUtil,
  WALLET_PATHS,
  HISTORY_PATHS,
  USER_ROLE_POWER,
  USER_AUTH,
  OPERATE_CUSTOMER_EMAIL,
  GA_EVENT_NAME
} from "@aspen/libs";
import { message, WithTrimInput, ModalBase } from "@aspen/ui";
import { History, ModalBusinessVerification } from "../../../index";
import { WithRiskReminder } from "../../../withRiskReminder/WithRiskReminder";

interface IProps extends IInjectProps {}
interface IState {
  loading: boolean;
  addressInfo: string;
  bankName: string;
  withdrawAmount: string | number;
  leastPaceNum: number; // 每次提币的最小数量
  maxPaceNum: number; // 每次提币的最大数量
  balance: number; //当前币种余额
  feeType: string; //当前费率收取类型- PERCENTAGE:按照百分比, FIXED:按照固定值, MIXED:混合计算取两者较大的一项
  feeRate: number; //按比例收取时的费率
  feeCurrecy: string | number; //费用固定时的金额
  limitInfo: {
    oneMonthAmount: number | null;
    oneWeekAmount: number | null;
    oneMonthBalance: number | null;
    oneWeekBalance: number | null;
  };
  showApplicationModal: boolean; // 代客操作提币申请modal
  showBusinessVerification: boolean; // 安全验证弹窗
  showVerifyTipModal: boolean;
  verifyTip: string;
}
class WithdrawUSDC extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addressInfo: "",
      bankName: "",
      withdrawAmount: "",
      leastPaceNum: 0,
      maxPaceNum: 0,
      balance: 0,
      feeType: "",
      feeRate: 0,
      feeCurrecy: "",
      limitInfo: {
        oneMonthAmount: null,
        oneWeekAmount: null,
        oneMonthBalance: null,
        oneWeekBalance: null
      },
      showApplicationModal: false,
      showBusinessVerification: false,
      showVerifyTipModal: false,
      verifyTip: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.initData();
  }

  // 初始化数据
  initData = () => {
    this.setState({
      loading: true
    });
    userEventCheck({ userEventEnum: IUserEventEnums.ApplyWithdraw }).then((res) => {
      if (res?.code != "0") {
        const errors = [
          "customer.not.allowed.withdraw.after.modify.password",
          "customer.not.allowed.withdraw.after.modify.bind"
        ];
        if (errors.includes(res?.msg)) {
          this.setState({
            loading: false,
            showVerifyTipModal: true,
            verifyTip: res?.msg
          });
        } else {
          this.loadPageData();
        }
      } else {
        this.loadPageData();
      }
    });
  };

  loadPageData = () => {
    const currency = getQueryValue("currency");
    const addressInfo = getQueryValue("bankId");
    const bankName = decodeURIComponent(getQueryValue("bankName"));
    this.setState({
      addressInfo,
      bankName
    });
    this.getWithdrawUsdLimit(currency);
  };

  //获取当前币种限额
  getWithdrawUsdLimit = (currency) => {
    const param = {
      currency: currency
    };
    getAssetCurrencyConfig(param)
      .then((res) => {
        if (res?.code == "0") {
          const chainList = res.data[0]?.assets?.filter((item) => item.chainType === "FIAT") || [];
          const asset = chainList[0];
          // 此处和后台讨论 ，因中台优化，单次限额取period === "ONCE"并且category === "ASSET"
          const perTime: ICurrencyAssetQuotas =
            asset?.quotas &&
            asset.quotas?.filter((e) => e.period === "ONCE" && e.category === "ASSET")[0];
          this.setState({
            leastPaceNum: perTime?.minAmount, //单次最小额
            maxPaceNum: perTime?.maxAmount, //单次最大额
            feeType: asset?.withdrawFeeType,
            feeRate: asset?.withdrawFeeRate,
            feeCurrecy: asset?.withdrawFeeAmount,
            balance: decimalPointNoMoreX(res?.data[0]?.available, 2)
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
    getCircleLimit().then((res) => {
      if (res?.code == "0") {
        this.setState({
          limitInfo: res?.data
        });
      }
    });
  };

  getText(): { [propName: string]: any } {
    // 不修改
    const intl = i18nUtil.t();

    const location: string = intl["kyc.certify.location"];
    const wallet: string = intl["wallet"];
    const noticeTips: string = intl["wallet.withdraw.usdc.notice.tips"];
    const limit: string = intl["wallet.withdraw.limit"];
    const amount: string = intl["wallet.withdraw.amount"];
    const max: string = intl["wallet.withdraw.max"];
    const amountPlaceholder: string = intl["wallet.withdraw.amount.placeholder"];
    const confirm: string = intl["button.confirm"];
    const noticeTitle: string = intl["wallet.withdraw.notice"];
    const noticeList: string[] = intl["wallet.withdraw.usdc.notice.content"];

    const withdrawFiatAddress: string = intl["wallet.withdraw.fiat.address"];
    const withdrawSuccess: string = intl["wallet.withdraw.success.tips"];
    const withdrawFailed: string = intl["wallet.withdraw.failed.tips"];
    const withdrawFee: string = intl["wallet.circle.withdraw.fee"];
    const availableText: string = intl["available"];
    const weekText: string = intl["overview.myAsset.week"];
    const monthText: string = intl["overview.myAsset.month"];
    const applicationModalTitle: string = intl["wallet.withdraw.application.title"];
    const applicationModalOkText: string = intl["wallet.withdraw.application.OkText"];
    const OKText: string = intl["button.ok"];
    return {
      location,
      wallet,
      noticeTips,
      limit,
      amount,
      max,
      amountPlaceholder,
      confirm,
      noticeTitle,
      noticeList,
      withdrawFiatAddress,
      withdrawSuccess,
      withdrawFailed,
      withdrawFee,
      availableText,
      weekText,
      monthText,
      applicationModalTitle,
      applicationModalOkText,
      OKText
    };
  }

  handleSubmit() {
    this.setState({
      showBusinessVerification: true
    });
  }

  //修改金额
  handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let params = e.target.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, "."); //取数字
    const value = params.replace(/^(-)*(\d+)\.(\d{1,2}).*$/, "$1$2.$3"); //取两位
    this.setState({ withdrawAmount: value });
  };
  handleMaxAmount = () => {
    this.setState({ withdrawAmount: this.state.balance });
  };

  //提交信息
  submitInfo = (param: IBusinessVerificationHeaderModel) => {
    this.withdrawFiatFetch(param);
  };

  withdrawFiatFetch = (verifyParam: IBusinessVerificationHeaderModel) => {
    const intl = i18nUtil.t();
    const { withdrawAmount } = this.state;
    const currency = getQueryValue("currency");
    const { withdrawSuccess, withdrawFailed } = this.getText();
    this.setState({ loading: true });
    const param = {
      asset: currency,
      amount: withdrawAmount
    };
    let headerParams: IReqSecurityVerifyHeader = {
      businessType: IBindingBusinessType.applyWithdraw,
      smsType: 0
    };
    headerParams = Object.assign({}, headerParams, verifyParam);
    withdrawCircle(param, headerParams)
      .then((res) => {
        if (res?.code == "0") {
          const user_auth = (typeof window != "undefined" && localStorage.getItem(USER_AUTH)) || "";
          this.setState({ showBusinessVerification: false });
          if (USER_ROLE_POWER[user_auth]?.actionForOthers) {
            this.setState({
              showApplicationModal: true
            });
          } else {
            message.success(withdrawSuccess, 2).then(() => {
              this.props.router.push(WALLET_PATHS.WALLET);
            });
          }
        } else {
          let msg = intl?.[res?.msg];
          if (!msg) {
            msg = intl?.[res?.code] ?? withdrawFailed;
          }
          message.error(msg);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    reportEvent({
      moduleName: GA_EVENT_NAME.whithdraw.circleFiat,
      detailParams: { [currency]: withdrawAmount }
    });
  };

  //判断输入金额是否符合规范
  checkAmount = (param) => {
    const intl = i18nUtil.t();
    const currency = getQueryValue("currency");
    const { leastPaceNum, maxPaceNum, limitInfo, balance } = this.state;
    const value = Number(param);
    if (value < leastPaceNum) {
      return `${intl["wallet.withdraw.amount.error.tips.first"]} ${numberToThousands(
        decimalPointNoMoreX(leastPaceNum, 2)
      )} ${convertUSD2USDC(currency)}`;
    }
    if (value > balance) {
      return `${intl["wallet.withdraw.amount.error.tips.third"]} ${numberToThousands(
        decimalPointNoMoreX(balance, 2)
      )} ${convertUSD2USDC(currency)}`;
    }
    if (value > maxPaceNum) {
      return `${intl["wallet.withdraw.amount.error.tips.fourth"]} ${numberToThousands(
        decimalPointNoMoreX(maxPaceNum, 2)
      )} ${convertUSD2USDC(currency)}`;
    }
    if (value > Number(limitInfo.oneWeekBalance)) {
      return `${intl["wallet.withdraw.amount.error.tips.fifth"]} ${numberToThousands(
        decimalPointNoMoreX(limitInfo.oneWeekBalance, 2)
      )} ${convertUSD2USDC(currency)}`;
    }
    if (value > Number(limitInfo.oneMonthBalance)) {
      return `${intl["wallet.withdraw.amount.error.tips.sixth"]} ${numberToThousands(
        decimalPointNoMoreX(limitInfo.oneMonthBalance, 2)
      )} ${convertUSD2USDC(currency)}`;
    }
    return "success";
  };

  // 计算输入金额费率
  calculateFee: () => string = () => {
    const { withdrawAmount, feeType, feeRate, feeCurrecy } = this.state;
    let count: number = 0;

    switch (feeType) {
      case "PERCENTAGE":
        count = computeNumber(Number(withdrawAmount), "*", feeRate).result;
        break;
      case "FIXED":
        count = Number(feeCurrecy);
        break;
      case "MIXED":
        count = Math.max(
          computeNumber(Number(withdrawAmount), "*", feeRate).result,
          Number(feeCurrecy)
        );
        break;
    }
    return count?.toFixed(2);
  };

  handleVerifyModal = () => {
    this.setState({ showVerifyTipModal: false });
    this.props.router.back();
  };

  render(): React.ReactNode {
    const {
      location,
      wallet,
      noticeTips,
      limit,
      amount,
      max,
      confirm,
      noticeTitle,
      noticeList,
      withdrawFiatAddress,
      withdrawFee,
      availableText,
      weekText,
      monthText,
      applicationModalTitle,
      applicationModalOkText,
      OKText
    } = this.getText();
    const intl = i18nUtil.t();
    const {
      loading,
      balance,
      withdrawAmount,
      addressInfo,
      bankName,
      limitInfo,
      showApplicationModal,
      showBusinessVerification,
      showVerifyTipModal,
      verifyTip
    } = this.state;
    const currency = getQueryValue("currency");
    return (
      <section className={styles.withdraw}>
        <Spin spinning={loading}>
          <Breadcrumb>
            <Breadcrumb.Item>{location}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link href={WALLET_PATHS.WALLET}>{wallet}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {i18nUtil.formatMessage(
                { id: "wallet.withdraw.title" },
                {
                  currentCoin: convertUSD2USDC(currency)
                }
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles.withdrawContent}>
            <p className={styles.title}>
              {i18nUtil.formatMessage(
                { id: "wallet.withdraw.title" },
                {
                  currentCoin: convertUSD2USDC(currency)
                }
              )}
            </p>
            <p className={styles.noticeTips}>
              <InfoCircleFilled
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  border: "1px solid #225085",
                  color: "#225085",
                  marginRight: 10
                }}
              />
              {noticeTips}
            </p>
            <div className={styles.limitArea}>
              <span>{limit}</span>
              <span>
                {numberToThousands(decimalPointNoMoreX(limitInfo?.oneWeekAmount, 2))} {currency}/
                {weekText};{numberToThousands(decimalPointNoMoreX(limitInfo?.oneMonthAmount, 2))}{" "}
                {currency}/{monthText}
              </span>
            </div>
            <div className={styles.addressArea}>
              <div className={styles.topArea}>
                <span>{withdrawFiatAddress}</span>
              </div>
              <div className={styles.addressUsdc}>
                {addressInfo} （{bankName}）
              </div>
            </div>
            <div className={styles.amountArea} style={{ marginBottom: 20 }}>
              <div className={styles.topArea}>
                <span>{amount}</span>
                <span onClick={this.handleMaxAmount}>{max}</span>
              </div>
              <div className={styles.bottomArea}>
                <div className={styles.amountTop}>
                  <WithTrimInput
                    bordered={false}
                    placeholder={numberToThousands(balance) + " " + availableText}
                    // type="number"
                    className={styles.amountInput}
                    value={withdrawAmount}
                    onChange={this.handleChangeAmount}
                    suffix={convertUSD2USDC(currency)}
                  />
                  {withdrawAmount != "" && this.checkAmount(withdrawAmount) != "success" && (
                    <div className={styles.limitTips}>
                      <p>{this.checkAmount(withdrawAmount)}</p>
                    </div>
                  )}
                </div>
                {withdrawAmount != "" && this.checkAmount(withdrawAmount) == "success" && (
                  <p className={styles.feeTips}>
                    {withdrawFee} {this.calculateFee()} {convertUSD2USDC(currency)}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.noticeArea} style={{ marginTop: 80 }}>
              <div className={styles.title}>{noticeTitle}</div>
              <ul className={styles.listArea}>
                {noticeList.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </div>
            <Button
              disabled={
                withdrawAmount && this.checkAmount(withdrawAmount) === "success" ? false : true
              }
              className={styles.confirmBtn}
              onClick={this.handleSubmit}
              type="primary">
              {confirm}
            </Button>
          </div>
          <ModalBase
            title={applicationModalTitle}
            centered
            closable={false}
            maskClosable={false}
            visible={showApplicationModal}
            okText={applicationModalOkText}
            okButtonProps={{ style: { width: 150 } }}
            cancelText={OKText}
            onOk={() => {
              this.props.router.replace(HISTORY_PATHS.HISTORY_APPLICATION_DETAIL);
            }}
            onCancel={() => {
              this.props.router.replace(WALLET_PATHS.WALLET);
            }}>
            <div
              className={styles.applicationModal}
              dangerouslySetInnerHTML={{
                __html: i18nUtil.formatMessage(
                  { id: "wallet.withdraw.application.content" },
                  {
                    email:
                      typeof window != "undefined" && localStorage.getItem(OPERATE_CUSTOMER_EMAIL)
                  }
                )
              }}
            />
          </ModalBase>
          {showBusinessVerification ? (
            <ModalBusinessVerification
              confirmLoading={loading}
              visible={showBusinessVerification}
              businessType={IBindingBusinessType.applyWithdraw}
              confirm={(res) => {
                this.submitInfo(res);
              }}
              cancel={() => {
                this.setState({
                  showBusinessVerification: false
                });
              }}
            />
          ) : null}
          {showVerifyTipModal ? (
            <ModalBase
              buttonType={"only"}
              open={showVerifyTipModal}
              title={intl["modal.confirm.title"]}
              onOk={this.handleVerifyModal}
              okText={intl["button.confirm"]}
              onCancel={this.handleVerifyModal}>
              <p
                style={{
                  marginBottom: "27px",
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#fff"
                }}>
                {intl[verifyTip]}
              </p>
            </ModalBase>
          ) : null}
        </Spin>

        <History showViewAll={true} type={2} currency={currency} fiat={true} />
      </section>
    );
  }
}

export const PageWithdrawUsdc = WithRiskReminder(withRouter(WithdrawUSDC));
