"use client";
import React from "react";
import { EmailCodeBaseProps } from "@aspen/ui";
import ManagerLogin from "../login/managerLogin";
import PartnerLogin from "../login/partnerLogin";
import PortalLogin from "../login/portalLogin";
import { ISystemType } from "@aspen/model";

interface IProps extends EmailCodeBaseProps {
  system: ISystemType;
}

export const PageLoginView = (props: IProps) => {
  if(!props?.system){
     throw new Error("Component PageLoginView needs system props")
  }
  let LoginView;
  if (props?.system === "manager") {
    LoginView = ManagerLogin;
  }
  if (props?.system === "partner") {
    LoginView = PartnerLogin;
  }
  if (props?.system === "portal") {
    LoginView = PortalLogin;
  }
  return <LoginView {...props} />;
};
