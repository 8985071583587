import React from "react";
import { withRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import { connect } from "react-redux";
import { cloneDeep } from "lodash-es";
import clsx from "clsx";
import { Breadcrumb, Button, Divider, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import styles from "@aspen/theme/Savings.module.less";
import {
  AUTHORIZATION_TYPE,
  certifiedKycLevel,
  convertUSD2USDC,
  createMultiRequest,
  decimalPointNoMoreX,
  decimalUpInteger,
  GA_EVENT_NAME,
  GA_EVENT_TAG,
  getQueryValue,
  handleMemberEquitiesData,
  i18nUtil,
  isComplementation,
  numberToThousands,
  reportEvent,
  resetRateNew,
  USER_AUTH,
  USER_ROLE,
  YIELD_PATHS,
  YIELD_TYPES
} from "@aspen/libs";
import {
  addAutomaticInvestmentPlan,
  autoInvestDetail,
  autoInvestList,
  autoInvestMinAmount,
  availableSpots,
  getMyStrategyDetail,
  memberEquities,
  yieldList
} from "@aspen/services";
import {
  AIPProductType,
  AIPStrategyType,
  AUTOMATICE_INVESTMENT_CYCLE,
  BalanceCategory,
  IAutoInvestCoinItem,
  IAutoInvestConfirm,
  IAutoInvestMinAmount,
  ICurrency,
  IFlexibleProducts,
  IInjectProps,
  IKycLevel,
  IMemberEquities,
  InvestmentProduct,
  IReqAutoInvestSubscribe
} from "@aspen/model";

import {
  CoinImage,
  Disclaimer,
  KycGuideTips,
  message,
  WithCoinList,
  WithKYCInfo,
  WithTrimInput
} from "@aspen/ui";
import {
  ModalDcaCoinList,
  ModalDcaConfirm,
  ModalDcaStrategy,
  ModalSafetyVerification
} from "../../index";

import selectTopRight from "@aspen/assets/images/selectTopRight.png";

const { Option } = Select;

interface IProps extends IInjectProps {
  coinList: ICurrency[];
  symbolNames: string[];
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
  showToProfileBtn?: boolean;
}

interface IState {
  product: InvestmentProduct;
  name: string;
  proportion: Array<IAutoInvestCoinItem>;
  proportionError: string;
  strategyType: number;
  periodIndex: number;
  payCurrency: string;
  financingAmount: string;
  financingError: string;
  perCycleAmount: string;
  perCycleError: string;
  flexibleBalance: string;
  spotBalance: string;
  estimatedInterest: string;
  modalCoinVisible: boolean;
  modalStrategyVisible: boolean;
  modalStrategyType: number;
  modalConfirmVisible: boolean;
  modalConfirmContract: IAutoInvestConfirm | null;
  showSafetyVerificationModal: boolean;
  autoFinancing: boolean;
  autoStaking: boolean;
  loading: boolean;
}

class CreateDcaPlan extends React.Component<IProps, IState> {
  spotBalances: { [key: string]: number } | undefined;
  flexibleBalances: { [key: string]: number } | undefined;
  flexibleItems: Array<IFlexibleProducts> | undefined;
  strategyMinAmount: Array<IAutoInvestMinAmount> | undefined;
  memberEquities: Array<IMemberEquities> | undefined;
  currencyAPY: number = 0;

  constructor(props: IProps) {
    super(props);
    const intl = i18nUtil.t();
    const strategyType =
      props.router?.query?.strategyType?.toString() ?? getQueryValue("strategyType");
    this.state = {
      // @ts-ignore
      product: null,
      name: intl["savings.automatic.plan.name.default"],
      proportion: [],
      proportionError: "",
      strategyType: Number(strategyType || 1),
      periodIndex: 0,
      payCurrency: "",
      financingAmount: "",
      financingError: "",
      perCycleAmount: "",
      perCycleError: "",
      flexibleBalance: "",
      spotBalance: "",
      estimatedInterest: "",
      modalCoinVisible: false,
      modalStrategyVisible: false,
      modalStrategyType: Number(strategyType || 1),
      modalConfirmVisible: false,
      modalConfirmContract: null,
      showSafetyVerificationModal: false,
      autoFinancing: false,
      autoStaking: false,
      loading: false
    };
  }

  componentDidMount() {
    this._fetchProduct();
  }

  _fetchProduct = () => {
    const productId = this.props.router?.query?.id?.toString() ?? getQueryValue("id");
    if (productId) {
      autoInvestDetail({ id: productId }).then((res) => {
        if (res?.code == "0") {
          const product: InvestmentProduct = res?.data;
          this.setState(
            {
              product: product,
              proportion: resetRateNew(product?.proportionList?.[0]?.currencyProportion ?? {}),
              payCurrency: product?.payOptionList?.[0]?.currency
            },
            () => this._fetchConfig()
          );
        }
      });
    } else {
      autoInvestList({ type: AIPProductType.Customize, offset: 0, limit: 100 }).then((res) => {
        if (res?.code == "0") {
          const product: InvestmentProduct = res?.data?.rows?.[0];
          this.setState(
            {
              product: product,
              payCurrency: product?.payOptionList?.[0]?.currency
            },
            () => this._fetchConfig()
          );
        }
      });
    }
  };

  _fetchConfig = () => {
    const { product } = this.state;
    let currencies: string[] = [];
    product?.payOptionList?.forEach((data) => {
      currencies.push(data.currency);
    });
    createMultiRequest(
      [
        availableSpots({ currency: currencies.toString(), category: BalanceCategory.Spot }), // 获取现货账户币种余额
        availableSpots({
          currency: currencies.toString(),
          category: BalanceCategory.Flexible
        }), // 获取活期账户币种余额
        yieldList({ currencies: currencies.toString() }), // 获取活期产品详细信息
        autoInvestMinAmount({ productId: product?.id ?? "" }), // 获取策略起购额列表
        memberEquities() //  获取VIP专属活期利率
      ],
      5,
      ({ success, errors }) => {
        const [res1, res2, res3, res4, res5] = success;
        this.spotBalances = res1?.data;
        this.flexibleBalances = res2?.data;
        this.flexibleItems = res3?.data;
        this.strategyMinAmount = res4?.data;
        this.memberEquities = handleMemberEquitiesData(res5?.data);
        if (errors.length === 0) {
          this._setBalance();
        }
      }
    );
  };

  _setBalance = () => {
    const { payCurrency, financingAmount, perCycleAmount } = this.state;
    // 更新APY
    this.flexibleItems?.forEach((data) => {
      if (data.currency === payCurrency) {
        this.currencyAPY = data.apy;
        this.memberEquities?.forEach((item) => {
          if (item.currency === payCurrency) this.currencyAPY += Number(item.rate) / 100;
        });
      }
    });
    // 更新余额
    if (this.spotBalances && this.flexibleBalances) {
      this.setState(
        {
          spotBalance: decimalPointNoMoreX(this.spotBalances[payCurrency]) as string,
          flexibleBalance: decimalPointNoMoreX(this.flexibleBalances[payCurrency]) as string
        },
        () => {
          this._financingAmountChange(financingAmount);
          this._perCycleAmountChange(perCycleAmount);
        }
      );
    }
  };

  _nameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState({ name: value });
  };

  _openModalCoin = () => {
    this.setState({ modalCoinVisible: true });
  };

  _coinPercentChange = (inputValue: string, index: number) => {
    // 不允许输入小数点
    const params = inputValue.replace(/\./g, "");
    // 去掉小数点之后， 如果不是数字，则直接return
    if (isNaN(Number(params))) {
      return;
    }
    // 输入超过3位，直接return
    if (params.length > 3) {
      return;
    }
    const { proportion } = this.state;
    proportion.forEach((_data, _index) => {
      if (_index === index && params !== "0") _data.percent = params;
    });
    this._setProportion(proportion);
  };

  _setProportion = (proportion: Array<IAutoInvestCoinItem>) => {
    const intl = i18nUtil.t();
    let percentTotal = 0;
    // 设置单个币种错误提示
    proportion.forEach((_data, _index) => {
      percentTotal += Number(_data.percent);
      if (Number(_data.percent) < 10) {
        _data.error = intl["savings.automatic.coin.single.percent"];
      } else {
        _data.error = "";
      }
    });
    // 数组状态使用深拷贝
    this.setState({ proportion: cloneDeep(proportion) });
    // 设置加权错误提示
    if (proportion.length > 0 && percentTotal != 100) {
      this.setState({
        proportionError: i18nUtil.formatMessage(
          { id: "savings.automatic.coin.total.percent" },
          { percent: percentTotal }
        )
      });
    } else {
      this.setState({ proportionError: "" });
    }
  };

  // 获取创建定投和活期申购额度限制
  _getFlexible = () => {
    const { strategyType, payCurrency } = this.state;
    let minAmount = 0; // 管理端设置的最小额度
    this.strategyMinAmount?.forEach((item) => {
      if (item.strategyType === strategyType && item.currency === payCurrency) {
        minAmount = item.minAmount;
      }
    });
    let minFlexible = 0; // 活期理财的最小额度
    let maxFlexible = 0; // 活期理财的最大额度
    let incrementFlexible = 0; //活期理财的递增额度
    this.flexibleItems?.forEach((item3) => {
      if (item3.currency === payCurrency) {
        minFlexible = item3.minAmount;
        maxFlexible = item3.maxAmount;
        incrementFlexible = item3.incrementAmount;
      }
    });
    return {
      minAmount,
      minFlexible,
      maxFlexible,
      incrementFlexible
    };
  };

  // 预估利息，预估每期定投额计算
  _calInterest = (value) => {
    const { strategyType, perCycleAmount, estimatedInterest, flexibleBalance } = this.state;
    let _estimatedInterest = estimatedInterest;
    let _perCycleAmount = perCycleAmount;
    if (strategyType !== AIPStrategyType.Normal) {
      let APY = this.currencyAPY;
      let APR = (Math.pow(1 + APY, 1 / 365) - 1) * 365;
      let amount = Number(value) + Number(flexibleBalance);
      _estimatedInterest = decimalPointNoMoreX((amount * APR) / 365) as string;
      if (strategyType === AIPStrategyType.Preservation) {
        // 保本策略计算预估利息，定投额等于预估利息保留一位小数
        _perCycleAmount =
          decimalPointNoMoreX(_estimatedInterest, 1) == "0.0"
            ? "0"
            : (decimalPointNoMoreX(_estimatedInterest, 1) as string);
      }
    }
    return {
      _estimatedInterest,
      _perCycleAmount
    };
  };

  _financingAmountChange = (inputValue: string) => {
    // 输入框限制最多允许输入八位小数
    let params = inputValue.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".");
    const value = params.replace(/^(-)*(\d+)\.(\d{1,8}).*$/, "$1$2.$3");
    const intl = i18nUtil.t();
    const { strategyType, payCurrency, perCycleAmount, flexibleBalance, spotBalance } = this.state;
    if (strategyType === AIPStrategyType.Normal) {
      return;
    }
    if (isNaN(Number(value))) {
      return;
    }
    const currency = convertUSD2USDC(payCurrency);
    // 只允许输入数字，且最大保留八位小数
    this.setState({ financingAmount: value, financingError: "" }, () => {
      // 理财定投改变申购额，重新计算每期定投额符合规则
      if (strategyType === AIPStrategyType.Financing) {
        this._perCycleAmountChange(perCycleAmount);
      }
    });
    // 预估利息计算
    const { _estimatedInterest, _perCycleAmount } = this._calInterest(value);
    // 获取创建定投和活期申购额度限制
    const { minAmount, minFlexible, maxFlexible, incrementFlexible } = this._getFlexible();
    // 错误判断（保本定投和理财定投可以额外申购）
    const amount = Number(value);
    const flexAmount = Number(flexibleBalance);
    const spotAmount = Number(spotBalance);
    let error = "";
    switch (strategyType) {
      case AIPStrategyType.Preservation:
        if (amount > 0) {
          if (amount < Math.max(minFlexible, minAmount - flexAmount)) {
            // 用户输入的数值不为0且小于【活期产品的最小申购额】和【管理端设置的最小本金额-活期余额】中的最大值
            error = i18nUtil.formatMessage(
              { id: "savings.automatic.minimum" },
              {
                amount: decimalUpInteger(Math.max(minFlexible, minAmount - flexAmount)),
                currency
              }
            );
            break;
          }
        } else if (flexAmount < minAmount) {
          // 用户未输入数值或输入数值为0且活期余额小于【管理端设置的最小本金额】
          error = i18nUtil.formatMessage(
            { id: "savings.automatic.minimum" },
            {
              amount: decimalUpInteger(minAmount - flexAmount),
              currency
            }
          );
          break;
        }
        break;
      case AIPStrategyType.Financing:
        // 用户输入的数值不为0且小于【活期产品的最小申购额】
        if (amount > 0 && amount < minFlexible) {
          error = i18nUtil.formatMessage(
            { id: "savings.automatic.minimum" },
            {
              amount: minFlexible,
              currency
            }
          );
        }
        break;
      default:
        break;
    }
    // 用户输入的数值不为零且同时满足管理端和活期最小申购额，判断其余活期申购规则
    if (amount > 0) {
      // 用户输入的数值大于【活期产品的最大申购额-活期余额】且【活期最大申购额 - 活期余额 】>0
      if (amount > maxFlexible - flexAmount && maxFlexible - flexAmount > 0) {
        error = i18nUtil.formatMessage(
          { id: "savings.automatic.maximum" },
          {
            amount: numberToThousands(decimalPointNoMoreX(maxFlexible - flexAmount, 0)),
            currency
          }
        );
      }
      // 用户输入的数值大于【活期产品的最大申购额-活期余额】且【活期最大申购额 - 活期余额 】<=0
      if (amount > maxFlexible - flexAmount && maxFlexible - flexAmount <= 0) {
        error = intl["savings.automatic.remaining"];
      }
      // 用户输入的数值超过【现货可用余额】
      if (amount > spotAmount) {
        error = intl["savings.automatic.insufficient"];
      }
      // 用户输入的数值不满足【活期产品的递增金额】
      if (!isComplementation(amount, incrementFlexible)) {
        error = i18nUtil.formatMessage(
          { id: "savings.automatic.multiple" },
          { amount: incrementFlexible }
        );
      }
    }
    // 更新预估利息、预估每期定投、额外申购额错误信息
    this.setState({
      financingError: error,
      estimatedInterest: _estimatedInterest,
      perCycleAmount: _perCycleAmount
    });
  };

  // 获取创建定投的额度限制
  _getAmountLimit = () => {
    const { product, strategyType, payCurrency } = this.state;
    let minAmount = 0; //管理端设置的最小额度
    let maxAmount = 0; //管理端设置的最大额度
    let incrementAmount = 0; //管理端设置的递增额度
    this.strategyMinAmount?.forEach((item) => {
      if (item.strategyType === strategyType && item.currency === payCurrency) {
        minAmount = item.minAmount;
      }
    });
    product?.payOptionList?.forEach((item2) => {
      if (item2.currency === payCurrency) {
        maxAmount = item2.maxAmount;
        incrementAmount = item2.incrementAmount;
      }
    });
    return {
      minAmount,
      maxAmount,
      incrementAmount
    };
  };

  _perCycleAmountChange = (inputValue: string) => {
    // 输入框限制只允许输入两位小数
    let params = inputValue.replace(/[^\d.]/g, "").replace(/\.{2,}/g, "."); //取数字
    const value = params.replace(/^(-)*(\d+)\.(\d{1,2}).*$/, "$1$2.$3"); //取两位
    const intl = i18nUtil.t();
    if (isNaN(Number(value))) {
      return;
    }
    const { financingAmount, strategyType, payCurrency, flexibleBalance, spotBalance } = this.state;
    if (strategyType === AIPStrategyType.Preservation) {
      return;
    }
    const currency = convertUSD2USDC(payCurrency);
    // 只允许输入数字，且最大保留八位小数
    this.setState({ perCycleAmount: value, perCycleError: "" });
    // 用户未输入时不做判断
    if (value === "") return;
    const { minAmount, maxAmount, incrementAmount } = this._getAmountLimit();
    // 错误判断（理财定投和普通定投需要限制每期定投额）
    const amount = Number(value);
    const flexAmount = Number(flexibleBalance);
    const spotAmount = Number(spotBalance);
    const financingNumber = Number(financingAmount);
    let error = "";
    // 用户输入的数值小于【管理端设置的最小申购额】
    if (amount < minAmount) {
      error = i18nUtil.formatMessage(
        { id: "savings.automatic.minimum" },
        {
          amount: minAmount,
          currency
        }
      );
    } else {
      switch (strategyType) {
        case AIPStrategyType.Financing:
          // 用户输入的数值大于【管理端设置的最大申购额】和【活期余额+活期申购额】中的最小值
          if (amount > Math.min(maxAmount, flexAmount + financingNumber)) {
            error = i18nUtil.formatMessage(
              { id: "savings.automatic.maximum" },
              {
                amount: numberToThousands(
                  decimalPointNoMoreX(Math.min(maxAmount, flexAmount + financingNumber))
                ),
                currency
              }
            );
          }
          break;
        case AIPStrategyType.Normal:
          // 用户输入的数值大于【管理端设置的最大申购额】
          if (amount > maxAmount) {
            error = i18nUtil.formatMessage(
              { id: "savings.automatic.maximum" },
              {
                amount: numberToThousands(maxAmount),
                currency
              }
            );
            break;
          }
          // 用户输入的数值超过【现货可用余额】
          if (amount > spotAmount) {
            error = intl["savings.automatic.insufficient"];
            break;
          }
          break;
        default:
          break;
      }
      // 用户输入的数值不满足【管理端设置的递增金额】
      if (!isComplementation(amount, incrementAmount)) {
        error = i18nUtil.formatMessage(
          { id: "savings.automatic.multiple" },
          { amount: incrementAmount }
        );
      }
    }
    this.setState({ perCycleError: error });
  };

  _canNextStep = () => {
    const { name, proportion, proportionError, financingError, perCycleAmount, perCycleError } =
      this.state;
    let proportionCoin = true;
    proportion.forEach((item) => {
      if (item.error) proportionCoin = false;
    });
    let canNextStep = false;
    if (
      name &&
      proportion.length > 0 &&
      proportionError === "" &&
      proportionCoin &&
      financingError === "" &&
      perCycleAmount &&
      perCycleError === ""
    ) {
      canNextStep = true;
    }
    return canNextStep;
  };

  _nextStep = async () => {
    const {
      product,
      payCurrency,
      name,
      proportion,
      strategyType,
      periodIndex,
      financingAmount,
      perCycleAmount,
      flexibleBalance,
      estimatedInterest
    } = this.state;
    if (this.props.showGuideModal(1)) {
      if (this._canNextStep()) {
        let financingProductId = "";
        this.flexibleItems?.forEach((item) => {
          if (item.currency === payCurrency) {
            financingProductId = item.id;
          }
        });
        const detailParams = {
          name: name,
          strategyType: strategyType,
          periodIndex: periodIndex,
          payCurrency: payCurrency,
          currencyAPY: this.currencyAPY,
          financingProductId: financingProductId,
          financingAmount: Number(financingAmount),
          perCycleAmount: Number(perCycleAmount),
          flexibleBalance: flexibleBalance,
          estimatedInterest: estimatedInterest
        };
        const contract: IAutoInvestConfirm = {
          ...detailParams,
          product: product,
          proportion: proportion
        };
        if (strategyType !== AIPStrategyType.Normal) {
          // 请求接口判断是否有进行中的同币种理财计划
          const res = await getMyStrategyDetail({ currency: payCurrency });
          if (res?.code == "0" && res?.data != null) {
            reportEvent({
              joinedTag: GA_EVENT_TAG.Modal,
              moduleName: GA_EVENT_NAME.dca.previewExistPlan,
              detailParams
            });
            this.setState({
              modalStrategyVisible: true,
              modalStrategyType: res?.data?.strategyType
            }); //已有同币种理财计划弹窗
            return;
          }
        }
        reportEvent({
          joinedTag: GA_EVENT_TAG.Modal,
          moduleName: GA_EVENT_NAME.dca.previewPlan,
          detailParams
        });
        this.setState({ modalConfirmVisible: true, modalConfirmContract: contract }); //确认弹窗
      }
    }
  };

  handleSubscribePlan = (autoFinancing: boolean, autoStaking: boolean) => {
    const authorizationType = localStorage.getItem(AUTHORIZATION_TYPE);
    if (
      (localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_PARTNER_CUSTOMER ||
        localStorage.getItem(USER_AUTH) === USER_ROLE.ROLE_SUB) &&
      authorizationType === "0"
    ) {
      this.setState({
        showSafetyVerificationModal: true,
        autoFinancing,
        autoStaking
      });
    } else {
      this._subscribePlan(autoFinancing, autoStaking);
    }
  };

  hideOK = (code: string) => {
    const { autoFinancing, autoStaking } = this.state;
    this._subscribePlan(autoFinancing, autoStaking, code);
  };

  _subscribePlan = (autoFinancing: boolean, autoStaking: boolean, emailCode?: string) => {
    const intl = i18nUtil.t();
    const contract = this.state.modalConfirmContract;
    let period =
      AUTOMATICE_INVESTMENT_CYCLE[contract?.product?.periodList?.[contract?.periodIndex] ?? ""]
        ?.code;
    if (contract?.strategyType === AIPStrategyType.Preservation) {
      period = AUTOMATICE_INVESTMENT_CYCLE.REAL_TIME.code;
    }
    let payOptionId = "";
    contract?.product?.payOptionList?.forEach((data) => {
      if (data.currency == contract?.payCurrency) payOptionId = data.id;
    });
    let proportion: { [key: string]: number } = {};
    contract?.proportion?.forEach((item) => {
      proportion[item.currency] = Number(item.percent);
    });
    this.setState({
      loading: true
    });
    const param: IReqAutoInvestSubscribe = {
      amount: contract?.perCycleAmount ?? "",
      autoFinancing: autoFinancing,
      autoStaking: autoStaking,
      financingAmount: contract?.financingAmount,
      financingProductId: contract?.financingProductId,
      name: contract?.name,
      payCurrency: contract?.payCurrency,
      payOptionId: payOptionId,
      period: period,
      productId: contract?.product?.id ?? "",
      proportion: proportion,
      strategyType: contract?.strategyType,
      verifyCode: emailCode || ""
    };
    addAutomaticInvestmentPlan(param)
      .then((res) => {
        if (res?.code == "0" && res?.data != null) {
          message.success(intl["savings.automatic.plan.create.success"], 2);
          const that = this;
          // 直接跳转可能导致最新申购的定投不显示，因此延迟1s跳转（1s时间是在beta环境测试得来的）
          setTimeout(() => {
            // 关闭确认弹窗
            that.setState({ modalConfirmVisible: false });
            // 跳转我的定投列表
            that.props.router.push({
              pathname: YIELD_PATHS.YIELD_MY_INVESTMENT_PLAN
            });
          }, 2000);
        } else {
          message.error(intl[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
    reportEvent({
      moduleName: GA_EVENT_NAME.dca.createPlan,
      detailParams: {
        ...param,
        proportion: JSON.stringify(proportion)
      }
    });
  };

  _renderName = () => {
    const intl = i18nUtil.t();
    const { name } = this.state;
    return (
      <>
        <p style={{ marginBottom: 8 }}>{intl["savings.automatic.plan.name"]}</p>
        <WithTrimInput
          value={name}
          maxLength={50}
          placeholder={intl["savings.automatic.plan.name.placeholder"]}
          onChange={this._nameChange}
        />
      </>
    );
  };

  _renderCoinAdd = () => {
    const intl = i18nUtil.t();
    const { proportion, proportionError } = this.state;
    return (
      <>
        <p style={{ marginTop: 24 }}>{intl["savings.automatic.portfolio.allocation"]}</p>
        {proportion.map((data, index) => (
          <div key={data.currency}>
            <div className={styles.coinItem}>
              <div className={styles.coinItemNoX}>
                <CoinImage size={28} coins={data.currency} />
                <p onClick={this._openModalCoin} className={styles.currency}>
                  {data.currency}
                </p>
                <p onClick={this._openModalCoin} className={styles.arrow}>
                  ▼
                </p>
                <p className={styles.line}>｜</p>
                <div className={styles.inputDiv}>
                  <WithTrimInput
                    className={styles.inputPercent}
                    value={data.percent}
                    onChange={(e) => this._coinPercentChange(e.target.value, index)}
                  />
                </div>
                <p className={styles.pPercent}>%</p>
              </div>
              <div
                className={styles.xDiv}
                onClick={() => {
                  proportion.splice(index, 1);
                  this._setProportion(proportion);
                }}>
                ✕
              </div>
            </div>
            {data.percent && data.error ? <p className={styles.tipError}>{data.error}</p> : null}
          </div>
        ))}
        {proportionError && <p className={styles.tipError}>{proportionError}</p>}
        <div className={styles.addButton} onClick={this._openModalCoin}>
          + {intl["button.add.coin"]}
        </div>
      </>
    );
  };

  _changeStrategy = (type) => {
    reportEvent({
      moduleName: GA_EVENT_NAME.dca.switchStrategy,
      detailParams: { strategyType: type }
    });
    this.setState(
      {
        strategyType: type,
        periodIndex: 0,
        financingAmount: "",
        perCycleAmount: "",
        estimatedInterest: "",
        financingError: ""
      },
      () => {
        this._financingAmountChange(this.state.financingAmount);
      }
    );
  };

  _renderPrincipalStrategy = () => {
    const intl = i18nUtil.t();
    const { strategyType } = this.state;
    return (
      <>
        <p style={{ marginTop: 26 }}>{intl["savings.automatic.principal.strategy"]}</p>
        <div>
          {strategyType >= 0 &&
            [AIPStrategyType.Preservation, AIPStrategyType.Financing, AIPStrategyType.Normal].map(
              (type, index) => (
                <div
                  className={styles.strategyItem}
                  key={type}
                  style={{ borderWidth: strategyType === type ? 1 : 0 }}
                  onClick={this._changeStrategy.bind(this, type)}>
                  <p className={styles.strategyTitle}>
                    {intl[`savings.automatic.principal.strategy.${type}`]}
                  </p>
                  <p className={styles.strategyDesc}>
                    {intl[`savings.automatic.principal.strategy.${type}.desc`]}
                  </p>
                  {strategyType === type && (
                    <div className={styles.strategyIcon}>
                      <Image alt="" width={40} height={40} src={selectTopRight} />
                    </div>
                  )}
                </div>
              )
            )}
        </div>
      </>
    );
  };

  _renderCoinSelect = () => {
    const intl = i18nUtil.t();
    const { product, payCurrency } = this.state;
    return (
      <>
        <p>{intl["savings.automatic.select.coin"]}</p>
        <Select
          className={styles.selectArea}
          placeholder={intl["placeholder.select"]}
          onChange={(value) => this.setState({ payCurrency: value }, this._setBalance)}
          value={payCurrency}
          bordered={false}
          size="large">
          {product?.payOptionList?.map((data, index) => (
            <Option key={index} value={data.currency}>
              <p>{convertUSD2USDC(data.currency)}</p>
            </Option>
          ))}
        </Select>
      </>
    );
  };

  _renderCycleSelect = () => {
    const intl = i18nUtil.t();
    const { product, periodIndex } = this.state;
    return (
      <>
        <p style={{ marginTop: 24 }}>{intl["savings.automatic.add.cycle"]}</p>
        <Select
          className={styles.selectArea}
          placeholder={intl["placeholder.select"]}
          onChange={(value) => this.setState({ periodIndex: value }, this._setBalance)}
          value={periodIndex}
          bordered={false}
          size="large">
          {product?.periodList?.map((data, index) => (
            <Option key={index} value={index}>
              <p>{intl["savings.automatic.investment.cycle." + data]}</p>
            </Option>
          ))}
        </Select>
      </>
    );
  };

  _renderFlexibleBalance = () => {
    const intl = i18nUtil.t();
    const { flexibleBalance, payCurrency } = this.state;
    return (
      <>
        <p className={styles.balanceTitle}>{intl["savings.automatic.flexible.balance"]}</p>
        <p className={styles.balance}>
          {numberToThousands(decimalPointNoMoreX(flexibleBalance))} {convertUSD2USDC(payCurrency)}
        </p>
      </>
    );
  };

  _renderSoptBalance = () => {
    const intl = i18nUtil.t();
    const { spotBalance, payCurrency } = this.state;
    return (
      <>
        <p className={styles.balanceTitle}>{intl["savings.automatic.spot.balance"]}</p>
        <p className={styles.balance}>
          {numberToThousands(decimalPointNoMoreX(spotBalance))} {convertUSD2USDC(payCurrency)}
        </p>
      </>
    );
  };

  _renderProtectInfo = () => {
    const intl = i18nUtil.t();
    const {
      payCurrency,
      financingAmount,
      financingError,
      perCycleAmount,
      estimatedInterest,
      spotBalance
    } = this.state;
    const { minimum } = this.getMinimum();
    return (
      <div>
        {this._renderFlexibleBalance()}
        <p className={styles.margin40Title}>{intl["savings.automatic.subscription.amount"]}</p>
        <WithTrimInput
          value={financingAmount}
          maxLength={50}
          placeholder={intl["placeholder.enter"]}
          onChange={(e) => this._financingAmountChange(e.target.value)}
          suffix={convertUSD2USDC(payCurrency)}
          addonAfter={
            <div
              style={{ display: "flex" }}
              onClick={() => this._financingAmountChange(decimalPointNoMoreX(spotBalance, 0))}>
              <div className={styles.inputMaxLeft} />
              MAX
            </div>
          }
        />
        <p className={styles.tipError}>{financingError}</p>
        <p className={styles.spotBalance}>
          {intl["savings.automatic.spot.balance"]}:{" "}
          {numberToThousands(decimalPointNoMoreX(spotBalance))} {convertUSD2USDC(payCurrency)}
        </p>
        <Divider />
        <div className={styles.flex}>
          <p className={styles.flexLeft}>{intl["savings.automatic.flexible.minimum"]}</p>
          <p className={styles.flexRight}>
            {numberToThousands(minimum)} {convertUSD2USDC(payCurrency)}
          </p>
        </div>
        <div className={styles.flex}>
          <div className={styles.flexLeft}>
            <p>{intl["savings.automatic.estimated.daily.interest"]}</p>
            <Tooltip
              title={i18nUtil.formatMessage(
                { id: "savings.automatic.estimated.daily.interest.desc" },
                { apy: this.currencyAPY * 100 }
              )}>
              <InfoCircleOutlined style={{ paddingLeft: 8, fontSize: 14 }} />
            </Tooltip>
          </div>
          <p className={styles.flexRight}>
            {numberToThousands(estimatedInterest)} {convertUSD2USDC(payCurrency)}
          </p>
        </div>
        <div className={styles.flex}>
          <div className={styles.flexLeft}>
            <p>{intl["savings.automatic.estimated.investment.amount"]}</p>
            <Tooltip title={intl["savings.automatic.estimated.investment.amount.desc"]}>
              <InfoCircleOutlined style={{ paddingLeft: 8, fontSize: 14 }} />
            </Tooltip>
          </div>
          <p className={styles.flexRight}>
            {numberToThousands(perCycleAmount)} {convertUSD2USDC(payCurrency)}
          </p>
        </div>
        <div className={styles.flex}>
          <p className={styles.flexLeft}>{intl["savings.automatic.add.cycle"]}</p>
          <p className={styles.flexRight}>{intl["savings.automatic.investment.cycle.REAL_TIME"]}</p>
        </div>
        <Divider />
      </div>
    );
  };

  getMinimum = () => {
    const { strategyType, payCurrency, flexibleBalance } = this.state;
    let minAmount = 0; // 管理端设置的最小额度
    const flexAmount = Number(flexibleBalance);
    this.strategyMinAmount?.forEach((item) => {
      if (item.strategyType === strategyType && item.currency === payCurrency) {
        minAmount = item.minAmount;
      }
    });
    const minimum = minAmount - flexAmount > 0 ? decimalUpInteger(minAmount - flexAmount) : 0;
    return { minimum, minAmount };
  };

  _renderDoubleInfo = () => {
    const intl = i18nUtil.t();
    const {
      payCurrency,
      financingAmount,
      financingError,
      perCycleAmount,
      perCycleError,
      spotBalance
    } = this.state;
    const { minAmount } = this.getMinimum();
    return (
      <div>
        {this._renderFlexibleBalance()}
        <p className={styles.margin40Title}>{intl["savings.automatic.subscription.amount"]}</p>
        <WithTrimInput
          value={financingAmount}
          maxLength={50}
          placeholder={intl["placeholder.enter"]}
          onChange={(e) => this._financingAmountChange(e.target.value)}
          suffix={convertUSD2USDC(payCurrency)}
          addonAfter={
            <div
              style={{ display: "flex" }}
              onClick={() => this._financingAmountChange(decimalPointNoMoreX(spotBalance, 0))}>
              <div className={styles.inputMaxLeft} />
              MAX
            </div>
          }
        />
        <p className={styles.tipError}>{financingError}</p>
        <p className={styles.spotBalance}>
          {intl["savings.automatic.spot.balance"]}: {numberToThousands(spotBalance)}{" "}
          {convertUSD2USDC(payCurrency)}
        </p>
        <p className={clsx(styles.margin24Title, styles.flexRowSpace)}>
          {intl["savings.automatic.amount.per.cycle"]}
          <span className={styles.descText}>
            {intl["savings.automatic.amount.min"]}: {numberToThousands(minAmount)}{" "}
            {convertUSD2USDC(payCurrency)}
          </span>
        </p>
        <WithTrimInput
          value={perCycleAmount}
          maxLength={50}
          placeholder={intl["placeholder.enter"]}
          onChange={(e) => this._perCycleAmountChange(e.target.value)}
          suffix={convertUSD2USDC(payCurrency)}
        />
        <p className={styles.tipError}>{perCycleError}</p>
        {this._renderCycleSelect()}
      </div>
    );
  };

  _renderNormalInfo = () => {
    const intl = i18nUtil.t();
    const { payCurrency, perCycleAmount, perCycleError } = this.state;
    return (
      <div>
        {this._renderSoptBalance()}
        <p className={styles.margin40Title}>{intl["savings.automatic.amount.per.cycle"]}</p>
        <WithTrimInput
          value={perCycleAmount}
          maxLength={50}
          placeholder={intl["placeholder.enter"]}
          onChange={(e) => this._perCycleAmountChange(e.target.value)}
          suffix={convertUSD2USDC(payCurrency)}
        />
        <p className={styles.tipError}>{perCycleError}</p>
        {this._renderCycleSelect()}
      </div>
    );
  };

  _renderBottomView = () => {
    const intl = i18nUtil.t();
    return (
      <div style={{ marginTop: 40 }}>
        <Button
          style={{ width: 128, height: 40, marginRight: 16 }}
          onClick={() => {
            this.props.router.push({
              pathname: YIELD_PATHS.YIELD,
              query: { tabType: YIELD_TYPES.DCA }
            });
          }}>
          {intl["button.back"]}
        </Button>
        <Button
          disabled={!this._canNextStep()}
          style={{ width: 128, height: 40 }}
          onClick={this._nextStep}
          type="primary">
          {intl["button.next"]}
        </Button>
      </div>
    );
  };

  _renderModalView = () => {
    const {
      proportion,
      payCurrency,
      modalCoinVisible,
      modalStrategyVisible,
      modalStrategyType,
      modalConfirmVisible,
      modalConfirmContract,
      showSafetyVerificationModal,
      loading
    } = this.state;
    return (
      <>
        <ModalDcaCoinList
          {...this.props}
          isVisible={modalCoinVisible}
          proportion={proportion}
          onHide={() => {
            this.setState({ modalCoinVisible: false });
          }}
          onConfirm={(newProportion: IAutoInvestCoinItem[]) => {
            this.setState({ modalCoinVisible: false });
            this._setProportion(newProportion);
          }}
        />
        {modalStrategyVisible && (
          <ModalDcaStrategy
            isVisible={modalStrategyVisible}
            strategyType={modalStrategyType}
            payCurrency={payCurrency}
            onHide={() => {
              this.setState({ modalStrategyVisible: false });
            }}
            onConfirm={() => {
              this.setState({ modalStrategyVisible: false });
              this.props.router.push({
                pathname: YIELD_PATHS.YIELD_MY_INVESTMENT_PLAN
              });
            }}
          />
        )}
        {modalConfirmVisible && (
          <ModalDcaConfirm
            isVisible={modalConfirmVisible}
            loading={loading}
            modalConfirmContract={modalConfirmContract || undefined}
            onHide={() => {
              this.setState({ modalConfirmVisible: false });
            }}
            onConfirm={(autoFinancing: boolean, autoStaking: boolean) => {
              this.handleSubscribePlan(autoFinancing, autoStaking);
            }}
          />
        )}
        {showSafetyVerificationModal ? (
          <ModalSafetyVerification
            visible={showSafetyVerificationModal}
            handleCancel={() => {
              this.setState({
                showSafetyVerificationModal: false
              });
            }}
            onOk={this.hideOK}
            businessType={-1}
            isCustomerValidate={true}
          />
        ) : null}
      </>
    );
  };

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const location_text: string = intl["kyc.certify.location"];
    const breadcrumb_first_text: string = intl["savings.automatic.add.breadcrumb.first"];
    const breadcrumb_second_text: string = intl["savings.automatic.add.breadcrumb.second"];
    const title_text: string = intl["savings.automatic.add.title"];
    const { strategyType } = this.state;

    return (
      <>
        <section className={styles.dcaCreate}>
          <div className="flex">
            <Breadcrumb style={{ maxWidth: "50%" }}>
              <Breadcrumb.Item>{location_text}: </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link href={{ pathname: YIELD_PATHS.YIELD, query: { tabType: YIELD_TYPES.DCA } }}>
                  {breadcrumb_first_text}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{breadcrumb_second_text}</Breadcrumb.Item>
            </Breadcrumb>
            {!certifiedKycLevel(1) ? (
              <KycGuideTips showToProfileBtn={this.props?.showToProfileBtn} />
            ) : null}
          </div>
          <div className={styles.container}>
            <p className={styles.title}>{title_text}</p>
            <div className={styles.flex}>
              <div className={styles.left}>
                {this._renderName()}
                {this._renderCoinAdd()}
                {this._renderPrincipalStrategy()}
              </div>
              <div className={styles.right}>
                {this._renderCoinSelect()}
                {strategyType === AIPStrategyType.Preservation && this._renderProtectInfo()}
                {strategyType === AIPStrategyType.Financing && this._renderDoubleInfo()}
                {strategyType === AIPStrategyType.Normal && this._renderNormalInfo()}
                {this._renderBottomView()}
              </div>
            </div>
          </div>
        </section>
        <Disclaimer />
        {this._renderModalView()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { symbolNames } = state.marketSpotAndTradeInfo;
  return {
    symbolNames
  };
};

export const PageCreateDca = WithKYCInfo(
  WithCoinList(withRouter(connect(mapStateToProps)(CreateDcaPlan)))
);
