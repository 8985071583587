import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Checkbox, Input, message, Select, Spin } from "antd";
import clsx from "clsx";
import { useRouter } from "next/router";
import Link from "next/link";
import styles from "@aspen/theme/Wallet.module.less";
import { GA_EVENT_NAME, WALLET_PATHS, convertUSD2USDC, i18nUtil, reportEvent } from "@aspen/libs";
import {
  addWithdrawAddress,
  checkWithdrawAddressFormat,
  getAssetCurrencyConfig
} from "@aspen/services";
import {
  IBusinessVerificationHeaderModel,
  ICurrencyAsset,
  ICurrencyConfig,
  IReqSecurityVerifyHeader,
  WITHDRAW_WHITELIST_TYPE,
  IBindingBusinessType
} from "@aspen/model";

import { ModalAddAddressOrigin, ModalResult, ModalBusinessVerification } from "../../../index";
import { WithRiskReminder } from "../../../withRiskReminder/WithRiskReminder";

const { Option } = Select;

interface IProps {}

const AddAddress: React.FC<IProps> = (props) => {
  const intl = i18nUtil.t();
  const router = useRouter();

  const [loading, setLoading] = useState<boolean>(true);
  const [coinList, setCoinList] = useState<ICurrencyConfig[]>([]);
  const [chainList, setChainList] = useState<ICurrencyAsset[]>([]);
  const [currentCoinInfo, setCurrentCoinInfo] = useState<ICurrencyConfig>();
  const [currentChainInfo, setCurrentChainInfo] = useState<ICurrencyAsset>();
  const [addressInfo, setAddressInfo] = useState<string>("");
  const [xrpTag, setXrpTag] = useState<string>("");
  const [addressFormat, setAddressFormat] = useState<boolean>(true);
  const [remark, setRemark] = useState<string>("");
  const [origin, setOrigin] = useState<string>("");
  const [originModalVisible, setOriginModalVisible] = useState<boolean>(false);
  const [addWhite, setAddWhite] = useState<boolean>(false);
  const [addSuccessModalVisible, setAddSuccessModalVisible] = useState<boolean>(false);
  const [showBusinessVerification, setShowBusinessVerification] = useState<boolean>(false);

  const successMapData = [
    {
      label: intl["wallet.withdraw.white.history.denomination"],
      value: convertUSD2USDC(currentCoinInfo?.currency ?? "")
    },
    {
      label: intl["wallet.withdraw.white.history.network"],

      value: currentChainInfo?.chainName
    },
    {
      label: intl["wallet.withdraw.white.history.address"],
      value: addressInfo
    },
    {
      label: intl["wallet.withdraw.white.add.tag"],
      value: xrpTag
    },
    {
      label: intl["wallet.withdraw.white.history.origin"],
      value: origin
    },
    {
      label: intl["wallet.withdraw.white.history.label"],
      value: remark || intl["wallet.withdraw.select.address.item.title"]
    }
  ];
  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    getAssetCurrencyConfig({})
      .then((res) => {
        if (res?.code == "0") {
          const list = res?.data ?? [];
          setCoinList(list);
          if (list.length) {
            setCurrentCoinInfo(list[0]);
            setChainList(list[0]?.assets);
            setCurrentChainInfo(list[0]?.assets[0]);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChangeCoin = (currency: string) => {
    const selectInfo = coinList.filter((e) => e.currency === currency)[0];
    setCurrentCoinInfo(selectInfo);
    setChainList(selectInfo?.assets);
    setCurrentChainInfo(selectInfo?.assets[0]);
  };
  const handleChangeChain = (chainName: string) => {
    const selectInfo = chainList.filter((e) => e.chainName === chainName)[0];
    setCurrentChainInfo(selectInfo);
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressInfo(e.target.value);
  };
  const handleChangeXrpTag = (e: React.ChangeEvent<HTMLInputElement>) => {
    setXrpTag(e.target.value);
  };

  const checkAddress = () => {
    if (addressInfo === "") {
      setAddressFormat(true);
      return;
    }
    if (
      currentChainInfo &&
      currentChainInfo.asset === "XRP" &&
      (addressInfo === "" || xrpTag === "")
    )
      return true;
    const params = {
      asset: currentChainInfo?.asset ?? "",
      address: addressInfo,
      memo: xrpTag
    };
    checkWithdrawAddressFormat(params).then((res) => {
      if (res?.code == "0") {
        if (res.data === true) {
          setAddressFormat(true);
        } else {
          setAddressFormat(false);
        }
      }
    });
  };

  const handleChangeRemark = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRemark(e.target.value);
  };

  const fetchAddAddress = (verifyParam: IBusinessVerificationHeaderModel) => {
    setLoading(true);
    const params = {
      asset: currentChainInfo?.asset ?? "",
      address: addressInfo,
      memo: xrpTag,
      source: origin,
      remark: remark,
      addressType: addWhite
        ? WITHDRAW_WHITELIST_TYPE.whitelist
        : WITHDRAW_WHITELIST_TYPE.nonWhitelist
    };
    let headerParams: IReqSecurityVerifyHeader = {
      businessType: IBindingBusinessType.withdrawAddAddress,
      smsType: 0
    };
    headerParams = Object.assign({}, headerParams, verifyParam);
    reportEvent({
      moduleName: GA_EVENT_NAME.whithdraw.addAddress,
      detailParams: params
    });
    addWithdrawAddress(params, headerParams)
      .then((res) => {
        if (res?.code == "0") {
          setShowBusinessVerification(false);
          setAddSuccessModalVisible(true);
        } else {
          message.error(res?.msg ? intl[res.msg] : res?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Breadcrumb style={{ marginBottom: "24px" }}>
        <Breadcrumb.Item>{intl["kyc.certify.location"]}:</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link href={WALLET_PATHS.WALLET}>{intl["wallet"]}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{intl["wallet.withdraw.select.address.btn.add"]}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.addNewAddressContent}>
        <p className={styles.title}>{intl["wallet.withdraw.white.add.title"]}</p>
        <div className={styles.selectView}>
          <div className={styles.selectCoinView}>
            <p className={styles.label}>{intl["wallet.withdraw.white.history.denomination"]}</p>
            <Select
              value={currentCoinInfo?.currency}
              style={{ width: 160 }}
              onChange={(e) => handleChangeCoin(e)}>
              {coinList.map((item, index) => {
                return (
                  <Option key={index} value={item.currency}>
                    {convertUSD2USDC(item.currency)}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div>
            <p className={styles.label}>{intl["wallet.withdraw.white.history.network"]}</p>
            <Select
              value={currentChainInfo?.chainName}
              style={{ width: 160 }}
              onChange={(e) => handleChangeChain(e)}>
              {chainList.map((item, index) => {
                return (
                  <Option key={index} value={item.chainName}>
                    {item?.chainName}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className={styles.itemView}>
          <p className={styles.label}>{intl["wallet.withdraw.white.history.address"]}</p>
          <Input
            bordered={false}
            onChange={handleChangeAddress}
            placeholder={intl["wallet.withdraw.white.add.address.placeholder"]}
            onBlur={checkAddress}
            value={addressInfo}
          />
          {!addressFormat && (
            <div className={styles.errorTips}>
              <p>{intl["wallet.withdraw.white.address.error"]}</p>
            </div>
          )}
        </div>
        {currentChainInfo?.asset === "XRP" && (
          <div className={styles.itemView}>
            <p className={styles.label}>{intl["wallet.withdraw.white.add.tag"]}</p>
            <Input
              bordered={false}
              onChange={handleChangeXrpTag}
              placeholder={intl["placeholder.enter"]}
              onBlur={checkAddress}
              value={xrpTag}
            />
          </div>
        )}
        <div className={styles.itemView}>
          <p className={styles.label}>{intl["wallet.withdraw.white.add.address.origin"]}</p>
          <div
            className={styles.originView}
            onClick={() => {
              setOriginModalVisible(true);
            }}>
            <span className={clsx(styles.originText, !origin && styles.placeholder)}>
              {origin ? origin : intl["wallet.withdraw.white.add.select"]}
            </span>
            <span className={styles.selectText}>Select</span>
          </div>
        </div>
        <div className={styles.itemView}>
          <p className={styles.label}>{intl["wallet.withdraw.white.add.address.label"]}</p>
          <Input
            bordered={false}
            onChange={handleChangeRemark}
            placeholder={intl["wallet.withdraw.white.add.label.placeholder"]}
            value={remark}
          />
        </div>
        <div className={styles.addWhite}>
          <Checkbox
            checked={addWhite}
            onChange={() => {
              setAddWhite(!addWhite);
            }}
          />
          <div className={styles.addWhiteText}>
            <p className={styles.addWhiteLable}>{intl["wallet.withdraw.white.add.white.title"]}</p>
            <p className={styles.addWhiteTips}>{intl["wallet.withdraw.white.add.white.content"]}</p>
          </div>
        </div>
        <div className={styles.noticeView}>
          <p className={styles.noticeTitle}> {intl["wallet.withdraw.notice"]}</p>
          {intl["wallet.withdraw.white.add.notice"].map((item: string, index: number) => {
            return (
              <p key={index} className={styles.noticeContent}>
                {item}
              </p>
            );
          })}
        </div>
        <Button
          type="primary"
          style={{ width: 170 }}
          disabled={!(addressInfo && origin && addressFormat)}
          onClick={() => {
            setShowBusinessVerification(true);
          }}>
          {intl["wallet.withdraw.white.add.save"]}
        </Button>
      </div>
      {originModalVisible ? (
        <ModalAddAddressOrigin
          visible={originModalVisible}
          handleOk={(e: string) => {
            setOrigin(e);
            setOriginModalVisible(false);
          }}
          handleCancel={() => {
            setOriginModalVisible(false);
          }}
        />
      ) : null}
      {showBusinessVerification ? (
        <ModalBusinessVerification
          confirmLoading={loading}
          visible={showBusinessVerification}
          businessType={IBindingBusinessType.withdrawAddAddress}
          confirm={(res) => {
            fetchAddAddress(res);
          }}
          cancel={() => {
            setShowBusinessVerification(false);
          }}
        />
      ) : null}
      {addSuccessModalVisible ? (
        <ModalResult
          open={addSuccessModalVisible}
          resultType={"success"}
          onOk={() => {
            setAddSuccessModalVisible(false);
            setLoading(true);
            router.push(WALLET_PATHS.WALLET_WITHDRAW_WHITELIST_MANAGEMENT);
          }}
          onCancel={() => {
            router.back();
          }}
          okText={intl["wallet.withdraw.white.add.success.btn.manage"]}
          title={intl["wallet.withdraw.white.add.success.title"]}
          text={addWhite ? intl["wallet.withdraw.white.add.success.content"] : ""}
          list={successMapData}
          maskClosable={false}
        />
      ) : null}
    </Spin>
  );
};

export const PageWhitelistAddAddress = WithRiskReminder(AddAddress);
