import dynamic from "next/dynamic";

export const StructuredHeader = dynamic(() => import("./StructuredHeader"), {
  ssr: false
});

export const ModalOrderDetail = dynamic(
  () => import("../trade/modalOrderDetail/ModalOrderDetail"),
  {
    ssr: false
  }
);

export const FixedYieldHistory = dynamic(() => import("./FixedYieldHistory"), {
  ssr: false
});

export const StakingYieldHistory = dynamic(() => import("./StakingYieldHistory"), {
  ssr: false
});

export const ModalStrategyDetail = dynamic(() => import("./ModalStrategyDetail"), {
  ssr: false
});

export const ModalCancelStrategy = dynamic(() => import("./ModalCancelStrategy"), {
  ssr: false
});
