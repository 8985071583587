import React, { useEffect } from "react";
import styles from "@aspen/theme/Download.module.less";

import { GA_EVENT_NAME, downloadGAReport } from "@aspen/libs";
import { DownloadHeader, About, Affix } from "../../index";

export const PageDownload: React.FC = () => {
  useEffect(() => {
    downloadGAReport(GA_EVENT_NAME.download.openDownloadPage);
  }, []);
  return (
    <div className={styles.container}>
      <DownloadHeader />
      <Affix />
      <About />
    </div>
  );
};
