import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Spin, Breadcrumb, Button } from "antd";
import styles from "@aspen/theme/Structured.module.less";
import dayjs from "dayjs";
import {
  ICurrency,
  IStructuredOrderDetailModel,
  IStructuredProductModel,
  EDualOrderStatus,
  STRUCTURED_DUAL_TYPE
} from "@aspen/model";
import {
  getStructuredDetail,
  getStructuredUnderlying,
  getStructuredOrderId,
  availableSpots,
  structuredPlaceOrder,
  getStructuredOrderDetail
} from "@aspen/services";
import {
  decimalPointNoMoreX,
  getQueryValue,
  isComplementation,
  numberToThousands,
  processAPR,
  convertUSD2USDC,
  convertUSDC2USD,
  fixNumString,
  showStrike,
  reportEvent,
  i18nUtil,
  certifiedKycLevel,
  BENCHMARK_CURRENCY,
  GA_EVENT_NAME,
  HISTORY_PATHS,
  PRODUCT_TYPES,
  REFERENCE_CURRENCY,
  STRUCTURED_PATH
} from "@aspen/libs";
import {
  message,
  NumericInput,
  CoinImage,
  Disclaimer,
  WithCoinList,
  KycGuideTips
} from "@aspen/ui";
import { ModalResult } from "../../index";

interface List {
  label: string;
  value: string | number;
}
interface IProps {
  coinList: ICurrency[];
  showToProfileBtn?: boolean;
}
// 订单处理状态
const FAILED_STATUS = [EDualOrderStatus.fail, EDualOrderStatus.rejected];
const SUCCESS_STATUS = [EDualOrderStatus.filled];
const PENDING_STATUS = [...FAILED_STATUS, ...SUCCESS_STATUS];
function StructuredSubscribe(props: IProps) {
  const router = useRouter();
  const WHOLE_SECONDS = 365 * 24 * 60 * 60;
  const intl = i18nUtil.t();
  const location_text: string = intl["kyc.certify.location"];
  const modalProductTitle = intl["structured.modal.subscription.product"];
  const modalDirectionTitle = intl["structured.modal.subscription.direction"];
  const modalAmountTitle = intl["structured.modal.subscription.amount"];
  const modalExpiryTitle = intl["structured.modal.subscription.expiryDate"];
  const modalPriceTitle = intl["structured.modal.subscription.targetPrice"];
  const modalInitialPriceTitle = intl["structured.modal.subscription.initialPrice"];
  const modalEstTitle = intl["structured.success.subscription.est"];
  const modalTimeTitle = intl["structured.success.subscription.time"];
  const modalAprTitle = intl["structured.success.subscription.apr"];
  const underlying = getQueryValue("underlying");
  const type = getQueryValue("type");
  // 产品ID
  const id = decodeURIComponent(getQueryValue("id"));
  // 交易对完整名称
  const name = getQueryValue("name");
  // quote
  const quote: string = name?.split("-")?.[1] || "";
  const subscriptionMax = useRef<number>(0);
  const subscriptionMin = useRef<number>(0);
  const tickSize = useRef<number>(0);
  const productId = useRef<string>("");
  const settleMinuteBuffer = useRef<number>(0);
  const orderId = useRef<string>("");
  const errorMsg = useRef<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [currentDialog, setCurrentDialog] = useState<string>("");
  const [resultDialogList, setResultDialogList] = useState<
    Array<{ label: string; value: string | number }>
  >([]);
  const [available, setAvailable] = useState<number>(0);
  const [amount, setAmount] = useState<string>("");
  const [detailInfo, setDetailInfo] = useState<IStructuredProductModel>();
  const [interestAbove, setInterestAbove] = useState<number | string>("--");
  const [rewardsAbove, setRewardsAbove] = useState<number | string>("--");
  const [interestBelow, setInterestBelow] = useState<number | string>("--");
  const [rewardsBelow, setRewardsBelow] = useState<number | string>("--");

  useEffect(() => {
    initData();
    _requestUnderlyingData();
    _requestOrderId();
    _requestAvailable();
  }, []);
  const initData = () => {
    getStructuredDetail({ underlying, quote: convertUSDC2USD(quote), type, id })
      .then(async (res) => {
        const { code, data } = res;
        if (code === "0") {
          setDetailInfo(data);
          calculateSettlementAbove(1, data);
          calculateSettlementBelow(1, data);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((err) => {
        message.error(intl?.[err?.msg] ?? err?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * 获取产品信息（subscriptionMax, subscriptionMin, tickSize, productId）
   */
  const _requestUnderlyingData = () => {
    getStructuredUnderlying().then(async (res) => {
      if (res?.code == "0") {
        const { data } = res;
        const arr =
          data &&
          data.filter((item) => {
            const targetProductName = `${item.underlying}-${convertUSD2USDC(item.quote)}`;
            const curProductName = `${underlying}-${convertUSD2USDC(quote)}`;
            return targetProductName === curProductName && item.type === type;
          });
        subscriptionMax.current = arr[0]?.subscriptionMax;
        subscriptionMin.current = arr[0]?.subscriptionMin;
        tickSize.current = arr[0]?.tickSize;
        settleMinuteBuffer.current = arr[0]?.settleMinuteBuffer;
        productId.current = arr[0]?.productId;
      }
    });
  };

  /**
   * 获取订单ID
   */
  const _requestOrderId = () => {
    getStructuredOrderId().then(async (res) => {
      if (res?.code == "0") {
        orderId.current = res?.data ?? "";
      }
    });
  };

  /**
   * 获取现货账户币种余额
   */
  const _requestAvailable = () => {
    let currency = type === STRUCTURED_DUAL_TYPE.BuyLow ? quote : underlying;
    currency = currency === BENCHMARK_CURRENCY ? REFERENCE_CURRENCY : currency;
    availableSpots({
      currency: currency
    }).then((res) => {
      setAvailable(res?.data[currency]);
    });
  };

  const handleChangeAmount = (e: string) => {
    setAmount(fixNumString(e, 8));
    calculateSettlementAbove(fixNumString(e, 8));
    calculateSettlementBelow(fixNumString(e, 8));
  };

  //输入金额错误提示文案
  const errAmountTips = () => {
    let currency = type === STRUCTURED_DUAL_TYPE.BuyLow ? quote : underlying;
    errorMsg.current = "";
    if (Number(amount) < subscriptionMin.current) {
      errorMsg.current = i18nUtil.formatMessage(
        { id: "structured.input.check.error.min" },
        {
          amount: subscriptionMin.current,
          underlying: currency
        }
      );
    }
    if (Number(amount) > subscriptionMax.current) {
      errorMsg.current = i18nUtil.formatMessage(
        { id: "structured.input.check.error.max" },
        {
          amount: subscriptionMax.current,
          underlying: currency
        }
      );
    }
    if (Number(amount) > available) {
      errorMsg.current = intl["structured.input.check.error.insufficient"];
    }
    // amount必须是tickSize的倍数;
    if (!isComplementation(amount, tickSize?.current)) {
      errorMsg.current = i18nUtil.formatMessage(
        { id: "structured.input.check.error.multiple" },
        {
          tickSize: tickSize.current
        }
      );
    }
    return errorMsg.current ? errorMsg.current : null;
  };

  /**
   * 下单（下单后查询订单状态）
   */
  const _requestPlaceOrder = () => {
    if (errorMsg.current) {
      message.error(errorMsg.current);
      return;
    }
    setLoading(true);
    const params = {
      orderId: orderId.current,
      itemId: id,
      productId: productId.current,
      apr: detailInfo!.estimateApr.toString(),
      amount: amount
    };
    reportEvent({
      moduleName: GA_EVENT_NAME.dualSubscribe.submitSubscribe,
      detailParams: { ...params }
    });
    structuredPlaceOrder(params)
      .then(async (res) => {
        if (res?.code == "0") {
          setTimeout(() => {
            checkOrder();
          }, 8000);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
          setLoading(false);
          _requestOrderId();
        }
      })
      .catch((err) => {
        message.error(intl?.[err?.msg] ?? err?.msg);
        setLoading(false);
        _requestOrderId();
      });
  };

  const _renderDialogResultList = (data: IStructuredOrderDetailModel) => {
    let succeedList: List[] = [];
    let failedList: List[] = [];
    let pendingList: List[] = [];
    if (data.state == EDualOrderStatus.filled) {
      const est = data?.expiry! + data?.settleMinuteBuffer! * 60 * 1000;
      succeedList = [
        {
          label: modalProductTitle,
          value: `${name} ${intl[`structured.${data?.type?.toLocaleLowerCase()}`]}`
        },
        {
          label: modalDirectionTitle,
          value: `${intl[`structured.${data?.type?.toLocaleLowerCase()}`]}`
        },
        {
          label: modalAmountTitle,
          value: `${decimalPointNoMoreX(data?.amount, 2)} ${
            data?.type === STRUCTURED_DUAL_TYPE.BuyLow
              ? convertUSD2USDC(data?.quote)
              : data?.subscription
          }`
        },
        {
          label: modalPriceTitle,
          value: showStrike(data?.strike, quote, detailInfo?.initialPrice ? 6 : 2)
        },
        {
          label: modalExpiryTitle,
          value: dayjs(data?.expiry).format("YYYY-MM-DD HH:mm")
        },
        {
          label: modalEstTitle,
          value: dayjs(est).format("YYYY-MM-DD HH:mm")
        },
        {
          label: modalAprTitle,
          value: `${processAPR(data?.estimateApr)}%`
        },
        {
          label: modalTimeTitle,
          value: dayjs(data?.created).format("YYYY-MM-DD HH:mm")
        }
      ];
      if (detailInfo?.initialPrice) {
        const _value = {
          label: modalInitialPriceTitle,
          value: showStrike(Number(data?.initialPrice), quote, 6)
        };
        succeedList.splice(3, 0, _value);
      }
      setResultDialogList(succeedList);
    } else if (FAILED_STATUS.indexOf(data.state as EDualOrderStatus) !== -1) {
      failedList = [
        {
          label: modalProductTitle,
          value: `${name} ${intl[`structured.${data?.type?.toLocaleLowerCase()}`]}`
        },
        {
          label: modalDirectionTitle,
          value: `${intl[`structured.${data?.type?.toLocaleLowerCase()}`]}`
        },
        {
          label: modalAmountTitle,
          value: `${decimalPointNoMoreX(data?.amount, 2)} ${
            data?.type === STRUCTURED_DUAL_TYPE.BuyLow
              ? convertUSD2USDC(data?.quote)
              : data?.subscription
          }`
        },
        {
          label: modalPriceTitle,
          value: showStrike(data?.strike, quote, detailInfo?.initialPrice ? 6 : 2)
        }
      ];
      if (detailInfo?.initialPrice) {
        const _value = {
          label: modalInitialPriceTitle,
          value: showStrike(Number(data?.initialPrice), quote, 6)
        };
        failedList.push(_value);
      }
      setResultDialogList(failedList);
    } else if (PENDING_STATUS.indexOf(data.state as EDualOrderStatus) === -1) {
      pendingList = [
        {
          label: modalProductTitle,
          value: `${name} ${intl[`structured.${data?.type?.toLocaleLowerCase()}`]}`
        },
        {
          label: modalDirectionTitle,
          value: `${intl[`structured.${data?.type?.toLocaleLowerCase()}`]}`
        },
        {
          label: modalAmountTitle,
          value: `${decimalPointNoMoreX(data?.amount, 2)} ${
            data?.type === STRUCTURED_DUAL_TYPE.BuyLow
              ? convertUSD2USDC(data?.quote)
              : data?.subscription
          }`
        },
        {
          label: modalPriceTitle,
          value: showStrike(data?.strike, quote, detailInfo?.initialPrice ? 6 : 2)
        },
        {
          label: modalExpiryTitle,
          value: dayjs(data?.expiry).format("YYYY-MM-DD HH:mm")
        }
      ];
      if (detailInfo?.initialPrice) {
        const _value = {
          label: modalInitialPriceTitle,
          value: showStrike(Number(data?.initialPrice), quote, 6)
        };
        pendingList.splice(3, 0, _value);
      }
      setResultDialogList(pendingList);
    }
    setTimeout(() => {
      setCurrentDialog(data.state);
    }, 200);
  };

  const checkOrder = () => {
    getStructuredOrderDetail(orderId.current)
      .then((res) => {
        if (res?.code == "0") {
          const { data } = res;
          data.settleMinuteBuffer = settleMinuteBuffer.current;
          reportEvent({
            moduleName: GA_EVENT_NAME.dualSubscribe.subsResult,
            detailParams: {
              underlying: underlying,
              id: id,
              name: name
            }
          });
          _renderDialogResultList(data);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((err) => {
        message.error(intl?.[err?.msg] ?? err?.msg);
      })
      .finally(() => {
        setLoading(false);
        _requestAvailable();
      });
  };

  const calculateSettlementAbove = (amount: string | number, detail?: IStructuredProductModel) => {
    if (amount === "") {
      setInterestAbove("--");
      setRewardsAbove("--");
      return;
    }
    const { strike, estimateApr, expiry } = detail ?? detailInfo!;
    const now = new Date().getTime();
    const dateDiff = expiry - now;
    const formulaInterest =
      type === STRUCTURED_DUAL_TYPE.BuyLow
        ? ((Number(amount) * estimateApr) / WHOLE_SECONDS) * (dateDiff / 1000)
        : ((Number(amount) * estimateApr * strike) / WHOLE_SECONDS) * (dateDiff / 1000);
    const formulaRewards =
      type === STRUCTURED_DUAL_TYPE.BuyLow
        ? Number(amount) + formulaInterest
        : Number(amount) * strike + formulaInterest;
    setInterestAbove(numberToThousands(decimalPointNoMoreX(formulaInterest)));
    setRewardsAbove(numberToThousands(decimalPointNoMoreX(formulaRewards)));
  };

  const calculateSettlementBelow = (amount: string | number, detail?: IStructuredProductModel) => {
    if (amount === "") {
      setInterestBelow("--");
      setRewardsBelow("--");
      return;
    }
    const { estimateApr, expiry, strike } = detail ?? detailInfo!;
    const now = new Date().getTime();
    const dateDiff = expiry - now;
    const formulaInterest =
      type === STRUCTURED_DUAL_TYPE.BuyLow
        ? ((Number(amount) * estimateApr) / strike / WHOLE_SECONDS) * (dateDiff / 1000)
        : ((Number(amount) * estimateApr) / WHOLE_SECONDS) * (dateDiff / 1000);
    const formulaRewards =
      type === STRUCTURED_DUAL_TYPE.BuyLow
        ? Number(amount) / strike + formulaInterest
        : Number(amount) + formulaInterest;
    setInterestBelow(decimalPointNoMoreX(formulaInterest));
    setRewardsBelow(decimalPointNoMoreX(formulaRewards));
  };

  const refreshCurrentPageData = () => {
    setCurrentDialog("");
    router.back();
  };

  const navigateToHistory = () => {
    setCurrentDialog("");
    router.push({ pathname: HISTORY_PATHS.HISTORY, query: { id: PRODUCT_TYPES.structured } });
  };

  return (
    <>
      <section className={styles.structuredSubscribe}>
        <div className="flex">
          <Breadcrumb>
            <Breadcrumb.Item>{location_text}: </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link href={{ pathname: STRUCTURED_PATH.STRUCTURED }}>
                {intl["wallet.structural.dual.currency"]}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{intl["structured.location.subscribe"]}</Breadcrumb.Item>
          </Breadcrumb>
          {!certifiedKycLevel(1) ? (
            <KycGuideTips showToProfileBt={props?.showToProfileBtn} />
          ) : null}
        </div>
        <Spin spinning={loading}>
          <div className={styles.container}>
            {detailInfo && (
              <div className={styles.area}>
                <div className={styles.content}>
                  <div className={styles.coinWrapper}>
                    <CoinImage size={45} showOpacity coins={name.replace("-", ",")} />
                    <div className={styles.info}>
                      <span style={{ fontSize: 22, marginBottom: 8 }}>{name}</span>
                      <span>
                        {type === STRUCTURED_DUAL_TYPE.BuyLow
                          ? i18nUtil.formatMessage(
                              { id: "structured.subscription.buy.title.desc" },
                              { currency: name?.split("-")?.[0] }
                            )
                          : i18nUtil.formatMessage(
                              { id: "structured.subscription.sell.title.desc" },
                              { currency: name?.split("-")?.[0] }
                            )}
                      </span>
                    </div>
                  </div>
                  <div className={styles.innerContent}>
                    <div className={styles.leftContent}>
                      <div className={styles.briefInfo}>
                        <div className={styles.briefInfoItem}>
                          <span className={styles.spanContent}>
                            {intl["structured.item.target"]}
                          </span>
                          <span>
                            {showStrike(
                              detailInfo?.strike,
                              quote,
                              detailInfo?.initialPrice ? 6 : 2
                            )}
                          </span>
                        </div>
                        {detailInfo?.initialPrice ? (
                          <div className={styles.briefInfoItem}>
                            <span className={styles.spanContent}>
                              {intl["structured.item.realtime"]}
                            </span>
                            <span>
                              {numberToThousands(decimalPointNoMoreX(detailInfo?.initialPrice, 6))}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className={styles.briefInfoItem}>
                          <span className={styles.spanContent}>
                            {intl["structured.modal.subscription.expiryDate"]}
                          </span>
                          <span>{dayjs(detailInfo?.expiry).format("YYYY-MM-DD")}</span>
                        </div>
                        <div className={styles.briefInfoItem} style={{ borderRightWidth: 0 }}>
                          <span className={styles.spanContent}>
                            {intl["structured.item.est.apr"]}
                          </span>
                          <span>{processAPR(detailInfo?.estimateApr ?? 0)}%</span>
                        </div>
                      </div>
                      <p className={styles.thirdTitle}>{intl["savings.automatic.add.available"]}</p>
                      <p className={styles.amount}>
                        {decimalPointNoMoreX(available)}{" "}
                        {type === STRUCTURED_DUAL_TYPE.BuyLow ? quote : underlying}
                      </p>
                      <p className={styles.thirdTitle}>{intl["structured.subscription.amount"]}</p>
                      <div className={styles.inputContent}>
                        <NumericInput
                          bordered={false}
                          value={amount}
                          placeholder={intl["structured.input.placeholder"]}
                          className={styles.amountInput}
                          onChangeEvent={handleChangeAmount}
                        />
                        <span className={styles.currencyBtn}>
                          {type === STRUCTURED_DUAL_TYPE.BuyLow ? quote : underlying}
                        </span>
                      </div>
                      <p
                        style={{ visibility: !amount ? "hidden" : "visible", height: 22 }}
                        className={styles.errorTips}>
                        {errAmountTips()}
                      </p>
                      <div className={styles.btnArea}>
                        <Button
                          style={{ width: 128, marginRight: 16 }}
                          className={styles.actionBtn}
                          onClick={() => {
                            router.push({
                              pathname: STRUCTURED_PATH.STRUCTURED
                            });
                          }}>
                          {intl["button.back"]}
                        </Button>
                        <Button
                          style={{ width: 128 }}
                          disabled={!amount || !!errorMsg.current}
                          type="primary"
                          onClick={_requestPlaceOrder}>
                          {intl["button.subscribe"]}
                        </Button>
                      </div>
                    </div>

                    <div className={styles.rightContent}>
                      <p className={styles.secTitle}>{intl["savings.structured.reference"]}</p>
                      <div className={styles.settlementArea}>
                        <span className={styles.smallTxt}>
                          {intl["overview.savings.subscribe"]}
                        </span>
                        <span>
                          {amount || 1} {type === STRUCTURED_DUAL_TYPE.BuyLow ? quote : underlying}
                        </span>
                      </div>
                      <p className={styles.tipsTitle}>
                        {`${intl["structured.subscription.settlement.tips1"]} ${
                          type === STRUCTURED_DUAL_TYPE.BuyLow ? "≤" : "≥"
                        } ${showStrike(
                          detailInfo?.strike,
                          quote,
                          detailInfo?.initialPrice ? 6 : 2
                        )}${intl["structured.subscription.settlement.tips2"]}`}
                      </p>
                      <div className={styles.settlementArea}>
                        <span className={styles.smallTxt}>
                          {intl["structured.subscription.interest"]}:
                        </span>
                        <span>
                          {type === STRUCTURED_DUAL_TYPE.BuyLow ? interestBelow : interestAbove}{" "}
                          {`${type === STRUCTURED_DUAL_TYPE.BuyLow ? underlying : quote}`}
                        </span>
                      </div>
                      <div className={styles.settlementArea}>
                        <span className={styles.smallTxt}>
                          {intl["structured.subscription.rewards"]}:
                        </span>
                        <span>
                          {type === STRUCTURED_DUAL_TYPE.BuyLow ? rewardsBelow : rewardsAbove}{" "}
                          {`${type === STRUCTURED_DUAL_TYPE.BuyLow ? underlying : quote}`}
                        </span>
                      </div>
                      <p className={styles.tipsTitle}>
                        {`${intl["structured.subscription.settlement.tips1"]} ${
                          type === STRUCTURED_DUAL_TYPE.BuyLow ? ">" : "<"
                        } ${showStrike(
                          detailInfo?.strike,
                          quote,
                          detailInfo?.initialPrice ? 6 : 2
                        )}${intl["structured.subscription.settlement.tips2"]}`}
                      </p>
                      <div className={styles.settlementArea}>
                        <span className={styles.smallTxt}>
                          {intl["structured.subscription.interest"]}:
                        </span>
                        <span>
                          {type === STRUCTURED_DUAL_TYPE.BuyLow ? interestAbove : interestBelow}{" "}
                          {`${type === STRUCTURED_DUAL_TYPE.BuyLow ? quote : underlying}`}
                        </span>
                      </div>
                      <div className={styles.settlementArea}>
                        <span className={styles.smallTxt}>
                          {intl["structured.subscription.rewards"]}:
                        </span>
                        <span>
                          {type === STRUCTURED_DUAL_TYPE.BuyLow ? rewardsAbove : rewardsBelow}{" "}
                          {`${type === STRUCTURED_DUAL_TYPE.BuyLow ? quote : underlying}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {currentDialog === EDualOrderStatus.filled ? (
            <ModalResult
              open={currentDialog === EDualOrderStatus.filled}
              resultType={"success"}
              title={intl["structured.modal.subscription.success"]}
              list={resultDialogList}
              onCancel={refreshCurrentPageData}
              onOk={refreshCurrentPageData}>
              <Button className={styles.modalButton} type="default" onClick={navigateToHistory}>
                {intl["structured.history.detail.view"]}
              </Button>
            </ModalResult>
          ) : null}
          {FAILED_STATUS.indexOf(currentDialog as EDualOrderStatus) !== -1 ? (
            <ModalResult
              open={FAILED_STATUS.indexOf(currentDialog as EDualOrderStatus) !== -1}
              resultType={"fail"}
              title={intl["structured.modal.subscription.fail"]}
              list={resultDialogList}
              onOk={refreshCurrentPageData}
              onCancel={refreshCurrentPageData}
            />
          ) : null}
          {PENDING_STATUS.indexOf(currentDialog as EDualOrderStatus) === -1 &&
          currentDialog !== "" ? (
            <ModalResult
              open={
                PENDING_STATUS.indexOf(currentDialog as EDualOrderStatus) === -1 &&
                currentDialog !== ""
              }
              resultType={"pending"}
              title={intl["structured.modal.subscription.pending"]}
              list={resultDialogList}
              onOk={refreshCurrentPageData}
              onCancel={refreshCurrentPageData}>
              <Button className={styles.modalButton} type="default" onClick={navigateToHistory}>
                {intl["structured.history.detail.view"]}
              </Button>
            </ModalResult>
          ) : null}
        </Spin>
      </section>
      <Disclaimer />
    </>
  );
}

export const PageDualSubscribe = WithCoinList(React.memo(StructuredSubscribe));
