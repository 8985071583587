import React from "react";
import { decimalPointNoMoreX, i18nUtil, Months, numberToThousands } from "@aspen/libs";
import LineChart from "../charts/LineChart";
import type { IYtdTrend } from "@aspen/model";
import dayjs from "dayjs";

type ILineModel = {
  x: string;
  y: number;
  type: "btc" | "sp";
};

interface IProps {
  ytdTrend: IYtdTrend | undefined;
}

interface IState {}

const maxXTickCount = 12;

class BtcChart extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  _handleList = (list: Array<string>, type: ILineModel["type"]): Array<ILineModel> => {
    return (
      list?.map((item) => {
        let date = dayjs(Number(item.split(",")?.[0]) * 1000);
        let month = date.month();
        let year = date.year();
        return {
          x: i18nUtil.t()[Months][`${month  }`] + (month == 0 ? `,${year}` : ""),
          y: Number(item.split(",")?.[1]),
          type
        };
      }) || []
    );
  };

  render() {
    const { ytdTrend } = this.props;
    const btcList = this._handleList(ytdTrend?.YTD_BTC?.bars ?? [], "btc");
    const spList = this._handleList(ytdTrend?.YTD_SP?.bars ?? [], "sp");
    // @ts-ignore
    const list = [].concat(btcList, spList);

    return (
      <LineChart
        xField="x"
        yField="y"
        list={list}
        xTickCount={maxXTickCount}
        yAxisFormatter={(text: string) => {
          return `${numberToThousands(decimalPointNoMoreX(text, 2))}%`;
        }}
        yAxisPosition="right"
        crosshairsType="xy"
        xRange={[0, 1]}
        xAxisLine={{
          style: {
            stroke: "#5E606D"
          }
        }}
        yAxisLine={{
          style: {
            stroke: "#5E606D"
          }
        }}
        isStack={false}
        legend={false}
        seriesField="type"
        colorField="type"
        xOffset={15}
        appendPadding={3}
        line={{
          style: { lineWidth: 1.5 }
        }}
        color={({ type }) => {
          if (type === "btc") {
            return "rgb(241,126,0)";
          }
          return "rgb(80,125,201)";
        }}
        areaStyle={({ type }) => {
          if (type === "btc") {
            return {
              fill: `l(90) 0:${"rgba(241,126,0,0.8)"} 1:${"rgba(241,126,0,0)"}`
            };
          }
          return {
            fill: `l(90) 0:${"rgba(80,125,201,0.8)"} 1:${"rgba(80,125,201,0)"}`
          };
        }}
        slider={{
          start: 0,
          end: 1
        }}
        tooltip={{
          showTitle: true,
          title: (title: string, datum: ILineModel) => {
            // const date = dayjs(Number(title) * 1000);
            // return Months[date.month() + ""] + "," + date.year();
            return title;
          },
          formatter: (datum: ILineModel) => {
            return {
              name: datum?.type == "btc" ? i18nUtil.t()["dca.dca.btc.dca.info"]
                  : i18nUtil.t()["dca.dca.btc.sp.info"],
              value: `${numberToThousands(decimalPointNoMoreX(datum?.y, 2))}%`
            };
          }
        }}
      />
    );
  }
}

export default BtcChart;
