import dynamic from "next/dynamic";
export * from "./assets/index";
export * from "./charts/index";
export * from "./clientManagement/DynamicImport";
export * from "./download/DynamicImport";
export * from "./history/DynamicImport";
export * from "./home/DynamicImport";
export * from "./intelligence/DynamicImport";
export * from "./structured/DynamicImport";
export * from "./login/DynamicImport";
export * from "./modalBusinessSwitchVerify";
export * from "./modalBusinessVerification";
export * from "./modalConfirm";
export * from "./modalGoogleAuth/ModalGoogleAuth";
export * from "./modalPhoneVerification";
export * from "./modalResult";
export * from "./modalSafetyVerification/ModalSafetyVerification";
export * from "./phoneInput/PhoneInput";
export * from "./profile/DynamicImport";
export * from "./strategy/DynamicImport";
export * from "./trade/DynamicImport";
export * from "./venture/DynamicImport";
export * from "./vip/PrivilegesTable";
export * from "./wallet";
export * from "./withAliCloudNC/WithAliCloudNC";
export * from "./withEmailOrGoogleVerify/WithEmailOrGoogleVerify";
export * from "./withRiskReminder/WithRiskReminder";
export * from "./yield/DynamicImport";
export * from "./ModalChangePassword";
export * from "./pageViews";

export const OnfidoComp = dynamic(() => import("./onfido/index"), {
  ssr: false
});

export const AgencyTipsArea = dynamic(() => import("./agency/agencyTipsArea/AgencyTipsArea"), {
  ssr: false
});
export const Header = dynamic(() => import("./header/Header"), {
  ssr: false
});
