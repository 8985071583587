import React, { useEffect, useState } from "react";
import { Col, Row, Space, Spin } from "antd";
import clsx from "clsx";
import styles from "@aspen/theme/Intelligence.module.less";
import { fecthIntelligenceNewsDetail, fecthIntelligenceNews } from "@aspen/services";
import { ONLY_MOBILE, ssrSetHeaders, i18nUtil, INTELLIGENCE_PATHS } from "@aspen/libs";
import type { INews, IReqIntelligenceNews } from "@aspen/model";
import { NEWSTYPE, EVENT_ACTIONS } from "@aspen/model";
import {
  ResearchItem,
  NewsCard,
  ExpertCardItem,
  DownloadAppCard,
  NewsCardItem,
  EmptyShareDetail,
  DetailInfo,
  JumpApp
} from "../../intelligence/DynamicImport";
import { HeadRender } from "@aspen/ui";
import { useAsideHeight } from "@aspen/hooks";

interface IProps {
  detailData: INews;
}

const ResearchDetail: React.FC<IProps> = (props: IProps) => {
  const [detailContent, setDetailContent] = useState<INews | null>(props.detailData);
  const [researchList, setResearchList] = useState<INews[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // 热点追踪
  const [newsList, setNewsList] = useState<INews[]>([]);
  const [newsLoading, setNewsLoading] = useState<boolean>(false);
  // 专栏
  const [expertList, setExpertList] = useState<INews[]>([]);
  const [expertLoading, setExpertLoading] = useState<boolean>(false);

  const intl = i18nUtil.t();
  const recommendedReading = intl["intelligence.research.recommended.reading"];
  const hotNewsText = intl["intelligence.news.hot.news.text"];
  const expertViews = intl["intelligence.expert.views"];
  const moreText = intl["link.more"];

  const { ref: asideRef } = useAsideHeight();

  useEffect(() => {
    if (props.detailData) setDetailContent(props.detailData);
  }, [props.detailData]);

  useEffect(() => {
    if (!ONLY_MOBILE) {
      getExpertList(); // 获取专栏列表
      getNewsList(); // 获取Hot News列表
    }
  }, []);

  useEffect(() => {
    if (!ONLY_MOBILE) {
      getResearchList(); // 获取研报列表
    }
  }, [detailContent?.newsTime]);

  // 获取研报列表
  const getResearchList = () => {
    setLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 4,
      action: "all",
      type: NEWSTYPE.research, // 研报
      startTime: detailContent?.newsTime
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setResearchList(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 获取专栏列表
  const getExpertList = () => {
    setExpertLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 3,
      action: "all",
      type: NEWSTYPE.expert
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setExpertList(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setExpertLoading(false);
      });
  };

  // 获取 Hot News List
  const getNewsList = () => {
    setNewsLoading(true);
    const params: IReqIntelligenceNews = {
      limit: 5,
      action: "all",
      type: NEWSTYPE.news // 爬虫新闻
    };
    fecthIntelligenceNews(params)
      .then((res) => {
        if (res?.code == "0") {
          setNewsList(res?.data?.rows ?? []);
        }
      })
      .finally(() => {
        setNewsLoading(false);
      });
  };

  return (
    <>
      {/* 新闻详情单独处理 */}
      <HeadRender
        title={detailContent?.title}
        description={detailContent?.summary}
        image={detailContent?.images?.[0]}
      />

      <section>
        <Row className={styles.row} gutter={16}>
          <Col flex="auto">
            <Spin spinning={loading}>
              <div className={clsx(styles.researchDetail, ONLY_MOBILE && styles.app)}>
                {detailContent ? (
                  <>
                    <DetailInfo type="research" detailInfo={detailContent} />
                    {ONLY_MOBILE ? (
                      <JumpApp
                        event_action={EVENT_ACTIONS.researchDetail}
                        id={detailContent?.id}
                        path={INTELLIGENCE_PATHS.INTELLIGENCE_RESEARCH_DETAIL}
                      />
                    ) : null}
                  </>
                ) : (
                  <EmptyShareDetail />
                )}
                {ONLY_MOBILE ||
                  (researchList?.length > 0 && (
                    <NewsCard
                      loading={loading}
                      title={recommendedReading}
                      link={INTELLIGENCE_PATHS.INTELLIGENCE_RESEARCH}>
                      <Row className={styles.readingList} gutter={30}>
                        {researchList.map((item) => (
                          <Col span={12} key={item.id}>
                            <ResearchItem key={item.id} data={item} sourcePage="detail" />
                          </Col>
                        ))}
                      </Row>
                    </NewsCard>
                  ))}
              </div>
            </Spin>
          </Col>
          {ONLY_MOBILE || (
            <Col
              flex="340px"
              ref={asideRef}
              className={styles.detailExtraContainer}
              style={{
                position: expertLoading || newsLoading ? "static" : "sticky"
              }}>
              <Spin spinning={expertLoading || newsLoading}>
                <Space size={16} direction="vertical">
                  {/* 专栏 */}
                  {expertList?.length > 0 && (
                    <NewsCard
                      title={expertViews}
                      linkText={moreText}
                      link={INTELLIGENCE_PATHS.INTELLIGENCE_EXPERT}>
                      {expertList?.map((item) => <ExpertCardItem key={item.id} data={item} />)}
                    </NewsCard>
                  )}
                  {/* 热门新闻 */}
                  <NewsCard
                    title={hotNewsText}
                    linkText={moreText}
                    link={INTELLIGENCE_PATHS.INTELLIGENCE_NEWS}>
                    {newsList?.map((item) => (
                      <NewsCardItem
                        key={item.id}
                        data={item}
                        path={INTELLIGENCE_PATHS.INTELLIGENCE_NEWS_DETAIL}
                      />
                    ))}
                  </NewsCard>
                  <DownloadAppCard />
                </Space>
              </Spin>
            </Col>
          )}
        </Row>
      </section>
    </>
  );
};

export const PageResearchDetail = React.memo(ResearchDetail);
