import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Tabs } from "antd";
import { useRouter } from "next/router";
import styles from "@aspen/theme/Venture.module.less";
import {
  getQueryValue,
  i18nUtil,
  reportEvent,
  GA_EVENT_NAME,
  GA_EVENT_TAG,
  isClient
} from "@aspen/libs";
import { ventureShares } from "@aspen/services";
import { message, Disclaimer } from "@aspen/ui";
import { Primary, Secondary, ModalShares, ModalSharesResult } from "../../index";

interface IProps {}

const Venture: React.FC<IProps> = () => {
  const intl = i18nUtil.t();
  const title = intl["ventures.title"];
  const tabItems = [
    {
      label: intl["ventures.primary.tab"],
      key: "primary"
    },
    {
      label: intl["ventures.secondary.tab"],
      key: "secondary"
    }
  ];
  const [currentTab, setCurrentTab] = useState<string>("primary");
  const [sharesVisible, setSharesVisible] = useState<boolean>(false);
  const [sharesResVisible, setSharesResVisible] = useState<boolean>(false);
  const router = useRouter();
  const ventureName = useRef<string>("");
  const sharesType = useRef<string>("");

  useEffect(() => {
    const defaultTab =
      router?.query?.currentTab?.toString() ||
      (isClient && getQueryValue("currentTab")) ||
      "primary";
    setCurrentTab(defaultTab);
  }, []);
  // 点击切换tab
  const handleChangeTabs: (T: string) => void = (value: string) => {
    setCurrentTab(value);
    router?.replace({ query: { currentTab: value } });
    reportEvent({
      moduleName: GA_EVENT_NAME.ventures.switchVentureList,
      detailParams: { tab: value }
    });
  };

  const handleSubmit = (_ventureName: string, _sharesType: string) => {
    ventureName.current = _ventureName;
    sharesType.current = _sharesType;
    reportEvent({
      moduleName: GA_EVENT_NAME.ventures.submitListSecondary,
      detailParams: { _ventureName, _sharesType }
    });
    ventureShares({ projectName: _ventureName, assetType: _sharesType })
      .then((res) => {
        if (res?.code == 0) {
          setSharesResVisible(true);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((error) => {
        message.error(intl?.[error?.msg] ?? error?.msg);
      })
      .finally(() => {
        setSharesVisible(false);
      });
  };
  return (
    <>
      <section className={styles.venture + " " + "customer"}>
        <div className="customerTop">
          <div className="top">
            <Col span={12}>
              <div>
                <p className="title">{title}</p>
              </div>
            </Col>
          </div>
        </div>

        <Row className="section">
          <Col span={24}>
            {typeof window != "undefined" && (
              <>
                <Tabs
                  items={tabItems}
                  className={styles.tab}
                  activeKey={currentTab}
                  onChange={handleChangeTabs}
                />
                <Button
                  type="primary"
                  className={styles.sharesWrapper}
                  onClick={() => {
                    reportEvent({
                      joinedTag: GA_EVENT_TAG.Modal,
                      moduleName: GA_EVENT_NAME.ventures.listSecondary
                    });
                    setSharesVisible(true);
                  }}>
                  {intl["ventures.shares.title"]}
                </Button>
                <div className={styles.list}>
                  {currentTab === "primary" && (
                    <div className={styles.flexibleContent}>
                      <Primary />
                    </div>
                  )}
                  {currentTab === "secondary" && (
                    <div className={styles.flexibleContent}>
                      <Secondary />
                    </div>
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
        {sharesVisible ? (
          <ModalShares
            visible={sharesVisible}
            cancelModal={() => {
              setSharesVisible(false);
            }}
            handleSubmit={(ventureName, sharesType) => {
              handleSubmit(ventureName, sharesType);
            }}
          />
        ) : null}
        {sharesResVisible ? (
          <ModalSharesResult
            visible={sharesResVisible}
            ventureName={ventureName.current}
            sharesType={sharesType.current}
            cancelModal={() => {
              setSharesResVisible(false);
            }}
          />
        ) : null}
      </section>
      <Disclaimer />
    </>
  );
};

export const PageVentures = React.memo(Venture);
