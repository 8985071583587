import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import dayjs from "dayjs";
import styles from "@aspen/theme/Structured.module.less";
import { Breadcrumb, Button, Divider, Spin, Table, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  AUTHORIZATION_TYPE,
  certifiedKycLevel,
  convertUSD2USDC,
  GA_EVENT_NAME,
  GA_EVENT_TAG,
  getQueryValue,
  i18nUtil,
  isComplementation,
  numberToThousands,
  REG_EXP_POSITIVE_NUMBER,
  reportEvent,
  STRUCTURED_PATH,
  STRUCTURED_TYPES,
  USER_AUTH,
  USER_ROLE_POWER
} from "@aspen/libs";
import { IAccumulatorOrderModel } from "@aspen/model";
import {
  availableSpots,
  getSnowballOrderDetail,
  getSnowballProductDetail,
  postSnowballOrder
} from "@aspen/services";
import { CoinImage, Disclaimer, KycGuideTips, message, WithTrimInput } from "@aspen/ui";
import {
  InitialPriceTooltip,
  ModalSafetyVerification,
  ModalSubscribeResult,
  ModalSuspend,
  SnowballDetail
} from "../../index";

interface IProps {
  showToProfileBtn?: boolean;
}

const SnowSubscribe = (props: IProps) => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const id = router?.query?.id?.toString() ?? getQueryValue("id") ?? "";
  const underlying = router?.query?.underlying?.toString() ?? getQueryValue("underlying") ?? "";
  const quote = router?.query?.quote?.toString() ?? getQueryValue("quote") ?? "";
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(null);
  const [available, setAvailable] = useState(0);
  const [openResultModal, setOpenResultModal] = useState<boolean>(false);
  const [openSuspendModal, setOpenSuspendModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [order, setOrder] = useState<IAccumulatorOrderModel>();
  const [showSafetyVerificationModal, setShowSafetyVerificationModal] = useState<boolean>(false);

  const goBack = () => {
    reportEvent({
      moduleName: GA_EVENT_NAME.snowball.cancelSubscribePage,
      detailParams: {
        id,
        underlying,
        quote,
        structuredType: STRUCTURED_TYPES.snowball
      }
    });
    router.back();
  };

  /**
   * 雪球详情
   */
  const fetchDetail = async () => {
    try {
      const res = await getSnowballProductDetail({ underlying, quote, id });
      setLoading(false);
      // 查询雪球详情接口如果异常，则返回前一个页面
      if (res?.code !== "0") {
        message.error(intl[res?.msg] ?? res?.msg);
        goBack();
        return;
      }
      if (!res?.data || Object.keys(res?.data).length <= 0) {
        goBack();
        return;
      }
      setData(res?.data);
    } catch (err) {
      setLoading(false);
      goBack();
    }
  };

  /**
   * 获取现货账户币种余额
   */
  const fetchAvailable = async () => {
    const res = await availableSpots({ currency: quote, category: 0 });
    if (res?.code !== "0") {
      message.error(intl[res?.msg] ?? res?.msg);
      return;
    }
    setAvailable(res?.data?.[quote]);
  };
  const onRefresh = () => {
    fetchDetail();
    fetchAvailable();
  };

  useEffect(() => {
    onRefresh();
  }, []);

  const { subscriptionMin, tickSize, productId } = data ?? {};
  const onChange = (inputValue: string) => {
    // 只能输入正数
    if (!REG_EXP_POSITIVE_NUMBER.test(inputValue)) {
      return;
    }
    // 输入框限制最多允许输入八位小数
    let params = inputValue.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".");
    const value = params.replace(/^(-)*(\d+)\.(\d{1,8}).*$/, "$1$2.$3");
    if (isNaN(Number(value))) {
      return;
    }

    setValue(value);
    // 校验输入框输入逻辑
    const amount = Number(value);
    // 判断申购数量不能低于最小申购额
    if (amount < subscriptionMin) {
      const error = i18nUtil.formatMessage(
        { id: "structured.accumulator.subscribe.minimum" },
        { amount: subscriptionMin, coin: convertUSD2USDC(quote) }
      );
      setError(error);
      return;
    }
    if (amount > available) {
      const error = intl["structured.accumulator.subscribe.insufficient"];
      setError(error);
      return;
    }
    if (tickSize && !isComplementation(amount, tickSize)) {
      const error = i18nUtil.formatMessage(
        { id: "structured.accumulator.subscribe.multiple" },
        { amount: tickSize ?? "--" }
      );
      setError(error);
      return;
    }
    setError(null);
  };
  const columns1 = [
    {
      title: intl["structural.snowball.period"],
      dataIndex: "period",
      width: 110,
      key: "period",
      render: (text) => (
        <p className={styles.valueText}>
          {text} {text ? intl["structured.accumulator.week"] : "--"}
        </p>
      )
    },
    {
      title: (
        <>
          <span>{i18nUtil.t()["structural.snowball.estAPR"]}</span>
          <Tooltip title={i18nUtil.t()["structural.snowball.estAPR.tooltips"]}>
            <InfoCircleOutlined className={styles.toolTipIcon} />
          </Tooltip>
        </>
      ),
      width: 128,
      dataIndex: "estimateApr",
      key: "estimateApr",
      render: (text) => (
        <p className={styles.valueText}>{text ? (Number(text) * 100).toFixed(2) : "--"}%</p>
      )
    },
    {
      title: (
        <>
          <span>{i18nUtil.t()["structural.snowball.strikePrice"]}</span>
          <Tooltip title={i18nUtil.t()["structural.snowball.strikePrice.tooltips"]}>
            <InfoCircleOutlined className={styles.toolTipIcon} />
          </Tooltip>
        </>
      ),
      width: 130,
      dataIndex: "strike",
      key: "strike",
      render: (text) => (
        <p className={styles.valueText}>{text ? `${(Number(text) * 100).toFixed(2)}%` : "--"}</p>
      )
    },
    {
      title: intl["structural.snowball.expiry"],
      dataIndex: "expiry",
      key: "expiry",
      render: (text) => (
        <p className={styles.valueText}>{text ? dayjs(text).format("YYYY-MM-DD") : "--"}</p>
      )
    }
  ];
  const columns2 = [
    {
      title: (
        <>
          <span>{i18nUtil.t()["structural.snowball.knockIn"]}</span>
          <Tooltip title={i18nUtil.t()["structural.snowball.knockIn.tooltips"]}>
            <InfoCircleOutlined className={styles.toolTipIcon} />
          </Tooltip>
        </>
      ),
      width: 110,
      dataIndex: "knockInPrice",
      key: "knockInPrice",
      render: (text) => (
        <p className={styles.valueText}>{text ? `${(Number(text) * 100).toFixed(2)}%` : "--"}</p>
      )
    },
    {
      title: (
        <>
          <span>{i18nUtil.t()["structural.snowball.knockOut"]}</span>
          <Tooltip title={i18nUtil.t()["structural.snowball.knockOut.tooltips"]}>
            <InfoCircleOutlined className={styles.toolTipIcon} />
          </Tooltip>
        </>
      ),
      width: 128,
      dataIndex: "knockOutPrice",
      key: "knockOutPrice",
      render: (text) => (
        <p className={styles.valueText}>{text ? `${(Number(text) * 100).toFixed(2)}%` : "--"}</p>
      )
    },
    {
      title: i18nUtil.t()["snowball.columns.kIObservation"],
      dataIndex: "kiObservedPeriod",
      key: "kiObservedPeriod",
      width: 130,
      render: (text) => (
        <p className={styles.valueText}>{intl[`snowball.columns.observation.${text}`] ?? text}</p>
      )
    },
    {
      title: i18nUtil.t()["snowball.columns.kOObservation"],
      dataIndex: "koObservedPeriod",
      key: "koObservedPeriod",
      render: (text) => (
        <p className={styles.valueText}>{intl[`snowball.columns.observation.${text}`] ?? text}</p>
      )
    }
  ];

  const checkOrder = async (data) => {
    const res = await getSnowballOrderDetail(data?.orderId);
    setLoading(false);
    setOrder(res?.data ?? data);
    setOpenResultModal(true);
    reportEvent({
      joinedTag: GA_EVENT_TAG.Modal,
      moduleName: GA_EVENT_NAME.snowball.subsResult,
      detailParams: {
        id: productId,
        itemId: id,
        amount: value,
        structuredType: STRUCTURED_TYPES.snowball
      }
    });
  };

  const placeOrder = async (emailCode?: string) => {
    try {
      setLoading(true);
      reportEvent({
        moduleName: GA_EVENT_NAME.snowball.submitSubscribe,
        detailParams: {
          id: productId,
          itemId: id,
          amount: value,
          structuredType: STRUCTURED_TYPES.snowball
        }
      });
      const res = await postSnowballOrder({
        productId,
        itemId: id,
        amount: value,
        verifyCode: emailCode || ""
      });
      // 申购接口又返回，需要关闭二次验证弹窗
      hideModal();
      if (res?.code !== "0") {
        setLoading(false);
        if (res?.msg === "order.book.not.exist") {
          reportEvent({
            joinedTag: GA_EVENT_TAG.Modal,
            moduleName: GA_EVENT_NAME.snowball.openSuspended,
            detailParams: {
              id: productId,
              itemId: id,
              amount: value,
              structuredType: STRUCTURED_TYPES.snowball
            }
          });
          setOpenSuspendModal(true);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
        return;
      }
      // 后端表格存储有最长3s的同步延迟，下单接口成功直接查询订单详情接口，有可能返回order.not.exist
      // 为了避免出现order.not.exist，同时保证前端用户体验不是太差，延迟3s再请求订单详情
      setTimeout(() => {
        checkOrder(res?.data);
      }, 3000);
    } catch (err) {
      setLoading(false);
      // 申购接口异常，关闭二次验证弹窗，显示error message
      hideModal();
      message.error(intl["system.error"]);
    }
  };
  const handleSubscribe = () => {
    const authorizationType = localStorage.getItem(AUTHORIZATION_TYPE);
    if (
      USER_ROLE_POWER[localStorage.getItem(USER_AUTH) ?? ""]?.actionForOthers &&
      authorizationType === "0"
    ) {
      setShowSafetyVerificationModal(true);
    } else {
      placeOrder();
    }
  };
  const cancelResultModal = () => {
    setValue("");
    setOpenResultModal(false);
  };
  const cancelSuspendModal = () => setOpenSuspendModal(false);
  const hideModal = () => setShowSafetyVerificationModal(false);
  const today = dayjs().hour(16).minute(0);
  const day = dayjs().valueOf() > today.valueOf() ? 1 : 0;
  return (
    <>
      <section className={styles.structuredSubscribe}>
        <Spin spinning={loading}>
          <div className="flex">
            <Breadcrumb>
              <Breadcrumb.Item>{intl["kyc.certify.location"]}: </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  href={{
                    pathname: STRUCTURED_PATH.STRUCTURED,
                    query: { currentTab: STRUCTURED_TYPES.snowball }
                  }}>
                  {intl["wallet.structural.snowball"]}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{intl["wallet.structural.snowball.subscribe"]}</Breadcrumb.Item>
            </Breadcrumb>
            {!certifiedKycLevel(1) ? (
              <KycGuideTips showToProfileBtn={props?.showToProfileBtn} />
            ) : null}
          </div>
          <div className={styles.accumulatorSubscribeBox}>
            <div className={styles.subHeaderBox}>
              <CoinImage
                // @ts-ignore
                size={45}
                showOpacity
                coins={`${underlying},${convertUSD2USDC(quote)}`}
              />
              <div className={styles.titleBox}>
                <p className={styles.titleText}>
                  {underlying}-{convertUSD2USDC(quote)}
                  &nbsp;
                  {intl["wallet.structural.snowball"]}
                </p>
              </div>
            </div>
            <div className={styles.accumulatorSubscribeBoxContent}>
              <div style={{ flex: "1 1 auto" }}>
                <Table
                  rowKey={(record) => record?.id}
                  bordered={false}
                  dataSource={[data]}
                  columns={columns1}
                  pagination={false}
                />
                <Table
                  rowKey={(record) => record?.id}
                  bordered={false}
                  dataSource={[data]}
                  columns={columns2}
                  pagination={false}
                  footer={() => (
                    <div className={styles.tableFooter}>
                      <div>
                        <span>{intl["structured.accumulator.initial.price"]}</span>
                        <Tooltip
                          overlayClassName={styles.overlayInnerStyle}
                          overlayInnerStyle={{ padding: "18px" }}
                          title={() => <InitialPriceTooltip />}>
                          <InfoCircleOutlined className={styles.toolTipIcon} />
                        </Tooltip>
                      </div>
                      <span className={styles.valueText}>
                        {dayjs(today).add(day, "d").format("YYYY-MM-DD HH:mm")}{" "}
                        {intl["button.confirm"]}
                      </span>
                    </div>
                  )}
                />
                <div>
                  <p className={styles.amountTitleText}>
                    {intl["structured.accumulator.subscribe.available.amount"]}:
                  </p>
                  <p className={styles.amountText}>
                    {available ? numberToThousands(available) : "--"} {convertUSD2USDC(quote)}
                  </p>
                  <p className={styles.inputTitleText}>
                    {intl["structured.accumulator.subscribe.amount.title"]}:
                  </p>
                  <WithTrimInput
                    value={value}
                    addonAfter={
                      <div
                        onClick={() => onChange((available ?? "").toString())}
                        className={styles.maxTextStyle}>
                        {intl["savings.fixed.max"]}
                      </div>
                    }
                    onChange={(e) => onChange(e.target.value)}
                  />
                  {error && <p className={styles.errorText}>{error}</p>}
                </div>
                <Divider />
                <div className={styles.flexRow} style={{ marginBottom: "24px" }}>
                  <span className={styles.keyText}>
                    {intl["structured.accumulator.subscribe.min.amount"]}
                  </span>
                  <span>
                    {numberToThousands(subscriptionMin)} {convertUSD2USDC(quote)}
                  </span>
                </div>
                <Divider />
                <p className={styles.noticeText}>
                  {intl["structured.accumulator.subscribe.notice"]}
                </p>
                <>
                  <Button onClick={goBack} className={styles.btnLeft}>
                    {intl["button.cancel"]}
                  </Button>
                  <Button
                    loading={loading}
                    className={styles.btnRight}
                    disabled={!(value && !error)}
                    type="primary"
                    onClick={handleSubscribe}>
                    {intl["button.subscribe"]}
                  </Button>
                </>
              </div>
              <SnowballDetail />
            </div>
          </div>
          {showSafetyVerificationModal && (
            <ModalSafetyVerification
              confirmLoading={loading}
              visible={showSafetyVerificationModal}
              handleCancel={hideModal}
              onOk={placeOrder}
              businessType={-1}
              isCustomerValidate={true}
            />
          )}
          {openResultModal && (
            <ModalSubscribeResult
              isAccu={false}
              isVisible={openResultModal}
              order={order}
              cancelModal={cancelResultModal}
              onRefresh={onRefresh}
            />
          )}
          {openSuspendModal && (
            <ModalSuspend isVisible={openSuspendModal} cancelModal={cancelSuspendModal} />
          )}
        </Spin>
      </section>
      <Disclaimer />
    </>
  );
};

export const PageSnowSubscribe = React.memo(SnowSubscribe);
