"use client";
import React, { useEffect } from "react";
import styles from "../../login/Login.module.less";
import { useRouter } from "next/router";
import { getConfigInfo, logout } from "@aspen/services";
import {
  clearCookies,
  clearLocalStorage,
  i18nUtil,
  reportGaInfo,
  ASSETANALYSIS,
  DEVICE_ID,
  ERROR,
  HOME_PATH,
  IS2FA,
  SHOW_RECAPTCHA,
  TRADE_PATH_DEFAULT_PATH,
  isClient
} from "@aspen/libs";
import { WithCoinList, EmailCodeLayout, message, EmailCodeBaseProps } from "@aspen/ui";
import LoginLayout from "../../login/LoginLayout";
import { clearAllWallet } from "@aspen/store/slices/WalletSlice";
import { clearAllYield } from "@aspen/store/slices/YieldSlice";
import { useAppDispatch } from "@aspen/store/hooks";
import LoginWidgets from "../../login/LoginWidgets";
import { LoadAwscJS } from "../../login/LoadAwsc";

interface IProps extends EmailCodeBaseProps {
  fetchAllCoinList: () => void;
  unregisterWS: () => void;
}

const PortalLogin: React.FC<IProps> = (props: IProps) => {
  const intl = i18nUtil.t();
  const router = useRouter();

  const dispatch = useAppDispatch();

  // 处理退出登录 信息清除等
  const init = () => {
    const error = localStorage.getItem(ERROR);
    if (error) {
      message.error(intl[error]);
    }
    props.fetchAllCoinList();
    props.unregisterWS();
    window.ws = null;
    localStorage.getItem(DEVICE_ID) && reportGaInfo(3);

    // 退出登录接口
    logout().finally(() => {
      clearLocalStorage();
      clearCookies();
      // 清除redux的数据
      clearReduxData();
    });
  };

  // 清除redux的数据
  const clearReduxData = () => {
    dispatch(clearAllWallet());
    dispatch(clearAllYield());
  };

  // 获取配置信息，afsOpen 是否开启阿里云验证开关
  const getConfigData = () => {
    // 设置默认值为true，若接口有问题，不会导致登录接口显示缺乏参数 header
    localStorage.setItem(SHOW_RECAPTCHA, "true");
    localStorage.setItem(IS2FA, "true");
    // FIXME: 显示隐藏资产分析入口
    localStorage.setItem(ASSETANALYSIS, "false");

    getConfigInfo().then((res) => {
      // eslint-disable-next-line eqeqeq
      if (res.code == "0") {
        localStorage.setItem(SHOW_RECAPTCHA, res.data?.afsOpen);
        localStorage.setItem(IS2FA, res.data?.is2fa);
        // 资产分析
        localStorage.setItem(ASSETANALYSIS, res.data?.assetAnalysis);
      }
    });
  };

  useEffect(() => {
    init();
    getConfigData();

    // Prefetch the home page
    router.prefetch(HOME_PATH);
    router.prefetch(TRADE_PATH_DEFAULT_PATH);

    // 页面刷新时清除填充的sesstionStorage值
    if (isClient) {
      window.onbeforeunload = () => {
        sessionStorage.clear();
      };
    }
  }, []);

  return (
    <div className={styles.form}>
      <LoadAwscJS />
      <LoginWidgets />
    </div>
  );
};

export default LoginLayout(PortalLogin);
