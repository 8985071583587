import React from "react";
import { Spin } from "antd";
import clsx from "clsx";
import styles from "@aspen/theme/Strategy.module.less";
import { IStrategyDetail, COMMON_CACHE_PRIORTY_KEYS } from "@aspen/model";
import { i18nUtil } from "@aspen/libs";
import { useCachePriorityData } from "@aspen/hooks";
import { StrategyItem, Disclaimer } from "../../index";

const Strategy: React.FC = () => {
  console.log("render Strategy");
  const intl = i18nUtil.t();
  const title = intl["strategies.title"];

  //@ts-ignore
  const { data: strategyListData, isLoading } = useCachePriorityData<Array<IStrategyDetail>>(
    COMMON_CACHE_PRIORTY_KEYS.strategies
  );

  return (
    <div className={clsx(styles.savings, "customer")}>
      <div className="customerTop">
        <div className="top">
          <p className="title">{title}</p>
        </div>
      </div>
      <Spin spinning={isLoading}>
        <div className="section">
          <div className={styles.strategy} style={{ marginTop: "34px" }}>
            <div className={styles.strategyList}>
              {strategyListData?.map((item, index) => (
                <div className={styles.strategyListItem} key={index}>
                  <StrategyItem key={item.id} data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Spin>

      <Disclaimer />
    </div>
  );
};

export const PageStrategies = React.memo(Strategy);
