import React from "react";
import styles from "./Login.module.less";
import Image from "next/image";
import tips_red from "@aspen/assets/images/tips_red.svg";
import { i18nUtil } from "@aspen/libs";

type IProps = {
  errorText: string | null;
};

const ErrorTip: React.FC<IProps> = (props: IProps) => {
  const { errorText } = props;
  const intl = i18nUtil.t();
  if (!errorText) {
    return null;
  }
  return (
    <div className={styles.errorBox}>
      <Image unoptimized alt="" width={16} height={16} src={tips_red} />
      <span className={styles.errorText}>{intl[errorText] ?? errorText}</span>
    </div>
  );
};

export default ErrorTip;
