import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { Spin, Breadcrumb, Row, Col, Button, Table, Modal, Divider } from "antd";
import styles from "@aspen/theme/Savings.module.less";
import { myAutoInvestHistoryList } from "@aspen/services";
import {
  numberToThousands,
  decimalPointNoMoreX,
  convertUSD2USDC,
  transformTime,
  resetRate,
  computeNumber,
  i18nUtil,
  reportEvent,
  GA_EVENT_NAME,
  GA_EVENT_TAG,
  YIELD_PATHS
} from "@aspen/libs";
import { IAutoProductsHistory, AUTOMATICE_INVESTMENT_HISTORY } from "@aspen/model";
import { WithEmptyContent } from "@aspen/ui";

function DcaHistory() {
  const router = useRouter();
  const intl = i18nUtil.t();
  const loadMore = intl["button.loadMore"];
  const location_text: string = intl["kyc.certify.location"];
  const date_text = intl["column.date"];
  const name_text = intl["column.name"];
  const status_text = intl["column.status"];
  const details_text = intl["column.details"];
  const denomination_text: string = intl["savings.automatic.modal.denomination"];
  const price_text: string = intl["savings.automatic.modal.price"];
  const amount_text: string = intl["savings.automatic.modal.amount"];
  const breadcrumb_text: string = intl["savings.automatic.my.title"];
  const breadcrumb2_text: string = intl["savings.automatic.history.btn"];
  const confirmTime_text: string = intl["savings.automatic.history.Confirm.time"];
  const TxID_text: string = intl["savings.automatic.history.TxID"];

  const contractsId = router?.query?.id?.toString();

  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [currentInfo, setCurrentInfo] = useState<IAutoProductsHistory>();
  useEffect(() => {
    getInitData();
  }, []);
  // 初始化数据,
  const getInitData: () => void = async () => {
    const param = {
      limit: 10,
      nextToken: nextToken,
      contractId: contractsId
    };
    setLoading(true);
    myAutoInvestHistoryList(param)
      .then((res) => {
        if (res?.code == "0") {
          setDataSource(
            dataSource.length > 0
              ? dataSource.concat(res.data?.investmentOperateLogs)
              : res.data?.investmentOperateLogs
          );
          setNextToken(res.data?.nextToken);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: date_text,
      dataIndex: "modified",
      render: (record) => <span>{transformTime(record)}</span>
    },
    {
      title: name_text,
      dataIndex: "contractName",
      render: (value: string, record) => (
        <span>{record.type == 1 ? value : getProductName(record)}</span>
      ) // TODO: 此处数据格式再any分支处理
    },
    {
      title: status_text,
      dataIndex: "status",
      render: (record) => <span>{intl[AUTOMATICE_INVESTMENT_HISTORY[record]]}</span>
    },
    {
      title: details_text,
      render: (record, row) => (
        <Button className={styles.actionBtn} onClick={() => getRateInfo(row)}>
          {intl["button.view"]}
        </Button>
      )
    }
  ];

  const getProductName = (record) => {
    if (record?.investmentProportion?.currencyProportion) {
      return `${Object.keys(record?.investmentProportion?.currencyProportion).join(",")} ${
        intl["savings.automatic.modal.title"]
      }`;
    }
    return "-";
  };

  //重构数据结构 获取每个币种的百分比 @后端接口 可优化
  const getRateInfo = (info) => {
    const newInfo = { ...info };
    const rateList = resetRate(newInfo?.investmentProportion?.currencyProportion);
    newInfo.subscribeDetails?.forEach((item) => {
      const currentPercent = rateList?.find((e) => e?.denomination === item?.currency)?.percent;
      const currentRate = rateList?.find((e) => e?.denomination === item?.currency)?.rate;
      item.percent = currentPercent;
      item.rate = currentRate;
    });
    setCurrentInfo(newInfo);
    handleChangeVisible();

    reportEvent({
      joinedTag: GA_EVENT_TAG.Modal,
      moduleName: GA_EVENT_NAME.dca.historyDetail,
      detailParams: {
        id: info?.id,
        productId: info?.productId,
        contractId: info?.contractId,
        contractName: info?.contractName,
        payCurrency: info?.payCurrency,
        type: info?.type
      }
    });
  };

  const handleChangeVisible = () => {
    setVisible(!visible);
  };

  return (
    <section className={styles.investmentHistory}>
      <Breadcrumb>
        <Breadcrumb.Item>{location_text}: </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link href={YIELD_PATHS.YIELD_MY_INVESTMENT_PLAN}>{breadcrumb_text}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{breadcrumb2_text}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.container}>
        <p className={styles.title}>{breadcrumb2_text}</p>
        <WithEmptyContent showEmpty={true}>
          <Spin spinning={loading}>
            <Table
              className={styles.table}
              rowKey={(record) => record?.id}
              bordered={false}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
            {nextToken && dataSource.length >= 10 && (
              <div className={styles.loadMore} onClick={() => getInitData()}>
                {loadMore}
              </div>
            )}
          </Spin>
        </WithEmptyContent>
        <Modal
          wrapClassName={styles.modal}
          title={intl["savings.automatic.modal.title"]}
          open={visible}
          width={600}
          destroyOnClose={true}
          footer={null}
          onCancel={handleChangeVisible}>
          <div className={styles.automaticModal}>
            <p style={{ marginBottom: 16 }}>{intl["savings.automatic.modal.amount"]}</p>
            <p className={styles.amountSum}>
              {numberToThousands(
                decimalPointNoMoreX(
                  computeNumber(currentInfo?.amount ?? 0, "+", currentInfo?.fee ?? 0)?.result
                )
              )}{" "}
              {convertUSD2USDC(currentInfo?.payCurrency ?? "")}
            </p>
            {currentInfo?.status == 3 && ( //PROCESSING(2)：处理中，CONFIRMED(3)：已确认 ，FAILED(11)：失败
              <div>
                <Divider />
                <div className={styles.rateArea}>
                  <Row className={styles.topTitle}>
                    <Col span={8}>
                      <p>{denomination_text}</p>
                    </Col>
                    <Col span={8}>
                      <p>{price_text}</p>
                    </Col>
                    <Col span={8}>
                      <p>{amount_text}</p>
                    </Col>
                  </Row>
                  {currentInfo?.subscribeDetails &&
                    currentInfo?.subscribeDetails?.length > 0 &&
                    currentInfo?.subscribeDetails?.map((item, index) => {
                      return (
                        <Row key={index} className={styles.topContent}>
                          <Col span={8}>
                            <p>{`${item.currency} (${item.percent ?? "-"})`}</p>
                          </Col>
                          <Col span={8}>
                            <p>
                              {
                                //SUBSCRIBE_START(0):开始认购,SUBSCRIBE_CONFIRMED(1):认购完成,SUBSCRIBE_FAILED(2):认购失败
                                //DISPENSE_START(10):开始分配,DISPENSE_CONFIRMED(11):分配完成,DISPENSE_FAILED(12):分配失败
                                // 后台产品讨论 此处均价前端计算: （总价加+费率） *比率 /币种数量 ， 不取avg prive
                                item.status == 11
                                  ? `${numberToThousands(
                                      decimalPointNoMoreX(
                                        item.amount
                                          ? ((currentInfo?.amount + currentInfo?.fee) * item.rate) /
                                              item.amount
                                          : 0
                                      )
                                    )} ${convertUSD2USDC(item.quoteCurrency)}`
                                  : "-"
                              }
                            </p>
                          </Col>
                          <Col span={8}>
                            <p>
                              {item.status == 11
                                ? `${numberToThousands(
                                    decimalPointNoMoreX(item?.amount)
                                  )} ${convertUSD2USDC(item.currency)}`
                                : "-"}
                            </p>
                          </Col>
                        </Row>
                      );
                    })}
                </div>
              </div>
            )}
            <Divider />
            <div className={styles.bottomArea}>
              <p>
                <span className={styles.bottonInfoKey}>{confirmTime_text}</span>
                <span className={styles.bottonInfoValue}>
                  {transformTime(currentInfo?.modified)}
                </span>
              </p>
              {/* <p>
                <span className={styles.bottonInfoKey}>{`Fee(${(currentInfo?.feeRate * 100).toFixed(
                  2
                )}%)`}</span>
                <span className={styles.bottonInfoValue}>
                  {decimalPointNoMoreX(currentInfo?.fee)}{" "}
                  {convertUSD2USDC(currentInfo?.payCurrency)}
                </span>
              </p> */}
              <p>
                <span className={styles.bottonInfoKey}>{TxID_text}</span>
                <span className={styles.bottonInfoValue}>{currentInfo?.contractId}</span>
              </p>
            </div>
          </div>
        </Modal>
      </div>
    </section>
  );
}

export const PageDcaHistory = DcaHistory;
