import React, { useEffect, useState } from "react";
import { Table, Row, Col, Spin, Button, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import styles from "@aspen/theme/History.module.less";
import {
  transformTime,
  decimalPointNoMoreX,
  i18nUtil,
  numberToThousands,
  WALLET_PATHS
} from "@aspen/libs";
import { getApplicationList } from "@aspen/services";
import {
  IApplicationList,
  APPLICATION_TYPE,
  APPLICATION_REVIEWER,
  APPLICATION_STATUS
} from "@aspen/model";
import { WithEmptyContent } from "@aspen/ui";

const ApplicationHistory = () => {
  const intl = i18nUtil.t();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<IApplicationList[]>();
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [offset, setOffset] = useState<number>(0);

  const router = useRouter();

  const loadMore = intl["button.loadMore"];

  useEffect(() => {
    fetchData(0);
  }, []);

  const columns = [
    {
      title: intl["column.date"],
      dataIndex: "startTime",
      render: (value: number) => <span>{transformTime(value)}</span>
    },
    {
      title: intl["column.type"],
      dataIndex: "flowType",
      render: (type: string) => (
        <div style={{ textTransform: "capitalize" }}>{intl[APPLICATION_TYPE[type]]}</div>
      )
    },
    {
      title: intl["column.currency"],
      render: (record: IApplicationList) =>
        record?.detail && <span>{JSON.parse(record.detail)?.currency}</span>
    },
    {
      title: intl["column.amount"],
      render: (record: IApplicationList) =>
        record?.detail && (
          <span>{numberToThousands(decimalPointNoMoreX(JSON.parse(record.detail)?.amount))}</span>
        )
    },
    {
      title: intl["column.reviewer"],
      dataIndex: "approveType",
      render: (type: string) => <div>{intl[APPLICATION_REVIEWER[type]]}</div>
    },
    {
      title: intl["column.email"],
      dataIndex: "approveEmail"
    },
    {
      title: intl["column.status"],
      dataIndex: "status",
      render: (type: string) => <div>{intl[APPLICATION_STATUS[type]]}</div>
    },
    {
      title: intl["column.action"],
      render: (record: IApplicationList) => (
        <div>
          {record?.status === 3 && (
            <Button
              className={styles.actionBtn}
              size="small"
              onClick={() =>
                toHistoryPage(
                  record.flowType == "OTC_WITHDRAW" ? "USD" : "crypto",
                  record.flowType == "OTC_WITHDRAW" ? true : false
                )
              }>
              {intl["button.view"]}
            </Button>
          )}
        </div>
      )
    }
  ];

  const fetchData = (newOffset: number) => {
    setLoading(true);
    const params = {
      offset: newOffset,
      limit: 10
    };
    getApplicationList(params)
      .then((res) => {
        if (res?.code == "0") {
          setDataSource(showLoadMore ? dataSource?.concat(res?.data) : res?.data);
          setShowLoadMore(res?.data.length < 10 ? false : true);
          setOffset(offset);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const toHistoryPage = (currency: string, fiat: boolean) => {
    router.push({
      pathname: WALLET_PATHS.WALLET_HISTORY,
      query: {
        type: 2,
        currency,
        fiat
      }
    });
  };

  return (
    <section className={styles.history + " " + "customer"}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24} style={{ display: "flex", alignItems: "center" }}>
            <p className="title">{intl["wallet.withdraw.application.title"]}</p>
            <Tooltip title={intl["wallet.withdraw.application.title.tips"]}>
              <InfoCircleOutlined
                style={{
                  paddingLeft: "10px",
                  fontSize: "12px"
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      </div>
      <div className={styles.tableWrap}>
        <div className="section" style={{ width: "100%", marginTop: 0 }}>
          <div className={styles.history} style={{ marginTop: 0 }}>
            <Spin spinning={loading}>
              <div className={styles.historyArea}>
                <WithEmptyContent showEmpty={true}>
                  <Table
                    className={styles.table}
                    bordered={false}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                  />
                </WithEmptyContent>

                {showLoadMore && (
                  <div className={styles.loadMore} onClick={() => fetchData(offset + 10)}>
                    {loadMore}
                  </div>
                )}
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </section>
  );
};

export const PageApplicationDetail = React.memo(ApplicationHistory);
