import dynamic from "next/dynamic";

export const ModalSecondarySubscribe = dynamic(() => import("./ModalSecondarySubscribe"), {
  ssr: false
});

export const ModalShares = dynamic(() => import("./ModalShares"), {
  ssr: false
});

export const ModalSharesResult = dynamic(() => import("./ModalSharesResult"), {
  ssr: false
});

export const ModalVentureSubscribeResult = dynamic(() => import("./ModalSubscribeResult"), {
  ssr: false
});

export const ModalVenture = dynamic(() => import("./ModalVenture"), {
  ssr: false
});
export * from "./Primary";

export * from "./Secondary";
