import React from "react";
import { Area, AreaConfig } from "@ant-design/plots";
import { ShapeAttrs } from "@antv/g-base";
import { isEqual } from "lodash-es";
import { IAssetSituationalData } from "@aspen/model";
import { validateChartData } from "@aspen/libs";
import { EmptyCharts } from "./emptyCharts/EmptyCharts";
import { AxisLineCfg } from "@antv/g2/lib/dependents";
import { TooltipAttr } from "@antv/g2plot/lib/types/attr";
interface IProps extends Partial<AreaConfig> {
  themeColor?: string;
  areaStartColor?: string;
  areaEndColor?: string;
  xField: string;
  yField: string;
  list: any[];
  xTickCount?: number;
  yTickCount?: number;
  xLabelStyle?: ShapeAttrs;
  yLabelStyle?: ShapeAttrs;
  yGridLineStyle?: ShapeAttrs;
  lineWidth?: number;
  yAxisPosition?: "top" | "bottom" | "right" | "left";
  yAxisFormatter?: (text: string) => {};
  xAxisFormatter?: (text: string) => {};
  tooltipValueFormatter?: (text: string) => string;
  tooltipFormatter?: TooltipAttr;
  crosshairsType?: "x" | "y" | "xy";
  xAxisLine?: AxisLineCfg;
  yAxisLine?: AxisLineCfg;
  colorField?: string;
  xRange?: number[];
  xOffset?: number;
}

interface IState {}

/**
 * 折线图
 * 关于配置概念可以参考
 * https://charts.ant.design/zh/examples/area/basic#basic
 * https://antv.vision/old-site/g2/doc/tutorial/start/axis.html
 */
export default class LineChart extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const {
      themeColor,
      areaStartColor,
      areaEndColor,
      xField,
      yField,
      list = [],
      xTickCount,
      yTickCount,
      lineWidth,
      xLabelStyle = {},
      yLabelStyle = {},
      yAxisPosition,
      crosshairsType,
      xAxisLine,
      yAxisLine,
      xRange,
      xOffset,
      tooltip,
      ...otherProps
    } = this.props;
    const { data } = validateChartData<IAssetSituationalData>(list, xField, yField);
    const config: AreaConfig & React.RefAttributes<unknown> = {
      data: data,
      tooltip: {
        showTitle: false,
        enterable: true,
        showContent: true,
        formatter: (datum) => {
          return {
            name: datum?.[xField],
            value: this.props?.tooltipValueFormatter
              ? this.props?.tooltipValueFormatter(datum[yField])
              : datum?.[yField]
          };
        },
        domStyles: {
          "g2-tooltip": {
            backgroundColor: "rgba(47, 51, 66, 0.91)",
            boxShadow: "none",
            fontFamily: "Common-Regular"
          },
          "g2-tooltip-list-item": { color: "#ffffff" }
        },
        showCrosshairs: !!crosshairsType,
        crosshairs: {
          type: crosshairsType || "x",
          line: {
            style: {
              stroke: "#ffffff",
              lineWidth: 1,
              lineDash: [4, 5],
              strokeOpacity: 0.18,
              cursor: "pointer"
            }
          }
        },
        ...tooltip
      },
      padding: "auto",
      appendPadding: [0, 5, 0, 0],
      xField: xField,
      yField: yField,
      limitInPlot: false,
      smooth: true,
      startOnZero: false,
      theme: {
        defaultColor: themeColor,
        fontFamily: "Common-Regular"
      },
      line: {
        color: themeColor,
        style: { lineWidth: lineWidth || 2 }
      },
      areaStyle: {
        fill: `l(90) 0:${areaStartColor} 1:${areaEndColor}`
      },
      // @ts-ignore
      xAxis: {
        tickCount: xTickCount || 10,
        line: xAxisLine || null,
        tickLine: null,
        range: xRange,
        label: {
          formatter: this.props?.xAxisFormatter ?? null,
          style: {
            fontSize: 13,
            lineHeight: 16.5,
            fill: "#ffffff",
            fillOpacity: 0.45,
            cursor: "pointer",
            fontFamily: "Common-Regular",
            ...xLabelStyle
          },
          offset: xOffset || 30
        }
      },
      // @ts-ignore
      yAxis: {
        position: yAxisPosition,
        line: yAxisLine || null,
        tickCount: yTickCount || 8,
        label: {
          formatter: this.props?.yAxisFormatter ?? null,
          style: {
            fontSize: 13,
            lineHeight: 16.5,
            fill: "#ffffff",
            fillOpacity: 0.45,
            cursor: "pointer",
            fontFamily: "Common-Regular",
            ...yLabelStyle
          }
        },
        grid: {
          line: {
            style: {
              fontFamily: "Common-Regular",
              fontWeight: 400,
              lineWidth: 1,
              lineDash: [4, 5],
              color: "#ffffff",
              fontSize: 14,
              strokeOpacity: 0.05
            }
          }
        }
      },
      ...otherProps
    };
    return <>{data?.length ? <Area {...config} /> : <EmptyCharts />}</>;
  }

  componentDidMount() {}

  componentWillUnmount() {}
  shouldComponentUpdate(nextProps: Readonly<IProps>): boolean {
    return (
      !isEqual(nextProps.list, this.props.list) || nextProps.themeColor !== this.props.themeColor
    );
  }
}
