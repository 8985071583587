import React from "react";
import { Table, Row, Col, Tabs, Spin, Radio, Button, RadioChangeEvent, Tooltip } from "antd";
import { InfoCircleOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { withRouter } from "next/router";
import styles from "@aspen/theme/History.module.less";
import {
  IFlexibleHistory,
  IInjectProps,
  IResOrders,
  IStrategyDetail,
  IStrategyOrderDetail,
  IStructuredItem,
  ISymbolPrecisions,
  IVentureListItem,
  EAccumulatorOrderStatus,
  ACCUMULATOR_ORDER_STATUS,
  Ventures_Shares_Reflect,
  SNOWBALL_ORDER_STATUS,
  SNOWBALL_PRODUCT_STATUS,
  SNOWBALL_VIEW_DESC_STATUS,
  SNOWBALL_SETTLED_STATUS,
  SPOT_ORDER_STATUS,
  SPOT_ORDER_TYPE,
  STRUCTURED_LOG_STATUS,
  OPERARTOR_TYPE,
  STRATEGY_ORDER_TYPE,
  FLEXIBLE_YIELD_OPERATE_TYPE
} from "@aspen/model";
import {
  yieldHistory,
  ordersOpen,
  getStrategyOrderList,
  fetchOrderDetail,
  getStrategyOrderDetail,
  cancelStrategy,
  getVentureOrderList,
  fetchStructuredHistory,
  fetchAccumulatorHistory,
  getStructuredSpecificList,
  fetchSnowballHistory
} from "@aspen/services";
import { message, WithEmptyContent } from "@aspen/ui";
import {
  HISTORY_PATHS,
  USER_ROLE_POWER,
  USER_AUTH,
  PRODUCT_TYPES,
  YIELD_TYPES,
  STRUCTURED_TYPES,
  GA_EVENT_NAME,
  REFERENCE_CURRENCY,
  transformTime,
  transformDate,
  decimalPointNoMoreX,
  convertUSD2USDC,
  numberToThousands,
  reportEvent,
  i18nUtil,
  processAPR,
  decimalPointNoMoreXNoFill,
  showStrike,
  getQueryValue,
  getBuyCoinText,
  getSellCoinText,
  isClient
} from "@aspen/libs";
import {
  FixedYieldHistory,
  ModalOrderDetail,
  ModalStrategyDetail,
  StakingYieldHistory,
  StructuredHeader,
  ModalCancelStrategy
} from "../../index";
import { unwatchFile } from "fs";

const defaultProps = {
  showViewAll: true,
  dataSource: [],
  marketSymbolsList: [],
  offlineSymbolsList: []
};

type IProps = {
  activeSymbolPrecisions: ISymbolPrecisions;
  offlineSymbolsList: ISymbolPrecisions;
} & IInjectProps &
  Partial<typeof defaultProps>;

interface IState {
  expandedRowKeys: string[];
  dataSource: (
    | IResOrders
    | IFlexibleHistory
    | IStructuredItem
    | IStrategyDetail
    | IVentureListItem
  )[];
  currentTab: string;
  currentStructuredTab: string;
  loading: boolean;
  nextToken: string;
  orderDetails: IResOrders[];
  showDetailModal: boolean;
  yieldType: string;
  currentStrateyDetail: IStrategyOrderDetail | null;
  strategyDetailModalVisible: boolean;
  confirmModalVisible: boolean; // 取消策略modal
  cancelStrategyId: string;
  cancelEmail: string;
  cancelType: number;
  realTimePrice: number;
}

class History extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      expandedRowKeys: [],
      dataSource: [],
      currentTab: PRODUCT_TYPES.spot,
      currentStructuredTab: STRUCTURED_TYPES.dual,
      loading: true,
      nextToken: "",
      orderDetails: [],
      showDetailModal: false,
      yieldType: YIELD_TYPES.flexible, // flexible: 活期 fixed: 定期 staking: Staking
      currentStrateyDetail: null,
      strategyDetailModalVisible: false,
      confirmModalVisible: false,
      cancelStrategyId: "",
      cancelEmail: "",
      cancelType: 0,
      realTimePrice: 0
    };
    this.handleClickOnView = this.handleClickOnView.bind(this);
  }

  componentDidMount(): void {
    this.refreshTab();
  }

  refreshTab = () => {
    const defaultTab =
      this.props?.router?.query?.id?.toString() ||
      (isClient && getQueryValue("id")) ||
      PRODUCT_TYPES.spot;
    const defaultStructuredTab =
      this.props?.router?.query?.structureType?.toString() ||
      (isClient && getQueryValue("structureType")) ||
      STRUCTURED_TYPES.dual;
    const defaultExpandedRowKeys =
      [this.props?.router?.query?.orderId?.toString() ?? ""] ||
      (isClient && [getQueryValue("orderId")]) ||
      [];
    this.setState(
      {
        currentTab: defaultTab,
        currentStructuredTab: defaultStructuredTab,
        expandedRowKeys: defaultExpandedRowKeys
      },
      this.getCurrentData
    );
  };

  //建立colums
  setColumns = () => {
    const { realTimePrice } = this.state;
    const intl = i18nUtil.t();
    const date: string = intl["column.date"];
    // const pair: string = intl["column.pair"];
    const side: string = intl["column.side"];
    const amount: string = intl["column.amount"];
    const fee: string = intl["column.fee"];
    const denomination: string = intl["column.denomination"];
    const type: string = intl["column.type"];
    const shares: string = intl["column.shares"];
    const status: string = intl["column.status"];
    const operator: string = intl["column.operator"];
    const projectName: string = intl["column.project.name"];
    const subscription: string = intl["savings.history.subscription"];
    const redemption: string = intl["savings.history.redemption"];
    const success: string = intl["savings.history.success"];
    const failed: string = intl["savings.history.failed"];
    const pending: string = intl["savings.history.pending"];
    const column_order_total = intl["column.order.total"];
    const column_filled_total = intl["column.filled.total"];
    const averagePrice = intl["column.average.price"];
    const column_filled_amount = intl["column.filled.amount"];
    const column_order_amount = intl["column.order.amount"];
    const column_order_price = intl["column.order.price"];
    const column_status = intl["column.status"];
    const column_operator = intl["column.operator"];
    const column_action = intl["column.action"];
    const column_type = intl["column.type"];
    const column_percentage = intl["column.percentage"];
    const button_view = intl["button.view"];
    const button_cancel = intl["button.cancel"];
    const subscriptionTime = intl["column.subscription.time"];
    const product = intl["column.product"];
    const targetPrice = intl["column.target.price"];
    const initialPrice = intl["column.initial.price"];
    const strikePrice = intl["column.strike.price"];
    const koPrice = intl["column.ko.price"];
    const receiptTime = intl["column.receiptTime"];
    const returns = intl["column.returns"];
    const expiry = intl["column.expiry"];
    const apr = intl["column.apr"];
    const subscriptionText = intl["column.subscription"];
    const rewards = intl["column.rewards"];
    const settlement = intl["column.settlement"];
    const target = intl["column.target"];
    const timePrice = intl["dual.real.time.price"];
    const kIPrice = intl["snowball.columns.kIPrice"];
    const estAPR = intl["snowball.columns.estAPR"];
    const kOPrice = intl["snowball.columns.kOPrice"];
    const snowballReturn = intl["snowball.columns.returns"];

    const { activeSymbolPrecisions, offlineSymbolsList } = this.props; //useAppSelector(marketSymbolsList);

    // 查找symbol 若已下架，则在下架的列表中找
    const currentSymbolPricesionInfo = (symbol) => {
      return activeSymbolPrecisions?.[symbol] ?? offlineSymbolsList?.[symbol];
    };

    const spotColumns = [
      {
        title: date,
        //dataIndex: "filledTime", //此处取成交时间，成交了才记录到表，所以成交时间filledTime小于创建时间created
        dataIndex: "created",
        render: (value: number) => <span>{transformTime(value)}</span>
      },
      /*{
        title: pair,
        dataIndex: "pair",
        render: (text, row) => {
          return <span>{convertUSD2USDC(`${row.baseCurrency}/${row.quoteCurrency}`)}</span>;
        }
      },*/
      {
        title: column_type,
        dataIndex: "orderType",
        width: 80,
        render: (_, record: IResOrders) =>
          record?.businessType === "interest_trading" ? (
            intl["column.yield.auto.purchase"]
          ) : (
            <span className="capitalize">{intl[SPOT_ORDER_TYPE[record?.orderType]]}</span>
          )
      },
      {
        title: side,
        dataIndex: "side",
        render: (_, record: IResOrders) => (
          <span className="capitalize">
            {record?.side == "buy"
              ? getBuyCoinText(`${record?.baseCurrency}_${record?.quoteCurrency}`)
              : getSellCoinText(`${record?.baseCurrency}_${record?.quoteCurrency}`)}
          </span>
        )
      },
      {
        title: (
          <div>
            {averagePrice} <br /> {column_order_price}
          </div>
        ),
        align: "right",
        dataIndex: "filledPrice",
        render: (_, record) => (
          <>
            {record?.orderType == "limit" && (
              <div style={{ minWidth: "85px" }}>
                {numberToThousands(
                  decimalPointNoMoreX(
                    record?.averageTradePrice ?? "--",
                    currentSymbolPricesionInfo(record?.symbol)?.symbolPrecision
                  )
                )}
                <br />
                {numberToThousands(
                  decimalPointNoMoreX(
                    record?.price,
                    currentSymbolPricesionInfo(record?.symbol)?.symbolPrecision
                  )
                )}
              </div>
            )}
            {record?.orderType == "market" && (
              <>
                {numberToThousands(
                  decimalPointNoMoreX(
                    record?.averageTradePrice ?? "--",
                    currentSymbolPricesionInfo(record?.symbol)?.symbolPrecision
                  )
                )}
                <br />
                --
              </>
            )}
          </>
        )
      },
      {
        title: (
          <>
            {column_filled_amount}
            <br /> {column_order_amount}
          </>
        ),
        align: "right",
        dataIndex: "filledQty",
        render: (filledQty, record) => (
          <>
            {numberToThousands(
              decimalPointNoMoreX(
                record?.filledQty,
                currentSymbolPricesionInfo(record?.symbol)?.tradeVolumePrecision
              )
            )}
            <br />
            {numberToThousands(
              decimalPointNoMoreX(
                record?.baseQty,
                currentSymbolPricesionInfo(record?.symbol)?.tradeVolumePrecision
              )
            )}
          </>
        )
      },
      {
        title: fee,
        dataIndex: "fee",
        align: "right",
        render: (_, record) => {
          return (
            <span>
              {/* fee展示8位 */}
              {numberToThousands(
                decimalPointNoMoreX(
                  record?.fee ?? "--",
                  currentSymbolPricesionInfo(record?.symbol)?.volumePrecision
                )
              )}
              <br />
              {convertUSD2USDC(record?.feeCurrency)}
            </span>
          );
        }
      },
      {
        title: column_status,
        width: 100,
        dataIndex: "orderStatus",
        render: (orderStatus: string) => (
          <span className="capitalize">{intl[SPOT_ORDER_STATUS[orderStatus]] ?? orderStatus}</span>
        )
      },
      {
        title: column_operator,
        width: 135,
        dataIndex: "operatorType", //操作人类别  0本人操作，1子账号，2代理商
        render: (value: number) => <span>{intl[OPERARTOR_TYPE[value]] || "--"}</span>
      },
      {
        title: (
          <div>
            {column_filled_total} <br /> {column_order_total}
          </div>
        ),
        align: "right",
        dataIndex: "total",
        render: (text, record) => {
          return (
            <>
              {numberToThousands(
                decimalPointNoMoreX(
                  record?.filledVolume,
                  currentSymbolPricesionInfo(`${record?.baseCurrency}_${record?.quoteCurrency}`)
                    ?.tradeVolumePrecision
                )
              )}
              <br />
              <span>
                {record?.orderType == "limit" && (
                  <>
                    {numberToThousands(
                      decimalPointNoMoreX(
                        record?.price * record?.baseQty,
                        currentSymbolPricesionInfo(
                          `${record?.baseCurrency}_${record?.quoteCurrency}`
                        )?.tradeVolumePrecision
                      )
                    )}
                    {/* <span style={{ paddingLeft: 4 }}>{convertUSD2USDC(record?.quoteCurrency)}</span> */}
                  </>
                )}
                {record?.orderType == "market" && "--"}
              </span>
            </>
          );
        }
      },
      {
        title: column_action,
        width: 75,
        align: "center",
        dataIndex: "orderId",
        render: (orderId) => (
          <div
            className={styles.action}
            onClick={() => {
              this.handleClickOnView(orderId);
            }}>
            {button_view}
          </div>
        )
      }
    ];
    const yieldColumns = [
      {
        title: date,
        dataIndex: "created",
        render: (value: number) => <span>{transformTime(value)}</span>
      },
      // {
      //   title: projectName,
      //   dataIndex: "productId"
      // },
      {
        title: denomination,
        dataIndex: "currency",
        render: (value: string) => convertUSD2USDC(value)
      },
      {
        title: type,
        dataIndex: "operateType",
        render: (value: number) => intl[FLEXIBLE_YIELD_OPERATE_TYPE[value]]
      },
      {
        title: amount,
        dataIndex: "amount",
        render: (value: number) => numberToThousands(decimalPointNoMoreX(value))
      },
      {
        title: column_operator,
        dataIndex: "operatorType", //操作人类别  0本人操作，1子账号，2代理商
        render: (value: number) => <span>{intl[OPERARTOR_TYPE[value]] || "--"}</span>
      },
      {
        title: status,
        dataIndex: "status",
        render: (value: number) => (
          <span>{value === 2 ? pending : value === 3 ? success : failed}</span>
        )
      }
    ];
    const dualColumns = [
      {
        title: subscriptionTime,
        dataIndex: "created",
        render: (value: number) => transformTime(value)
      },
      {
        title: product,
        dataIndex: "productId",
        render: (value: string, record: IStructuredItem) => {
          const productName = value?.split(":")?.[0];
          const underlying = productName?.split("_")?.[0];
          const subscription = productName?.split("_")?.[1];
          return (
            <span>
              {underlying}-{convertUSD2USDC(subscription)} {""}
              {intl[`history.structured.${record?.type?.toString()?.toLocaleLowerCase()}`]}
            </span>
          );
        }
      },
      {
        title: () => (
          <div>
            <div>{initialPrice}</div>
            <div>{timePrice}</div>
          </div>
        ),
        dataIndex: "initialPrice",
        width: 130,
        render: (value: number, record: IStructuredItem) => (
          <>
            <div>{value ? showStrike(value, record?.quote, 6) : "--"}</div>
            <div>
              {realTimePrice && record?.underlying == "ETH" && record?.quote == "BTC"
                ? showStrike(realTimePrice, record?.quote, 6)
                : "--"}
            </div>
          </>
        )
      },
      {
        title: targetPrice,
        dataIndex: "strike",
        render: (value: number, record: IStructuredItem) => (
          <span>
            {value ? showStrike(value, record?.quote, record?.initialPrice ? 6 : 0) : "--"}
          </span>
        )
      },
      {
        title: (
          <>
            <span>{intl["column.expiry"]}</span>
            <Tooltip title={intl["structured.expiryDate.tips"]} style={{ width: 200 }}>
              <InfoCircleOutlined style={{ paddingLeft: "5px", cursor: "pointer" }} />
            </Tooltip>
          </>
        ),
        dataIndex: "expiry",
        render: (value: number) => <span>{transformTime(value)}</span>
      },
      {
        title: apr,
        dataIndex: "estimateApr",
        render: (value: number) => (value ? `${processAPR(value)}%` : "--")
      },
      {
        title: subscriptionText,
        dataIndex: "amount",
        render: (value: number, record: IStructuredItem) =>
          value ? (
            <span>{`${numberToThousands(value)} ${convertUSD2USDC(record?.subscription)}`}</span>
          ) : (
            "--"
          )
      },
      {
        title: rewards,
        dataIndex: "settleAmount",
        render: (value: number, record: IStructuredItem) =>
          value ? (
            <span>
              {`${numberToThousands(decimalPointNoMoreXNoFill(value))} ${convertUSD2USDC(
                record?.settleCurrency
              )}`}
            </span>
          ) : (
            "--"
          )
      },
      {
        title: settlement,
        dataIndex: "paymentTime",
        render: (value: number) => (value ? <span>{transformTime(value)}</span> : "--")
      },
      {
        title: target,
        dataIndex: "isReached",
        render: (value: boolean | null | undefined) =>
          value !== null && value !== undefined ? <span>{value ? "Y" : "N"}</span> : "--"
      },
      {
        title: status,
        dataIndex: "state",
        render: (value: string) => <span>{intl[STRUCTURED_LOG_STATUS[value]]}</span>
      },
      {
        title: operator,
        dataIndex: "operatorType",
        render: (value: string) => <span>{intl[OPERARTOR_TYPE[value]]}</span>
      }
    ];
    const accumulatorColumns = [
      {
        title: date,
        dataIndex: "created",
        render: (value: number) => transformTime(value)
      },
      {
        title: product,
        width: 100,
        dataIndex: "productId",
        render: (value: string, record) => (
          <span>
            {record?.underlying}-{convertUSD2USDC(record?.quote)}
          </span>
        )
      },
      {
        title: expiry,
        width: 100,
        dataIndex: "expiry",
        render: (value: number, record) => (
          <>
            <span>{transformDate(value)}</span>
            <div>
              ({record?.period} {intl["structured.accumulator.week"]})
            </div>
          </>
        )
      },
      {
        title: amount,
        dataIndex: "amount",
        render: (value: string) => (value ? numberToThousands(value) : "--")
      },
      {
        title: initialPrice,
        dataIndex: "initialPrice",
        render: (value: string) => (value ? numberToThousands(value) : "--")
      },
      {
        title: strikePrice,
        dataIndex: "strikePrice",
        render: (value: string, record) => (
          <>
            <span>
              {record?.priceUpdated ? numberToThousands(decimalPointNoMoreX(value, 2)) : ""}
            </span>
            <div style={{ fontSize: record?.priceUpdated ? 12 : 14 }}>
              {(Number(record?.strikePercent) * 100).toFixed(2)}%
            </div>
          </>
        )
      },
      {
        title: koPrice,
        dataIndex: "koPrice",
        render: (value: string, record) => (
          <>
            <span>
              {record?.priceUpdated ? numberToThousands(decimalPointNoMoreX(value, 2)) : ""}
            </span>
            <div style={{ fontSize: record?.priceUpdated ? 12 : 14 }}>
              {(Number(record?.koPercent) * 100).toFixed(2)}%
            </div>
          </>
        )
      },
      {
        title: column_operator,
        dataIndex: "operatorType",
        render: (value: string) => intl[OPERARTOR_TYPE[value]] || "--"
      },
      {
        title: returns,
        width: 150,
        dataIndex: "quote",
        render: (value: string, record) => {
          const isEnd = record?.state == EAccumulatorOrderStatus.settled; //已到期
          const isStop = isEnd && record?.period != record?.settledPeriod; //已终止
          const isRedeem = record?.state == "settling"; //赎回中

          return isRedeem || isEnd || isStop ? (
            <span>
              {numberToThousands(
                decimalPointNoMoreX(
                  Number(record?.totalRemainAmount ?? 0) + Number(record?.totalUnSettleAmount ?? 0),
                  2
                )
              ) ?? "--"}{" "}
              {convertUSD2USDC(value)}
              <br />
              {numberToThousands(decimalPointNoMoreX(record?.totalSettleAmount)) ?? "--"}{" "}
              {convertUSD2USDC(record?.underlying)}
            </span>
          ) : (
            <span>--</span>
          );
        }
      },
      {
        title: (
          <>
            <span>{receiptTime}</span>
            <Tooltip
              title={intl["structured.accumulator.receipt.time.tips"]}
              style={{ width: 200 }}>
              <InfoCircleOutlined style={{ paddingLeft: "5px", cursor: "pointer" }} />
            </Tooltip>
          </>
        ),
        width: 130,
        dataIndex: "paymentTime",
        render: (value: number, record) => {
          const isEnd = record?.state == EAccumulatorOrderStatus.settled; //已到期
          const isStop = isEnd && record?.period != record?.settledPeriod; //已终止
          return isEnd || isStop ? transformTime(value) : "--";
        }
      },
      {
        title: status,
        width: 100,
        dataIndex: "state",
        render: (value: string, record) => {
          const isEnd = value == EAccumulatorOrderStatus.settled; //已到期
          const isStop = isEnd && record?.period != record?.settledPeriod; //已终止
          const statusText = ACCUMULATOR_ORDER_STATUS[isStop ? "terminal" : value];
          return isStop || isEnd ? (
            <>
              <span>{intl[statusText]}</span>
              <Tooltip title={intl[`${statusText}.tips`]} style={{ width: 200 }}>
                <InfoCircleOutlined style={{ paddingLeft: "5px", cursor: "pointer" }} />
              </Tooltip>
            </>
          ) : (
            intl[statusText]
          );
        }
      }
    ];
    const snowballColumns = [
      {
        title: date,
        dataIndex: "created",
        render: (value: number) => transformTime(value)
      },
      {
        title: product,
        width: 100,
        dataIndex: "productId",
        render: (value: string, record) => (
          <span>
            {record?.underlying}-{convertUSD2USDC(record?.quote)}
          </span>
        )
      },
      {
        title: expiry,
        width: 150,
        dataIndex: "expiry",
        render: (value: number, record) => (
          <div>
            {transformDate(value)}({record?.period} {intl["structured.accumulator.week"]}){" "}
            <div>
              {SNOWBALL_SETTLED_STATUS.includes(record?.state)
                ? record?.expired
                  ? "Y"
                  : "N"
                : "--"}
            </div>
          </div>
        )
      },
      {
        title: estAPR,
        width: 100,
        dataIndex: "estimateApr",
        render: (value: number) => <span>{value ? (Number(value) * 100).toFixed(2) : "--"}%</span>
      },
      {
        title: initialPrice,
        dataIndex: "initialPrice",
        render: (value: string) => (value ? numberToThousands(value) : "--")
      },
      {
        title: kIPrice,
        dataIndex: "kiPrice",
        render: (value: string, record) => (
          <>
            <span>{value ? numberToThousands(decimalPointNoMoreX(value, 2)) : ""}</span>
            <div>{(Number(record?.kiPercent) * 100).toFixed(2)}%</div>
          </>
        )
      },
      {
        title: kOPrice,
        dataIndex: "koPrice",
        render: (value: string, record) => (
          <>
            <span>{value ? numberToThousands(decimalPointNoMoreX(value, 2)) : ""}</span>
            <div>{(Number(record?.koPercent) * 100).toFixed(2)}%</div>
          </>
        )
      },
      {
        title: strikePrice,
        dataIndex: "strikePrice",
        render: (value: string, record) => (
          <>
            <span>{value ? numberToThousands(decimalPointNoMoreX(value, 2)) : ""}</span>
            <div>{(Number(record?.strikePercent) * 100).toFixed(2)}%</div>
          </>
        )
      },
      {
        title: snowballReturn,
        width: 150,
        dataIndex: "amount",
        render: (value: string, record) => {
          return (
            <span>
              {numberToThousands(decimalPointNoMoreX(Number(value), 2)) ?? "--"}{" "}
              {convertUSD2USDC(record?.quote)}
              <br />
              {SNOWBALL_SETTLED_STATUS.includes(record?.state) ? (
                <>
                  {numberToThousands(
                    decimalPointNoMoreX(
                      record?.settleAmount,
                      record?.settleCurrency === REFERENCE_CURRENCY ? 2 : 8
                    )
                  ) ?? "--"}{" "}
                  {convertUSD2USDC(record?.settleCurrency)}
                </>
              ) : (
                <span>--</span>
              )}
            </span>
          );
        }
      },
      {
        title: column_operator,
        dataIndex: "operatorType",
        render: (value: string) => intl[OPERARTOR_TYPE[value]] || "--"
      },
      Table.EXPAND_COLUMN
    ];
    const strategyColumns = [
      {
        title: date,
        dataIndex: "businessDate",
        render: (value) => transformTime(value)
      },
      {
        title: projectName,
        dataIndex: "strategyName",
        width: 200
      },
      {
        title: denomination,
        dataIndex: "currency",
        render: (value) => convertUSD2USDC(value)
      },
      {
        title: (
          <>
            {amount}
            <br />
            {column_percentage}
          </>
        ),
        dataIndex: "amount",
        render: (value, record) => (
          <>
            {value ? <span>{numberToThousands(decimalPointNoMoreX(value))}</span> : "--"}
            <br />
            {record.redeemPercentage ? <span>{record.redeemPercentage}%</span> : "--"}
          </>
        )
      },
      {
        title: type,
        dataIndex: "type",
        render: (value) => (value == 0 ? subscription : redemption)
      },
      {
        title: column_operator,
        width: 120,
        align: "center",
        dataIndex: "operator", //操作人类别  0本人操作，1子账号，2代理商
        render: (value: number) => <span>{intl[OPERARTOR_TYPE[value]] || "--"}</span>
      },
      {
        title: column_status,
        dataIndex: "status",
        render: (value, record) => (
          <span>
            {record.effective === 1
              ? intl[STRATEGY_ORDER_TYPE[value]]
              : intl[STRATEGY_ORDER_TYPE[11]]}
          </span>
        )
      },
      {
        title: column_action,
        width: 200,
        render: (record) => (
          <div>
            <Button
              className={styles.actionBtn}
              style={{ marginRight: 12 }}
              size="small"
              onClick={() => {
                this.handleViewStrategyDetail(record);
              }}>
              {button_view}
            </Button>
            <Button
              size="small"
              style={{ width: 80, height: 28 }}
              className={
                record.effective === 0 ||
                (record?.status !== 0 && record?.status !== 1 && record?.status !== 3)
                  ? styles.disabledBtn
                  : styles.cancelBtn
              }
              disabled={
                // 扣款/冻结失败、待上传时前端可取消意向 若订单effective == 0 （失效）则按钮不可点
                record.effective === 0 ||
                (record?.status !== 0 && record?.status !== 1 && record?.status !== 3)
              }
              onClick={() => {
                this.handleCancelStrategy(record);
              }}>
              {button_cancel}
            </Button>
          </div>
        )
      }
    ];
    const ventureColumns = [
      {
        title: date,
        dataIndex: "businessDate",
        render: (value) => transformDate(value)
      },
      {
        title: projectName,
        dataIndex: "ventureName"
      },
      {
        title: denomination,
        dataIndex: "currency",
        render: (value) => convertUSD2USDC(value)
      },
      {
        title: type,
        dataIndex: "type",
        render: (value) => (value == 0 ? subscription : redemption)
      },
      {
        title: shares,
        dataIndex: "shares",
        render: (value) => <span>{intl[Ventures_Shares_Reflect[value]]}</span>
      },
      {
        title: amount,
        dataIndex: "amount",
        render: (value) => numberToThousands(decimalPointNoMoreX(value))
      }
    ];

    return {
      [PRODUCT_TYPES.spot]: spotColumns,
      [PRODUCT_TYPES.yield]: yieldColumns,
      [STRUCTURED_TYPES.dual]: dualColumns,
      [STRUCTURED_TYPES.accumulator]: accumulatorColumns,
      [STRUCTURED_TYPES.snowball]: snowballColumns,
      [PRODUCT_TYPES.strategies]: strategyColumns,
      [PRODUCT_TYPES.ventures]: ventureColumns
    };
  };

  handleClickOnView(orderId: string): void {
    const intl = i18nUtil.t();
    // 获取订单详情
    const param = {
      orderId: orderId
    };
    fetchOrderDetail(param)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            orderDetails: res?.data ?? []
          });
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({
          showDetailModal: true
        });
      });

    reportEvent({
      moduleName: GA_EVENT_NAME.myInvestment.tradeDetail,
      detailParams: { id: orderId }
    });
  }

  hideModal = () => {
    this.setState({
      orderDetails: [],
      showDetailModal: false
    });
  };

  //获取表格数据
  getCurrentData = () => {
    const { currentTab, currentStructuredTab } = this.state;
    let detailParams = {};
    if (currentTab === PRODUCT_TYPES.structured) {
      detailParams = { currentStructuredTab };
    }
    this.setState({ loading: true });
    reportEvent({
      moduleName: GA_EVENT_NAME.history.switchTab,
      detailParams: {
        tab: currentTab,
        ...detailParams
      }
    });
    switch (currentTab) {
      case PRODUCT_TYPES.spot:
        this.getSpotHistory();
        break;
      case PRODUCT_TYPES.yield:
        if (this.state.yieldType === YIELD_TYPES.flexible) {
          this.getYieldHistory();
        }
        break;
      case PRODUCT_TYPES.structured:
        if (currentStructuredTab === STRUCTURED_TYPES.dual) {
          this.getStructuredHistory();
        } else if (currentStructuredTab === STRUCTURED_TYPES.accumulator) {
          this.getAccumulatorHistory();
        } else {
          this.getSnowballHistory();
        }
        break;
      case PRODUCT_TYPES.strategies:
        this.getStrategyHistory();
        break;
      case PRODUCT_TYPES.ventures:
        this.getVentureHistory();
        break;
      default:
        break;
    }
  };

  //点击切换tab
  handleChangeTabs: (T: string) => void = (value: string) => {
    this.setState(
      {
        currentTab: value,
        nextToken: "",
        dataSource: []
      },
      () => {
        this.getCurrentData();
      }
    );
  };

  //dispatch
  getSpotHistory = () => {
    const { nextToken, dataSource } = this.state;
    const param = {
      limit: 10,
      orderQueryStatus: "history",
      fromOrderId: nextToken
    };
    ordersOpen(param)
      .then((res) => {
        if (res?.code == "0") {
          const nextToken =
            res?.data?.length < 10 ? "" : res.data[res?.data?.length - 1]?.orderId?.toString();
          this.setState({
            nextToken: nextToken ?? "", //再请求一遍才能判断下一页有没有数据
            dataSource: dataSource.concat(res?.data)
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  getYieldHistory = () => {
    const { nextToken, dataSource } = this.state;
    const param = {
      limit: 10,
      nextToken: nextToken
    };
    yieldHistory(param)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            dataSource:
              (nextToken ? dataSource.concat(res?.data?.rows ?? []) : res?.data?.rows) ?? [],
            nextToken: res?.data?.nextToken
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  _requestRealTimePrice = () => {
    getStructuredSpecificList({ underlying: "ETH", quote: "BTC", type: "BuyLow" })
      .then((res) => {
        const { code, data } = res || {};
        if (code == "0") {
          this.setState({
            realTimePrice: data?.[0]?.initialPrice || 0
          });
        }
      })
      .catch((err) => {
        console.info("err====>", err);
      });
  };

  getStructuredHistory = () => {
    const { nextToken, dataSource, realTimePrice } = this.state;

    //首次刷新||或者不存在eth-btc价格
    if (dataSource.length == 0 || !realTimePrice) {
      this._requestRealTimePrice();
    }

    const param = {
      limit: 10,
      nextToken
    };
    fetchStructuredHistory(param)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            dataSource:
              (nextToken ? dataSource.concat(res?.data?.rows ?? []) : res?.data?.rows) ?? [],
            nextToken: res?.data?.nextToken
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  getAccumulatorHistory = () => {
    const { nextToken, dataSource } = this.state;
    const param = {
      limit: 10,
      nextToken
    };
    this.setState({ loading: true });
    fetchAccumulatorHistory(param)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            dataSource:
              (nextToken ? dataSource.concat(res?.data?.rows ?? []) : res?.data?.rows) ?? [],
            nextToken: res?.data?.nextToken
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  getSnowballHistory = () => {
    const { nextToken, dataSource } = this.state;
    const param = {
      limit: 10,
      nextToken
    };
    this.setState({ loading: true });
    fetchSnowballHistory(param)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            dataSource:
              (nextToken ? dataSource.concat(res?.data?.rows ?? []) : res?.data?.rows) ?? [],
            nextToken: res?.data?.nextToken
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  getStrategyHistory = () => {
    const { dataSource, nextToken } = this.state;
    const params = {
      limit: 10,
      nextToken: nextToken
    };
    getStrategyOrderList(params)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            dataSource:
              (nextToken ? dataSource.concat(res?.data?.rows ?? []) : res?.data?.rows) ?? [],
            nextToken: res?.data?.nextToken
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  getVentureHistory = () => {
    const { dataSource, nextToken } = this.state;
    const params = {
      limit: 10,
      nextToken: nextToken
    };
    getVentureOrderList(params)
      .then((res) => {
        if (res?.code == "0") {
          this.setState({
            dataSource:
              (nextToken ? dataSource.concat(res?.data?.rows ?? []) : res?.data?.rows) ?? [],
            nextToken: res?.data?.nextToken
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  //切换理财类型
  handleChangeYield = (e: RadioChangeEvent) => {
    this.setState(
      {
        yieldType: e.target.value
      },
      () => {
        if (e.target.value === YIELD_TYPES.flexible) {
          this.getCurrentData();
        }
      }
    );

    reportEvent({
      moduleName: GA_EVENT_NAME.history.switchYieldTab,
      detailParams: { tab: e.target.value }
    });
  };

  // 获取选中策略详情
  handleViewStrategyDetail = (record) => {
    const intl = i18nUtil.t();
    const params = {
      orderId: record?.id
    };
    getStrategyOrderDetail(params).then((res) => {
      if (res?.code == "0") {
        this.setState({ currentStrateyDetail: res.data, strategyDetailModalVisible: true });
      } else {
        message.error(intl?.[res?.msg] ?? res?.msg);
      }
    });

    reportEvent({
      moduleName: GA_EVENT_NAME.myInvestment.strategyView,
      detailParams: { id: record?.id }
    });
  };

  // 取消策略申购赎回意向
  handleCancelStrategy = (record) => {
    this.setState(
      {
        cancelStrategyId: record?.id,
        cancelEmail: record?.operatorEmail,
        cancelType: record?.operator
      },
      () => {
        this.setState({
          confirmModalVisible: true
        });
      }
    );
    reportEvent({
      moduleName: GA_EVENT_NAME.myInvestment.strategyCancel,
      detailParams: { id: record?.id }
    });
  };

  fetchCancelStrategy = () => {
    const intl = i18nUtil.t();
    this.setState({ nextToken: "" });
    const params = {
      orderId: this.state.cancelStrategyId
    };
    cancelStrategy(params)
      .then((res) => {
        if (res?.code == "0") {
          message.success(intl["system.success"]);
          this.setState({ confirmModalVisible: false });
          this.getStrategyHistory();
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  handleCurrentChange = (e: RadioChangeEvent) => {
    this.setState(
      {
        currentStructuredTab: e.target.value,
        nextToken: "",
        dataSource: []
      },
      () => {
        this.getCurrentData();
      }
    );
    reportEvent({
      moduleName: GA_EVENT_NAME.history.switchStructuredTab,
      detailParams: { tab: e.target.value }
    });
  };

  render(): React.ReactNode {
    const intl = i18nUtil.t();
    const {
      dataSource,
      currentTab,
      currentStructuredTab,
      loading,
      nextToken,
      showDetailModal,
      orderDetails,
      yieldType,
      currentStrateyDetail,
      strategyDetailModalVisible,
      confirmModalVisible,
      cancelEmail,
      cancelType,
      expandedRowKeys
    } = this.state;
    const columns = this.setColumns();
    const { activeSymbolPrecisions, offlineSymbolsList } = this.props; //useAppSelector(marketSymbolsList);

    const isStructureTab = currentTab === PRODUCT_TYPES.structured;
    const tabItems = [
      {
        label: intl["title.market"],
        key: PRODUCT_TYPES.spot
      },
      {
        label: intl["compliance.yield.title"],
        key: PRODUCT_TYPES.yield
      },
      {
        label: intl["history.structured"],
        key: PRODUCT_TYPES.structured
      },
      {
        label: intl["menu.strategies"],
        key: PRODUCT_TYPES.strategies
      },
      {
        label: intl["menu.ventures"],
        key: PRODUCT_TYPES.ventures
      }
    ];

    const commonTableDom = () => (
      <>
        <WithEmptyContent showEmpty={true}>
          {isStructureTab && (
            <div style={{ marginBottom: "20px" }}>
              <StructuredHeader
                currentTab={currentStructuredTab}
                handleCurrentChange={this.handleCurrentChange}
              />
            </div>
          )}
          <Table
            className={styles.table}
            rowKey={(record: any) => record.id ?? record.orderId}
            bordered={false}
            dataSource={dataSource}
            // @ts-ignore
            columns={columns[isStructureTab ? currentStructuredTab : currentTab]}
            pagination={false}
            expandable={
              isStructureTab && currentStructuredTab === STRUCTURED_TYPES.snowball
                ? {
                    columnTitle: intl["column.status"],
                    expandedRowRender: (record) => (
                      <div className={styles.expandBox}>
                        <div className={record?.settleTime ? styles.flex0 : styles.flex0Auto}>
                          <div className={styles.expandItem}>
                            <div className={styles.expandItemKey}>
                              {intl["structural.snowball.knockIn"]}
                              {SNOWBALL_SETTLED_STATUS.includes(record?.state) && record?.kiTime
                                ? "(Y)"
                                : ""}
                            </div>
                            <div className={styles.expandItemValue}>
                              {record?.kiTime
                                ? `${transformDate(record?.kiTime)} (${numberToThousands(
                                    decimalPointNoMoreXNoFill(record?.actualKiPrice, 2)
                                  )})`
                                : "N"}
                            </div>
                          </div>
                          <div className={styles.expandItem}>
                            <div className={styles.expandItemKey}>
                              {intl["structural.snowball.knockOut"]}{" "}
                              {SNOWBALL_SETTLED_STATUS.includes(record?.state) && record?.koTime
                                ? "(Y)"
                                : ""}
                            </div>
                            <div className={styles.expandItemValue}>
                              {record?.koTime
                                ? `${transformDate(record?.koTime)} (${numberToThousands(
                                    decimalPointNoMoreXNoFill(record?.actualKoPrice, 2)
                                  )})`
                                : "N"}
                            </div>
                          </div>
                        </div>
                        {record?.settleTime && (
                          <div className={styles.flex1}>
                            <div className={styles.expandItem}>
                              <div className={styles.expandItemKey} style={{ display: "flex" }}>
                                {intl["column.settlement"]}(Y)
                                <Tooltip
                                  title={intl["structured.expiryDate.tips"]}
                                  style={{ width: 200 }}>
                                  <InfoCircleOutlined
                                    style={{ paddingLeft: 5, paddingTop: 4, cursor: "pointer" }}
                                  />
                                </Tooltip>
                              </div>
                              <div>
                                {record?.paymentTime ? transformTime(record?.paymentTime) : "--"}
                              </div>
                            </div>
                            <div className={styles.expandItem}>
                              <div className={styles.expandItemKey}>
                                {intl["snowball.settle.title"]}
                              </div>
                              <div>
                                {
                                  intl[
                                    `structured.snowball.settled.detail.caseKey.${record?.settleDescType}`
                                  ]
                                }
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ),
                    expandIcon: ({ expanded, onExpand, record }) => {
                      return (
                        <div style={{ textAlign: "right" }}>
                          <div>{intl[SNOWBALL_ORDER_STATUS[record?.state]]}</div>
                          <div onClick={(e) => onExpand(record, e)} className={styles.expandTd}>
                            <span>{intl[SNOWBALL_PRODUCT_STATUS[record?.state]] ?? "--"}</span>
                            {SNOWBALL_VIEW_DESC_STATUS.includes(record?.state) &&
                              (expanded ? (
                                <UpOutlined className={styles.expandIcon} />
                              ) : (
                                <DownOutlined className={styles.expandIcon} />
                              ))}
                          </div>
                        </div>
                      );
                    },
                    expandedRowKeys: expandedRowKeys,
                    onExpandedRowsChange: (expandedRows: string[]) => {
                      const newRows = expandedRows.filter(
                        (item) => !expandedRowKeys.includes(item)
                      );
                      reportEvent({
                        moduleName: GA_EVENT_NAME.history.snowDetail,
                        detailParams: {
                          orderId: newRows.join(",")
                        }
                      });
                      this.setState({
                        expandedRowKeys: newRows
                      });
                    }
                  }
                : {}
            }
          />
        </WithEmptyContent>
        {nextToken && dataSource.length >= 10 && (
          <div className={styles.loadMore} onClick={this.getCurrentData}>
            {intl["button.loadMore"]}
          </div>
        )}
      </>
    );

    const user_auth = (isClient && localStorage.getItem(USER_AUTH)) || "";

    return (
      <section className={styles.history + " " + "customer"}>
        <div className="customerTop">
          <Row className="top">
            <Col span={24}>
              <div className={styles.titleContent}>
                <p className="title">{intl["history.title"]}</p>
                {USER_ROLE_POWER[user_auth]?.withdrawApplication && (
                  <Button
                    type="primary"
                    className={styles.btn}
                    onClick={() => {
                      this.props.router.push(HISTORY_PATHS.HISTORY_APPLICATION_DETAIL);
                    }}>
                    {intl["wallet.withdraw.application.title"]}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.tableWrap}>
          <div className="section" style={{ width: "100%", marginTop: 0 }}>
            <div className={styles.history} style={{ marginTop: 0 }}>
              <Spin spinning={loading}>
                <Tabs
                  items={tabItems}
                  className={styles.tab}
                  activeKey={currentTab}
                  onChange={this.handleChangeTabs}
                  style={{ color: "#fff" }}
                />
                <div className={styles.historyArea}>
                  {currentTab === PRODUCT_TYPES.yield ? (
                    <div className={styles.yieldTable}>
                      <div className={styles.yieldTableTitle}>
                        <Radio.Group
                          onChange={this.handleChangeYield}
                          value={yieldType}
                          style={{ marginBottom: 24 }}>
                          <Radio.Button value={YIELD_TYPES.flexible}>
                            {intl["savings.flexible"]}
                          </Radio.Button>
                          <Radio.Button value={YIELD_TYPES.fixed}>
                            {intl["savings.fixed"]}
                          </Radio.Button>
                          <Radio.Button value={YIELD_TYPES.staking}>
                            {intl["savings.staking"]}
                          </Radio.Button>
                        </Radio.Group>
                      </div>
                      {/* 活期理财 */}
                      {yieldType === YIELD_TYPES.flexible && commonTableDom()}
                      {/* 定期理财 */}
                      {yieldType === YIELD_TYPES.fixed && <FixedYieldHistory />}
                      {/* staking理财 */}
                      {yieldType === YIELD_TYPES.staking && <StakingYieldHistory />}
                    </div>
                  ) : (
                    commonTableDom()
                  )}
                </div>
              </Spin>
            </div>
          </div>
        </div>

        {confirmModalVisible ? (
          <ModalCancelStrategy
            visible={confirmModalVisible}
            cancelModal={() => {
              this.setState({ confirmModalVisible: false });
            }}
            fetchCancelStrategy={this.fetchCancelStrategy}
            cancelEmail={cancelEmail}
            cancelType={cancelType}
          />
        ) : null}

        {showDetailModal ? (
          <ModalOrderDetail
            visible={showDetailModal}
            tableData={orderDetails}
            handleCancel={this.hideModal}
            onOk={this.hideModal}
            activeSymbolPrecisions={activeSymbolPrecisions}
            offlineSymbolsList={offlineSymbolsList}
          />
        ) : null}

        {strategyDetailModalVisible ? (
          <ModalStrategyDetail
            visible={strategyDetailModalVisible}
            info={currentStrateyDetail}
            cancelModal={() => {
              this.setState({ strategyDetailModalVisible: false });
            }}
          />
        ) : null}
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  const { offlineSymbolsList, activeSymbolPrecisions } = state.marketSpotAndTradeInfo;
  return {
    offlineSymbolsList, // 离线的盘口精度信息
    activeSymbolPrecisions
  };
};

export const PageHistory = withRouter(connect(mapStateToProps)(History));
