import React, { useState } from "react";
import styles from "./Login.module.less";
import { i18nUtil, LOGIN_PATHS, OPEN_BIND_PHONE } from "@aspen/libs";
import { Tabs } from "antd";
import Link from "next/link";
import { BaseLogin, LoginTabItem } from "./DynamicImport";
import { ACCOUNT_TYPE } from "@aspen/model";

type IProps = {};

const LoginWidgets: React.FC<IProps> = (props) => {
  const intl = i18nUtil.t();
  // 登录方式
  const [currentTab, setCurrentTab] = useState(ACCOUNT_TYPE.EMAIL);

  const handleChangeTab = (value) => {
    setCurrentTab(value);
  };

  const tabItems = [
    {
      key: ACCOUNT_TYPE.EMAIL,
      label: (
        <LoginTabItem
          key={ACCOUNT_TYPE.EMAIL}
          activeTab={ACCOUNT_TYPE.EMAIL}
          currentTab={currentTab}
          title={intl["login.email.tab"]}
        />
      )
    },
    OPEN_BIND_PHONE && {
      key: ACCOUNT_TYPE.PHONE,
      label: (
        <LoginTabItem
          key={ACCOUNT_TYPE.PHONE}
          activeTab={ACCOUNT_TYPE.PHONE}
          currentTab={currentTab}
          title={intl["login.phone.tab"]}
        />
      )
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.commonTips + " text-right"} style={{ marginBottom: "42px" }}>
        {intl["login.noAccount"]}&nbsp;
        <Link href={LOGIN_PATHS.LOGIN_REGISTER}>
          <span className={styles.toRegister}>{intl["register.now"]}</span>
        </Link>
      </div>
      <p className={styles.title}>{intl["login.text"]}</p>
      <Tabs
        items={tabItems}
        onChange={handleChangeTab}
        className={styles.tab}
        activeKey={currentTab}
      />
      <div
        className={styles.inputFillBox}
        style={{ display: currentTab === ACCOUNT_TYPE.EMAIL ? "block" : "none" }}>
        <BaseLogin {...props} accountType={ACCOUNT_TYPE.EMAIL} />
      </div>
      <div
        className={styles.inputFillBox}
        style={{ display: currentTab === ACCOUNT_TYPE.PHONE ? "block" : "none" }}>
        <BaseLogin {...props} accountType={ACCOUNT_TYPE.PHONE} />
      </div>
      <div className="text-center" style={{ marginTop: "32px" }}>
        <Link href={LOGIN_PATHS.LOGIN_RESET}>
          <div className={styles.commonTipsForget}>{intl["login.forget"]}</div>
        </Link>
      </div>
    </div>
  );
};

export default LoginWidgets;
